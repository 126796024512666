// POSMenu v2.15
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { usePOS } from '../../contexts/POSContext';
import { fetchAggregatedOrder, createOrder, updateOrder, updateCustomOrder, updateMealCourses, deleteOrder } from '../../services/posApiService';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuItems from './menu/MenuItems';
import CategoryList from './menu/CategoryList';
import OrderSummary from './menu/OrderSummary';
import { aggregateAndOptimizeOrderItems } from '../../utils/orderAggregation'

const Container = styled.div`
  display: flex;
  height: 100%;
  user-select: none;
  gap: 20px;
  background-color: #121212;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const POSMenu = () => {
  const {
    restaurantId, loadTables, selectedTable, loadMenus, selectedMenu, categories, selectedCategory,
    setSelectedCategory, loadCategories, loadItemsAndCombos, setLoading,
    showAlert, openModal, setCurrentItem, setOrderEmpty, orderItems, setOrderItems
  } = usePOS();
  const isMobile = useMediaQuery('(max-width:600px)');
  const isTabletOrLarger = useMediaQuery('(min-width:601px)');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [orderChanged, setOrderChanged] = useState(false);
  const [courseChanged, setCourseChanged] = useState({});

  useEffect(() => {
    if (restaurantId) {
      loadMenus();
      loadTables();
    }
  }, [restaurantId, loadMenus, loadTables]);

  useEffect(() => {
    if (selectedMenu) {
      loadCategories(selectedMenu);
    }
  }, [selectedMenu, loadCategories]);

  useEffect(() => {
    if (selectedCategory) {
      loadItemsAndCombos(selectedCategory);
      if (isMobile) {
        setDrawerOpen(true);
      }
    }
  }, [selectedCategory, loadItemsAndCombos, isMobile]);

  useEffect(() => {
    if (!isMobile && categories.length > 0 && !selectedCategory) {
      setSelectedCategory(categories[0].id);
    }
  }, [categories, selectedCategory, setSelectedCategory, isMobile]);

  useEffect(() => {
    if (isMobile) {
      setSelectedCategory(null);
    }
  }, [isMobile, setSelectedCategory]);

useEffect(() => {
  const fetchOrderData = async () => {
    if (selectedTable) {
      try {
        const orderData = await fetchAggregatedOrder(selectedTable);
        if (orderData.items && Object.keys(orderData.items).length > 0) {
          // Set orderItems with the optimized structure
          setOrderItems({
            orderId: orderData.orderId,
            items: orderData.items,
            customOrder: orderData.custom_order,
          });

        } else {
          setOrderItems({
            orderId: null,
            items: {},
            customOrder: false,
          });
        }
      } catch (error) {
        showAlert('Error fetching order for the selected table', 'error', 'order-fetch');
      }
    }
    setOrderChanged(false);
  };

  fetchOrderData();
}, [selectedTable, showAlert, setOrderItems]);

const handleItemClick = (item) => {
  if (!selectedTable) {
    showAlert('Please select a table number', 'warning', 'table-select');
    return;
  }

  const category = categories.find((cat) => cat.id === selectedCategory);
  const courseType = category ? category.course_type : 'Drinks';

  // Create a new item to be added to the orderItems state
  const newItem = {
    id: null,
    menu_item_id: item.items ? null : item.id,
    combo_id: item.items ? item.id : null,
    name: item.name,
    quantity: 1,
    price: item.price,
    note: '',
    course_type: courseType,
    status: 'received',
    isSelected: true, // Mark as selected but not yet part of the order
  };

  setOrderItems((prevOrderItems) => {
    const updatedItems = { ...prevOrderItems.items };

    // Iterate through all courses to find if the item already exists
    let itemFound = false;

    Object.keys(updatedItems).forEach((existingCourseType) => {
      const courseItems = updatedItems[existingCourseType] || {};

      // Check if an existing item has the same menu_item_id or combo_id
      const existingItemKey = Object.keys(courseItems).find((key) => {
        const existingItem = courseItems[key];
        return (
          (newItem.menu_item_id && existingItem.menu_item_id === newItem.menu_item_id) ||
          (newItem.combo_id && existingItem.combo_id === newItem.combo_id)
        );
      });

      if (existingItemKey) {
        // If the item already exists, update its quantity and course type
        courseItems[existingItemKey].quantity += 1;
        // Optionally update course_type if the user changes it while adding
        courseItems[existingItemKey].course_type = courseType;
        itemFound = true;
      }

      // Update the course in the overall items
      updatedItems[existingCourseType] = courseItems;
    });

    if (!itemFound) {
      // If the item wasn't found, add it to the current course type
      const courseItems = updatedItems[courseType] || {};
      courseItems[newItem.name] = newItem;
      updatedItems[courseType] = courseItems;
    }

    // Only optimize the items if the order is not a custom order
    const finalItems = prevOrderItems.customOrder
      ? updatedItems
      : aggregateAndOptimizeOrderItems(updatedItems);

    return {
      ...prevOrderItems,
      items: finalItems, // Set either optimized or non-optimized items based on order type
    };
  });

  setOrderChanged(true);
};

const removeItemFromOrder = (itemId) => {
  setOrderItems((prevOrderItems) => {
    const updatedItems = {};

    Object.entries(prevOrderItems.items).forEach(([courseType, courseItems]) => {
      const updatedCourseItems = {};

      Object.entries(courseItems).forEach(([itemName, item]) => {
        // Match by id or by menu_item_id/combo_id for new items
        const isMatchingItem =
          item.id === parseInt(itemId) ||
          (item.menu_item_id && `menu_${item.menu_item_id}` === itemId) ||
          (item.combo_id && `combo_${item.combo_id}` === itemId);

        if (isMatchingItem) {
          // Check if the item is in 'received' status
          if (item.status !== 'received') {
            showAlert('Cannot remove item that is not in received status', 'error', 'remove-item');
            updatedCourseItems[itemName] = item; // Keep the item unchanged
          } else if (item.quantity > 1) {
            // Reduce the quantity if it's greater than 1
            updatedCourseItems[itemName] = { ...item, quantity: item.quantity - 1 };
          }
          // If quantity is 1, don't include the item in updatedCourseItems (effectively removing it)
        } else {
          updatedCourseItems[itemName] = item; // Keep the item unchanged
        }
      });

      // If there are items left in the course, include the course in updatedItems
      if (Object.keys(updatedCourseItems).length > 0) {
        updatedItems[courseType] = updatedCourseItems;
      }
    });

    const isEmpty = Object.keys(updatedItems).length === 0;
    setOrderEmpty(isEmpty);

    return { ...prevOrderItems, items: updatedItems };
  });

  setOrderChanged(true);
};

  const handleItemDetailsClick = (item) => {
    if (item.status && item.status !== 'received') {
      showAlert('Cannot edit item that is not in received status', 'error', 'edit-item');
      return;
    }
    setCurrentItem(item);
    openModal(item);
  };

const onDragEnd = (result) => {
  const { source, destination } = result;

  // Corrected condition: no self-comparison
  if (!destination || (source.droppableId === destination.droppableId && source.index === destination.index)) {
    return;
  }

  setOrderItems((prevOrderItems) => {
    const updatedItems = { ...prevOrderItems.items };

    // Extract source and destination course items
    const sourceCourseItems = { ...updatedItems[source.droppableId] };
    let destinationCourseItems = { ...updatedItems[destination.droppableId] };

    const sourceKeys = Object.keys(sourceCourseItems);
    const destinationKeys = Object.keys(destinationCourseItems);

    if (!sourceKeys.length || !destinationKeys.length) {
      return prevOrderItems;
    }

    // Extract the item being moved
    const movedItemKey = sourceKeys[source.index];
    const movedItem = sourceCourseItems[movedItemKey];

    // Prevent drinks from being reordered
    if (movedItem.course_type === 'Drinks') {
      showAlert('Drinks cannot be reordered.', 'warning');
      return prevOrderItems;
    }

    // Prevent non-drinks from being dropped into the "Drinks" category
    if (destination.droppableId === 'Drinks' && movedItem.course_type !== 'Drinks') {
      showAlert('Cannot move a meal course to drinks.', 'warning');
      return prevOrderItems;
    }

    // Remove the item from the source course
    delete sourceCourseItems[movedItemKey];

    // Check if the item already exists in the destination and update quantity or place it at the new index
    const existingItemKey = destinationKeys.find((key) => {
      const item = destinationCourseItems[key];
      return (
        (item.menu_item_id && item.menu_item_id === movedItem.menu_item_id) ||
        (item.combo_id && item.combo_id === movedItem.combo_id)
      );
    });

    if (existingItemKey) {
      destinationCourseItems[existingItemKey].quantity += movedItem.quantity;
    } else {
      // Insert the moved item into the destination course at the correct index
      const destinationItemEntries = Object.entries(destinationCourseItems);
      destinationItemEntries.splice(destination.index, 0, [movedItemKey, movedItem]);
      destinationCourseItems = Object.fromEntries(destinationItemEntries);
    }

    // Update the courses in the overall items
    updatedItems[source.droppableId] = sourceCourseItems;
    updatedItems[destination.droppableId] = destinationCourseItems;

    const updatedCourses = {};
    Object.entries(updatedItems).forEach(([courseType, courseItems]) => {
      if (courseType !== 'Drinks') {
        updatedCourses[courseType] = Object.entries(courseItems).map(([itemName, item]) => ({
          id: item.id,
          menu_item: item.menu_item_id || null,
          combo: item.combo_id || null,
          quantity: item.quantity,
          note: item.note || '',
          course_type: item.course_type,
        }));
      }
    });

    setCourseChanged(updatedCourses);

    return {
      ...prevOrderItems,
      items: updatedItems,
      customOrder: true, // Transition to custom order due to reordering
    };
  });

  setOrderChanged(true);
};

const prepareOrderData = () => {
  const allItems = [];

  // Iterate over each course and the items within it
  Object.entries(orderItems.items).forEach(([courseType, courseItems]) => {
    Object.entries(courseItems).forEach(([itemName, item]) => {
      allItems.push({
        id: item.id || null,
        menu_item: item.menu_item_id || null,
        combo: item.combo_id || null,
        quantity: item.quantity,
        course_type: item.course_type,
        note: item.note || '',
      });
    });
  });

  return {
    table: selectedTable,
    items: allItems,
  };
};

const prepareCustomOrderData = () => {
  const { orderId, items } = orderItems;
  const updatedCourses = {};
  const newItems = [];

  // Convert course names to a numeric sequence and populate updatedCourses
  const courseNumbers = Object.keys(items)
    .filter((courseType) => courseType !== 'Drinks') // Exclude 'Drinks' from renumbering
    .sort()
    .reduce((acc, courseType, index) => {
      acc[courseType] = index + 1; // Create a mapping of courseType to a numeric course number
      return acc;
    }, {});

  // Iterate over the items object and organize them by numeric course number
  Object.entries(items).forEach(([courseType, courseItems]) => {
    const courseNumber = courseNumbers[courseType] || courseType; // Keep 'Drinks' as is

    Object.entries(courseItems).forEach(([itemName, item]) => {
      // If it's an existing item (part of a previous order), update its course
      if (item.id) {
        if (!updatedCourses[courseNumber]) {
          updatedCourses[courseNumber] = [];
        }
        updatedCourses[courseNumber].push({
          id: item.id,
          menu_item: item.menu_item_id || null,
          combo: item.combo_id || null,
          quantity: item.quantity,
          note: item.note || '',
          course_type: courseType,
        });
      } else {
        // Otherwise, treat it as a new item
        newItems.push({
          id: null,
          menu_item: item.menu_item_id || null,
          combo: item.combo_id || null,
          quantity: item.quantity,
          note: item.note || '',
          course_type: courseType,
          order: orderId,
        });
      }
    });
  });

  return {
    order_id: orderId,
    updated_courses: updatedCourses,
    new_items: newItems,
  };
};

const placeOrder = async () => {
  if (Object.keys(orderItems.items).length === 0) return;

  setLoading(true);

  try {
    let response;
    let updatedOrderData;

    // Use orderItems.orderId and orderItems.customOrder directly
    if (orderItems.customOrder) {
      const { updated_courses, new_items } = prepareCustomOrderData();
      response = await updateCustomOrder(orderItems.orderId, { updated_courses, new_items });
    } else {
      const orderData = prepareOrderData();

      if (orderItems.orderId) {
        response = await updateOrder(orderItems.orderId, orderData);
      } else {
        response = await createOrder(orderData);
      }
    }

    // Refetch the updated order data after placing/updating the order
    updatedOrderData = await fetchAggregatedOrder(selectedTable);

    // Update the orderItems state with the latest data from the backend
    setOrderItems({
      orderId: updatedOrderData?.orderId || response.id,
      items: updatedOrderData?.items || response.items,
      customOrder: updatedOrderData?.custom_order || response.custom_order,
    });

    // Clear the state indicating changes and show a success message
    setOrderChanged(false);
    showAlert(orderItems.customOrder ? 'Custom order updated successfully' : 'Order updated successfully', 'success', 'order-update');
  } catch (error) {
    console.error('Error placing/updating order:', error);
    showAlert('Error placing/updating order', 'error', 'order-error');
  } finally {
    setLoading(false);
  }
};

const updateOrderChanges = async () => {
  try {
    let orderId = orderItems.orderId;

    // Step 1: If no order exists, create the order first
    if (!orderId) {
      const orderData = prepareOrderData();
      const response = await createOrder(orderData);
      orderId = response.id; // Capture the newly created order ID
    }

    // Step 2: Now that the order exists, handle the custom order update if needed
    if (orderItems.customOrder) {
      const { updated_courses, new_items } = prepareCustomOrderData();
      await updateCustomOrder(orderId, { updated_courses, new_items });
    } else {
      const orderData = prepareOrderData();
      await updateOrder(orderId, orderData);
    }

    // Refetch the updated order data to ensure frontend is in sync
    const updatedOrderData = await fetchAggregatedOrder(selectedTable);

    // Step 3: Now that all items have IDs, proceed to update the meal courses
    const updatedItems = updatedOrderData.items;
    let courseIndex = 1;

    const updatedCoursesWithNumbers = Object.entries(courseChanged)
      .filter(([courseType, items]) => items.length > 0)
      .reduce((acc, [courseType, items]) => {
        acc[courseIndex] = items.map((item) => {
          const matchedItem = Object.values(updatedItems)
            .flatMap(courseItems => Object.values(courseItems))
            .find(updatedItem => (
              (item.menu_item && updatedItem.menu_item_id === item.menu_item) ||
              (item.combo && updatedItem.combo_id === item.combo)
            ));
          return {
            ...item,
            id: matchedItem?.id || item.id,
          };
        });
        courseIndex += 1;
        return acc;
      }, {});

    // Step 4: Send the updated meal courses to the backend
    await updateMealCourses(orderId, updatedCoursesWithNumbers);

    // Final Step: Re-fetch the updated order data to ensure the frontend is in sync
    const finalOrderData = await fetchAggregatedOrder(selectedTable);

    // Update the orderItems state with the latest data from the server
    setOrderItems({
      orderId: finalOrderData.orderId,
      items: finalOrderData.items,
      customOrder: finalOrderData.custom_order,
    });

    // Clear the local course change tracker
    setCourseChanged({});
    setOrderChanged(false);

    showAlert('Meal courses updated successfully', 'success', 'order-update');
  } catch (error) {
    console.error('Error updating meal courses:', error);
    showAlert('Error updating meal courses', 'error', 'order-update-error');
  } finally {
    setLoading(false);
  }
};

const deletePlacedOrder = async () => {
  if (!selectedTable) {
    showAlert('Please select a table number', 'warning', 'table-select');
    return;
  }

  setLoading(true);

  try {
    const response = await deleteOrder(orderItems.orderId);
    
    if (response.status === 204) {
      // Clear the orderItems state and reset relevant flags
      setOrderItems({
        orderId: null,
        items: [],
        customOrder: false,
      });
      setOrderEmpty(true);
      showAlert('Order deleted successfully', 'success', 'order-delete');
    } else {
      showAlert('Failed to delete order', 'error', 'order-delete-error');
    }
  } catch (error) {
    console.error('Error deleting order:', error);
    showAlert('Error deleting order', 'error', 'order-delete-error');
  } finally {
    setLoading(false);
  }
};

  return (
    <Container>
      <MenuItems
        handleItemClick={handleItemClick}
      />
      <CategoryList/>
      <OrderSummary
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        orderChanged={orderChanged}
        setOrderChanged={setOrderChanged}
        courseChanged={courseChanged}
        setCourseChanged={setCourseChanged}
        isMobile={isMobile}
        isTabletOrLarger={isTabletOrLarger}
        fetchAggregatedOrder={fetchAggregatedOrder}
        removeItemFromOrder={removeItemFromOrder}
        handleItemDetailsClick={handleItemDetailsClick}
        onDragEnd={onDragEnd}
        updateOrderChanges={updateOrderChanges}
        placeOrder={placeOrder}
        deletePlacedOrder={deletePlacedOrder}
      />
    </Container>
  );
};

export default POSMenu;