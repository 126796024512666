import React from 'react';
import { Dialog, DialogTitle, DialogContent, Typography } from '@mui/material';

const AllergenDialog = ({ open, onClose, allergens }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Allergens</DialogTitle>
      <DialogContent>
        {allergens.length > 0 ? (
          allergens.map((allergen, index) => (
            <Typography key={index}>{allergen}</Typography>
          ))
        ) : (
          <Typography>No allergens specified</Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AllergenDialog;
