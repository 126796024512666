import React, { useEffect, useCallback } from 'react';
import { useRestaurant } from '../../contexts/RestaurantContext';
import styled from 'styled-components';
import OnlineMenu from '../Users/OnlineMenu';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  position: relative;
  width: 375px; // Represents the width of a typical smartphone
  height: 667px; // Represents the height of a typical smartphone
  overflow-y: auto; // Allows scrolling if content overflows
  background: white;
  border-radius: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
`;

const OnlineMenuModal = () => {
  // Shared states
  const { setShowPreview } = useRestaurant();

  // Handle click outside the modal to close it
  const handleOutsideClick = (event) => {
    if (event.target.id === 'modal-overlay') {
      setShowPreview(false);
    }
  };

  // Handle Esc key press to close the modal
  const handleEscKeyPress = useCallback((event) => {
    if (event.key === 'Escape') {
      setShowPreview(false);
    }
  }, [setShowPreview]);

  useEffect(() => {
    // Add event listener for Escape key press
    window.addEventListener('keydown', handleEscKeyPress);
    
    return () => {
      // Clean up the event listener when the component is unmounted
      window.removeEventListener('keydown', handleEscKeyPress);
    };
  }, [handleEscKeyPress]);

  return (
    <ModalOverlay id="modal-overlay" onClick={handleOutsideClick}>
      <ModalContainer>
        {/* OnlineMenu content goes here */}
        <OnlineMenu />
      </ModalContainer>
    </ModalOverlay>
  );
};

export default OnlineMenuModal;
