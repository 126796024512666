// AreaSwiper
import React, { useState, useEffect } from 'react';
import { useRestaurant } from '../../../contexts/RestaurantContext';
import { updateArea, deleteArea, fetchArea } from '../../../services/apiService';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'; // core Swiper
import 'swiper/css/navigation'; // Navigation module
import 'swiper/css/pagination'; // Pagination module
import { Pagination, Navigation } from 'swiper/modules';
import styled from 'styled-components';
import FloorMapComponent from './FloorMapComponent';
import { Typography, TextField } from '@mui/material';
import { DeleteOutline } from '@mui/icons-material';
import ModeEditOutlineRoundedIcon from '@mui/icons-material/ModeEditOutlineRounded';
import { DeleteConfirmationDialog } from '../../Common/MUI.StyledComponents';

const AreaSwiperContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; // Center-align the items horizontally
  position: relative; // Ensure relative positioning for contained elements
  width: 100%;
  border: 1px solid lightgrey;
  border-radius: 5px;
`;

const AreaHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: var(--color-primary);
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
`;

const AreaName = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Icons = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
`;

const SwiperContainer = styled.div`
  width: 100%; // Full width
  overflow: hidden; // Prevents any overflow from the swiper from appearing outside
  position: relative; // Ensure relative positioning for navigation buttons
  font-weight: 600;
// NAVIGATION
  --swiper-navigation-size: 25px;
  --swiper-navigation-top-offset: 200px;
  --swiper-navigation-sides-offset: 4px;
  --swiper-navigation-color: var(--color-secondary);
// PAGINATION
  --swiper-pagination-color: var(--color-secondary);
  --swiper-pagination-left: 50%;
  --swiper-pagination-right: auto;
  --swiper-pagination-bottom: auto;
  --swiper-pagination-top: 0px;
  --swiper-pagination-fraction-color: inherit;
  --swiper-pagination-progressbar-bg-color: rgba(0, 0, 0, 0.25);
  --swiper-pagination-progressbar-size: 4px;
  --swiper-pagination-bullet-size: 8px;
  --swiper-pagination-bullet-width: 8px;
  --swiper-pagination-bullet-height: 8px;
  --swiper-pagination-bullet-inactive-color: #000;
  --swiper-pagination-bullet-inactive-opacity: 0.15;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 4px;
  --swiper-pagination-bullet-vertical-gap: 6px;
`;

const AreaSwiper = ({ currentAreaName, setCurrentAreaName }) => {
  const [editingAreaId, setEditingAreaId] = useState(null);
  const [editingAreaName, setEditingAreaName] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [areaToDelete, setAreaToDelete] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const { loadUserRestaurants, areas, setAreas, handleAddTable } = useRestaurant();

  useEffect(() => {
    if (areas.length > 0) {
      setCurrentAreaName(areas[activeIndex]?.name || '');
    }
  }, [activeIndex, areas, setCurrentAreaName]);

  const onSlideChange = (swiper) => {
    const currentSlideIndex = swiper.activeIndex;
    setActiveIndex(currentSlideIndex);
  };

  const handleAddTableClick = () => {
    const currentAreaId = areas[activeIndex]?.id;
    handleAddTable(currentAreaId); // Use the context function
  };

  const handleEditClick = (areaId, areaName) => {
    setEditingAreaId(areaId);
    setEditingAreaName(areaName);
  };

  const handleEditChange = (event) => {
    setEditingAreaName(event.target.value);
  };

  const handleEditSubmit = async () => {
    // Only proceed if the name has actually changed
    const originalArea = areas.find(area => area.id === editingAreaId);
    if (originalArea.name === editingAreaName) {
      setEditingAreaId(null);
      return;
    }

    try {
      const currentAreaDetails = await fetchArea(editingAreaId);
      const updatedAreaData = { ...currentAreaDetails, name: editingAreaName };
      await updateArea(editingAreaId, updatedAreaData);

      // Update the areas state to reflect the new name
      setAreas((prevAreas) =>
        prevAreas.map((area) =>
          area.id === editingAreaId ? { ...area, name: editingAreaName } : area
        )
      );

      setEditingAreaId(null);
      setEditingAreaName('');
      setCurrentAreaName(updatedAreaData.name); // Update the displayed area name
    } catch (error) {
      console.error('Error updating area:', error);
    }
  };

  const handleDeleteClick = (areaId) => {
    setOpenDeleteDialog(true);
    setAreaToDelete(areaId);
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteArea(areaToDelete);
      loadUserRestaurants(); // Refresh areas
      setOpenDeleteDialog(false);
      setAreaToDelete(null);
    } catch (error) {
      console.error('Error deleting area:', error);
    }
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setAreaToDelete(null);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleEditSubmit();
    }
  };

  return (
    <AreaSwiperContainer>
      <AreaHeader>
        <Typography variant="h6" style={{ marginLeft: '10px', cursor: 'pointer', textDecoration: 'underline' }} color="white" onClick={handleAddTableClick}>
          Add Table
        </Typography>
        <AreaName>
          {editingAreaId === areas[activeIndex]?.id ? (
            <TextField
              variant="standard"
              size="small"
              value={editingAreaName}
              onChange={handleEditChange}
              onBlur={handleEditSubmit}
              onKeyPress={handleKeyPress}
              autoFocus
              style={{ marginLeft: '20px', fontSize: '15px', fontWeight: '550', backgroundColor: 'white', borderRadius: '4px'}}
            />
          ) : (
            <Typography color="white" variant="h6">
              {areas[activeIndex]?.name}
            </Typography>
          )}
        </AreaName>
        <Icons>
          <ModeEditOutlineRoundedIcon
            sx={{ color: 'white', marginRight: '12px', fontSize: '19px', cursor: 'pointer' }}
            onClick={() => handleEditClick(areas[activeIndex]?.id, areas[activeIndex]?.name)}
          />
          <DeleteOutline
            sx={{ color: 'white', marginRight: '18px', fontSize: '21px', cursor: 'pointer' }}
            onClick={() => handleDeleteClick(areas[activeIndex]?.id)}
          />
        </Icons>
      </AreaHeader>
      <SwiperContainer>
        <Swiper
          spaceBetween={0}
          slidesPerView={'auto'}
          pagination={{ clickable: true }}
          navigation
          onSlideChange={onSlideChange}
          modules={[Pagination, Navigation]}
          allowTouchMove={false}
        >
          {areas.map((area) => (
            <SwiperSlide key={area.id}>
              <FloorMapComponent areaId={area.id} />
            </SwiperSlide>
          ))}
        </Swiper>
      </SwiperContainer>
      <DeleteConfirmationDialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleConfirmDelete}
        instanceName="area"
      />
    </AreaSwiperContainer>
  );
};

export default AreaSwiper;
