import React, { useState } from 'react';
import { useRestaurant } from '../../../contexts/RestaurantContext';
import styled from 'styled-components';
import { Droppable, Draggable } from '@hello-pangea/dnd';
import { NavigateNext, MoreVert } from '@mui/icons-material';
import { Menu, MenuItem, IconButton, Typography, Collapse } from '@mui/material';
import { UnderlinedButton } from '../../Common/MUI.StyledComponents';
import logo from '../../../assets/logo_no-pic.png';

// Styled components
const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  user-select: none;
`;

const CategoryContainer = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  width: 100%;
`;

const TabsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  width: 95%;
  margin-left: 5%;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const IconColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 30px;
  cursor: pointer;
`;

const CategoryTab = styled.div`
  display: flex;
  flex-direction: column;
  position: relative; // Add this line
  box-sizing: border-box;
  background-color: white;
  font-size: 17px;
  color: var(--color-black);
  border: 1px solid var(--color-lightgrey);
  border-radius: 10px;
  width: 100%;
  padding: 8px 12px 8px 12px;
  margin-top: 6px;
  margin-bottom: 6px;
  cursor: grab;
`;

const CategoryDescriptionTab = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: left;
  color: var(--color-grey);
  font-size: 0.85rem;
`;

const MenuItemTab = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: white;
  font-size: 15px;
  border: 1px solid lightgrey;
  border-radius: 10px;
  width: 100%;
  padding: 12px;
  margin-top: 4px;
  margin-bottom: 4px;
`;

const ItemName = styled.div`
  flex-grow: 1;
  font-size: 15px;
`;

const PriceDisplay = styled.span`
  margin-right: 20px;
`;

const MenuItemDetails = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const TextDetails = styled.div`
  flex: 1;
  margin-right: 10px;
`;

const PictureContainer = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  border-radius: 10px;
  border: 1px solid var(--color-light);
  margin-right: 12px;

  img {
    width: auto;
    height: 100%;
    min-width: 100%;
    object-fit: cover;
    border-radius: 10px;
    border: 1px solid darkgrey;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const ItemPlaceholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: 1px dashed lightgrey;
  border-radius: 10px;
  padding: 10px;
  height: 50px;
  font-size: 16px;
  color: #3e3effe3;
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 15px;
  &:hover {
    background-color: #e9ecef;
    border-style: solid;
  }
`;

// Utility functions
const renderToggleIcon = (isExpanded) => (
  <IconButton>
    <NavigateNext
      style={{
        transition: 'transform 0.3s ease-in-out',
        transform: isExpanded ? 'rotate(90deg)' : 'rotate(0deg)',
        color: 'var(--color-secondary)'
      }}
    />
  </IconButton>
);

// Main component
const CategoryList = ({
  expandedCategories,
  expandedItems,
  toggleCategory,
  toggleItem,
  handleAddCategorySelection,
  handleAddMenuItemSelection,
  openEditCategorySidebar,
  openEditMenuItemSidebar,
  openEditComboSidebar,
  openDeleteConfirmation,
  setCategoryActionSelection,
  categoryActionSelection,
  setMenuItemActionSelection,
  menuItemActionSelection,
  addCategoryOptions,
  addMenuItemOptions,
}) => {
  // Shared states
  const {
    menuCategories, menuItems, combos, selectedMenu, allergens, dietGroups, selectedCategory, setSelectedCategory
  } = useRestaurant();

  const [anchorElAdd, setAnchorElAdd] = useState(null);
  const [anchorElEdit, setAnchorElEdit] = useState(null);
  const [currentItem, setCurrentItem] = useState(null);

  const openAddMenu = Boolean(anchorElAdd);
  const openEditMenu = Boolean(anchorElEdit);

  const handleAddButtonClick = (event, categoryId) => {
    setSelectedCategory(categoryId)
    setAnchorElAdd(event.currentTarget);
  };

  const handleAddMenuClose = () => {
    setAnchorElAdd(null);
  };

  const handleMenuItemClick = (option) => {
    if (selectedCategory) {  // Ensure selectedCategory is set
      handleAddMenuItemSelection(option, selectedCategory); // Pass the selected category to parent
    }
    handleAddMenuClose();
  };

  const handleMoreVertClick = (event, item) => {
    setAnchorElEdit(event.currentTarget);
    setCurrentItem(item);
  };

  const handleEditMenuClose = () => {
    setAnchorElEdit(null);
    setCurrentItem(null);
  };

  const handleEditClick = () => {
    if (currentItem.type === 'category') {
      openEditCategorySidebar(currentItem.data);
    } else if (currentItem.type === 'menuItem') {
      openEditMenuItemSidebar(currentItem.data);
    } else if (currentItem.type === 'combo') {
      openEditComboSidebar(currentItem.data);
    }
    handleEditMenuClose();
  };

  const handleDeleteClick = () => {
    openDeleteConfirmation(currentItem.data.id, currentItem.type, selectedMenu, currentItem.categoryId);
    handleEditMenuClose();
  };

  const renderItemAllergensAndDietGroups = (items) => {
    if (!items) {
      return <div />;
    }

    // Extract allergen names directly from the combo
    const itemAllergenNames = items.allergens.map(allergenId => {
      const allergen = allergens.find(a => a.id === allergenId); // Consumed from context
      return allergen ? allergen.name : 'Unknown Allergen';
    });

    // Extract diet group names directly from the combo
    const itemDietGroupNames = items.diet_groups.map(dietGroupId => {
      const dietGroup = dietGroups.find(d => d.id === dietGroupId); // Consumed from context
      return dietGroup ? dietGroup.name : 'Unknown Diet Group';
    });

    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '6px', padding: '16px 0 8px 0' }}>
        <Typography color="blue" variant="body">{itemAllergenNames.length ? itemAllergenNames.join(', ') : 'Add allergens if needed'}</Typography>
        <Typography color="grey" variant="body">Diet Groups: {itemDietGroupNames.length ? itemDietGroupNames.join(', ') : 'None'}</Typography>
      </div>
    );
  };

return (
  <ListContainer>
    <Droppable droppableId="droppableCategories" type="CATEGORY">
      {(provided) => (
        <div ref={provided.innerRef} {...provided.droppableProps}>
          {menuCategories.map((category, index) => (
            <Draggable key={category.id} draggableId={`category-${category.id}`} index={index}>
              {(provided) => (
                <ListContainer ref={provided.innerRef} {...provided.draggableProps}>
                  <CategoryContainer>
                    <TabsContainer>
                      <CategoryTab {...provided.dragHandleProps}>
                        <HeaderContainer>
                          <IconColumn onClick={() => toggleCategory(category.id)}>
                            {renderToggleIcon(expandedCategories[category.id])}
                          </IconColumn>
                          <Typography variant="h6" style={{ flexGrow: 1 }}>
                            {category.name}
                          </Typography>
                        </HeaderContainer>
                        <CategoryDescriptionTab>
                          {category.description || 'No description provided'}
                        </CategoryDescriptionTab>
                        <IconButton
                          onClick={(event) =>
                            handleMoreVertClick(event, {
                              type: 'category',
                              data: category,
                            })
                          }
                          style={{ position: 'absolute', top: '50%', right: '12px', transform: 'translateY(-50%)' }}
                        >
                          <MoreVert />
                        </IconButton>
                      </CategoryTab>
                    </TabsContainer>
                  </CategoryContainer>
                  {expandedCategories[category.id] && (
                    <>
                      <Droppable droppableId={`droppableItems-${category.id}`} type="ITEM">
                        {(provided) => (
                          <div ref={provided.innerRef} {...provided.droppableProps}>
                            {menuItems
                              .filter((item) => item.categories.includes(category.id))
                              .map((item, itemIndex) => (
                                <Draggable
                                  key={item.id}
                                  draggableId={`item-${item.id}`}
                                  index={itemIndex}
                                >
                                  {(provided) => (
                                    <div ref={provided.innerRef} {...provided.draggableProps}>
                                      <ItemContainer>
                                        <TabsContainer>
                                          <MenuItemTab {...provided.dragHandleProps}>
                                            <HeaderContainer>
                                              <IconColumn onClick={() => toggleItem(item.id)}>
                                                {renderToggleIcon(expandedItems[item.id])}
                                              </IconColumn>
                                              <ItemName>{item.name}</ItemName>
                                              <PriceDisplay>€{item.price}</PriceDisplay>
                                              <IconButton
                                                onClick={(event) =>
                                                  handleMoreVertClick(event, {
                                                    type: 'menuItem',
                                                    data: item,
                                                    categoryId: category.id,
                                                  })
                                                }
                                              >
                                                <MoreVert />
                                              </IconButton>
                                            </HeaderContainer>
                                            <Collapse
                                              in={expandedItems[item.id]}
                                              timeout="auto"
                                              unmountOnExit
                                            >
                                              <MenuItemDetails>
                                                <TextDetails>
                                                  <Typography variant="body">{item.description}</Typography>
                                                  {renderItemAllergensAndDietGroups(item)}
                                                </TextDetails>
                                                <PictureContainer>
                                                  <img
                                                    src={item.picture ? item.picture : logo }
                                                    alt={item.name || 'default image'}
                                                  />
                                                </PictureContainer>
                                              </MenuItemDetails>
                                            </Collapse>
                                          </MenuItemTab>
                                        </TabsContainer>
                                      </ItemContainer>
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                            <Droppable droppableId={`droppableCombos-${category.id}`} type="COMBOS">
                              {(provided) => (
                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                  {combos
                                    .filter((combo) => combo.categories.includes(category.id))
                                    .map((combo, comboIndex) => (
                                      <Draggable
                                        key={combo.id}
                                        draggableId={`combo-${combo.id}`}
                                        index={comboIndex}
                                      >
                                        {(provided) => (
                                          <div ref={provided.innerRef} {...provided.draggableProps}>
                                            <ItemContainer>
                                              <TabsContainer>
                                                <MenuItemTab {...provided.dragHandleProps}>
                                                  <HeaderContainer>
                                                    <IconColumn onClick={() => toggleItem(combo.id)}>
                                                      {renderToggleIcon(expandedItems[combo.id])}
                                                    </IconColumn>
                                                    <ItemName>{combo.name}</ItemName>
                                                    <PriceDisplay>€{combo.price}</PriceDisplay>
                                                    <IconButton
                                                      onClick={(event) =>
                                                        handleMoreVertClick(event, {
                                                          type: 'combo',
                                                          data: combo,
                                                          categoryId: category.id,
                                                        })
                                                      }
                                                    >
                                                      <MoreVert />
                                                    </IconButton>
                                                  </HeaderContainer>
                                                  <Collapse
                                                    in={expandedItems[combo.id]}
                                                    timeout="auto"
                                                    unmountOnExit
                                                  >
                                                    <MenuItemDetails>
                                                      <TextDetails>
                                                        <Typography color="secondary">
                                                          You selected: {combo.item_names}
                                                        </Typography>
                                                        <Typography variant="body">{combo.description}</Typography>
                                                        {renderItemAllergensAndDietGroups(combo)}
                                                      </TextDetails>
                                                      <PictureContainer>
                                                        <img
                                                          src={combo.picture ? combo.picture : logo }
                                                          alt={combo.name || 'default image'}
                                                        />
                                                      </PictureContainer>
                                                    </MenuItemDetails>
                                                  </Collapse>
                                                </MenuItemTab>
                                              </TabsContainer>
                                            </ItemContainer>
                                          </div>
                                        )}
                                      </Draggable>
                                    ))}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                            {provided.placeholder}
                            <ItemContainer>
                              <TabsContainer>
                                <ItemPlaceholder>
                                  <UnderlinedButton
                                    onClick={(event) => handleAddButtonClick(event, category.id)}
                                    open={openAddMenu}
                                  >
                                    Add a dish, drink or combo
                                  </UnderlinedButton>
                                  <Menu
                                    anchorEl={anchorElAdd}
                                    open={openAddMenu}
                                    onClose={handleAddMenuClose}
                                  >
                                    {addMenuItemOptions.map((option) => (
                                      <MenuItem
                                        key={option.value}
                                        onClick={() => handleMenuItemClick(option)}
                                      >
                                        {option.label}
                                      </MenuItem>
                                    ))}
                                  </Menu>
                                </ItemPlaceholder>
                              </TabsContainer>
                            </ItemContainer>
                          </div>
                        )}
                      </Droppable>
                    </>
                  )}
                </ListContainer>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
    <Menu anchorEl={anchorElEdit} open={openEditMenu} onClose={handleEditMenuClose}>
      <MenuItem onClick={handleEditClick}>Edit</MenuItem>
      <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
    </Menu>
  </ListContainer>
);
};

export default CategoryList;
