import React from 'react';
import styled from 'styled-components';

const Pad = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin: 10px 0;
`;

const PadButton = styled.button`
  padding: 20px;
  font-size: 24px;
  background-color: #121212;
  border: none;
  color: white;
  border-radius: 10px;
  &:hover {
    background-color: #3e3effe6;
  }
`;

const DeleteButton = styled(PadButton)`
  grid-column: -2 / span 1;
`;

const NumberPad = ({ onInput, onDelete }) => {
  const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  return (
    <Pad>
      {numbers.map((number) => (
        <PadButton key={number} onClick={() => onInput(number)}>
          {number}
        </PadButton>
      ))}
      <div></div>
      <PadButton onClick={() => onInput(0)}>0</PadButton>
      <DeleteButton onClick={onDelete}>⌫</DeleteButton>
    </Pad>
  );
};

export default NumberPad;
