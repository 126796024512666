import React from 'react';
import { Routes, Route } from 'react-router-dom';
import styled from 'styled-components';
import RestaurantOverview from './RestaurantOverview';
import MenuOverview from './MenuOverview';
import QRCode from './QRCode';
import TeamManagement from './TeamManagement';
import Payments from './Payments';
import AnalyticsHome from './AnalyticsHome';

const MainContentContainer = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding-top: 20px;
`;

const MainContent = () => {
    return (
        <MainContentContainer>
            <Routes>
                <Route path="/my-restaurants" element={<RestaurantOverview />} />
                <Route path="/menu-overview" element={<MenuOverview />} />
                <Route path="/qr-codes" element={<QRCode />} />
                <Route path="/my-team" element={<TeamManagement />} />
                <Route path="/payments" element={<Payments />} />
                <Route path="/analytics" element={<AnalyticsHome />} />
                {/* Define other nested routes here */}
            </Routes>
        </MainContentContainer>
    );
};

export default MainContent;
