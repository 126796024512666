import React from 'react';
import { usePOS } from '../../../contexts/POSContext';
import styled from 'styled-components';
import { CircularProgress, Grid as MuiGrid, Typography } from '@mui/material';

const LeftContainer = styled.div`
  width: 40%;
  background-color: #1f1f1f;
  overflow-y: auto;
  border-radius: 10px;
  padding-top: 10px;
  max-height: calc(100vh - 130px); /* Adjust height to ensure it fits within the viewport */
  @media (max-width: 600px) {
    display: none;
  }
`;

const CategoryTitle = styled.h5`
  display: flex;
  color: #ff6a3ee3;
  justify-content: center;
  padding-bottom: 20px;
  padding-top: 10px;
  margin: 0px;
`;

const GridContainer = styled(MuiGrid)`
  flex-grow: 1;
  justify-content: center;
  && {
    gap: 8px;
  }
`;

const GridItem = styled(MuiGrid)`
  && {
    background-color: #fff;
    padding: 12px;
    box-sizing: border-box;
    border: 1px solid var(--color-secondary);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;
  }
`;

const MenuItems = ({ handleItemClick }) => {
  const { loading, categories, menuItems, combos, selectedCategory } = usePOS();

  const renderMenuItemsAndCombos = () => (
    <GridContainer container>
      {menuItems.map((item) => (
        <GridItem item xs={12} sm={8} md={6} lg={4} key={item.id} onClick={() => handleItemClick(item)}>
          <Typography>{item.name}</Typography>
        </GridItem>
      ))}
      {combos.map((combo) => (
        <GridItem item xs={12} sm={8} md={6} lg={4} key={combo.id} onClick={() => handleItemClick(combo)}>
          <Typography>{combo.name}</Typography>
        </GridItem>
      ))}
    </GridContainer>
  );

  return (
    <LeftContainer>
      <CategoryTitle>
        {categories.find(category => category.id === selectedCategory)?.name || ''}
      </CategoryTitle>
      {loading ? <CircularProgress /> : renderMenuItemsAndCombos()}
    </LeftContainer>
  );
};

export default MenuItems;
