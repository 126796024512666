// RestaurantWizard v1.75
import React, { useState } from 'react';
import {
  Stepper, Step, StepLabel, Button, Typography, CircularProgress, TextField, Alert, Box, Checkbox, FormControlLabel, Autocomplete, Divider, IconButton
} from '@mui/material';
import { createRestaurant } from '../../../services/apiService';
import { validateAddress } from '../../../services/externalApiService';
import { useRestaurant } from '../../../contexts/RestaurantContext';
import PictureUpload from '../../Common/PictureUpload';
import { MuiTelInput } from 'mui-tel-input';
import countries from 'country-flag-icons/react/3x2';
import { checkVAT, countries as vatCountries } from 'jsvat-next';
import CustomTimePicker from '../../Common/CustomTimePicker';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineRounded from '@mui/icons-material/DeleteOutlineRounded';

// Country options with flags
const countryOptions = [
  { code: 'IT', label: 'Italia', icon: <countries.IT style={{ width: 20, marginRight: 8 }} /> },
  { code: 'FR', label: 'France', icon: <countries.FR style={{ width: 20, marginRight: 8 }} /> },
  { code: 'DE', label: 'Deutschland', icon: <countries.DE style={{ width: 20, marginRight: 8 }} /> },
  // Add more countries as needed
];

// Create VAT country options
const vatCountryOptions = vatCountries.map((country) => ({
  code: country.codes[0],
  label: country.codes[0],
}));

const steps = ['Restaurant Details', 'Address Details', 'Opening Hours', 'Extras'];

const WizardContainer = (props) => (
  <Box display="flex" flexDirection="column" height="100%" {...props} />
);

const StepContainer = (props) => (
  <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    flexGrow={1}
    justifyContent="flex-start"
    padding="2rem 0"
    {...props}
  />
);

const RestaurantWizard = ({ onComplete }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [restaurantName, setRestaurantName] = useState('');
  const [description, setDescription] = useState('');
  const [vatCountryCode, setVatCountryCode] = useState('IT');
  const [vatNumber, setVatNumber] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const [legalAddress, setLegalAddress] = useState({
    name: '',
    address_line_1: '',
    postal_code: '',
    city: '',
    state: '',
    country: 'Italy', // Default country set to Italy
  });

  const [sameAsLegalAddress, setSameAsLegalAddress] = useState(true);

  const [restaurantAddress, setRestaurantAddress] = useState({
    name: '',
    address_line_1: '',
    postal_code: '',
    city: '',
    state: '',
    country: 'Italy', // Default country set to Italy
  });

  // Updated openingHours state structure
  const [openingHours, setOpeningHours] = useState([
    { day_of_week: 0, is_open: false, time_slots: [{ opening_time: null, closing_time: null }] },
    { day_of_week: 1, is_open: false, time_slots: [{ opening_time: null, closing_time: null }] },
    { day_of_week: 2, is_open: false, time_slots: [{ opening_time: null, closing_time: null }] },
    { day_of_week: 3, is_open: false, time_slots: [{ opening_time: null, closing_time: null }] },
    { day_of_week: 4, is_open: false, time_slots: [{ opening_time: null, closing_time: null }] },
    { day_of_week: 5, is_open: false, time_slots: [{ opening_time: null, closing_time: null }] },
    { day_of_week: 6, is_open: false, time_slots: [{ opening_time: null, closing_time: null }] },
  ]);

  const defaultTimeSlot = {
    opening_time: '09:00',
    closing_time: '22:00',
  };

  const [logo, setLogo] = useState(null);
  const [coverImage, setCoverImage] = useState(null);
  const [previewLogoUrl, setPreviewLogoUrl] = useState(null);
  const [previewCoverImageUrl, setPreviewCoverImageUrl] = useState(null);

  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const { loadUserRestaurants } = useRestaurant();

  // Helper function to format time values to HH:mm format
  const formatTime = (time) => {
    if (!time) return null; // Handle null or undefined time values
    if (typeof time === 'string') return time; // If already in string format
    // Otherwise, assume it's a Date and format to HH:mm
    const hours = time.getHours().toString().padStart(2, '0');
    const minutes = time.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  const handleFinish = async () => {
    setLoading(true);
    setAlertMessage(null);

    const formData = new FormData();
    formData.append('name', restaurantName);
    formData.append('description', description);
    const fullVatNumber = vatCountryCode + vatNumber.trim();
    formData.append('vat_number', fullVatNumber);
    if (phoneNumber) {
      formData.append('phone_number', phoneNumber);
    }

    // Automatically set the restaurant name in addresses
    const legalAddressWithName = { ...legalAddress, name: restaurantName };
    const restaurantAddressWithName = sameAsLegalAddress
      ? legalAddressWithName
      : { ...restaurantAddress, name: restaurantName };

    // Append legal_address fields individually
    for (const key in legalAddressWithName) {
      formData.append(`legal_address.${key}`, legalAddressWithName[key]);
    }

    // Append restaurant_address fields individually
    for (const key in restaurantAddressWithName) {
      formData.append(`restaurant_address.${key}`, restaurantAddressWithName[key]);
    }

    // Ensure opening hours are formatted with required fields
    const formattedOpeningHours = openingHours
      .filter((day) => day.is_open)
      .flatMap((day) =>
        day.time_slots.map((slot) => ({
          day_of_week: day.day_of_week,
          opening_time: formatTime(slot.opening_time),
          closing_time: formatTime(slot.closing_time),
        }))
      );

    formData.append('opening_hours', JSON.stringify(formattedOpeningHours));

    // Images
    if (logo) formData.append('restaurant_logo', logo);
    if (coverImage) formData.append('cover_image', coverImage);

    try {
      const newRestaurant = await createRestaurant(formData);
      loadUserRestaurants();
      onComplete(newRestaurant.id);
    } catch (error) {
      setAlertMessage({ type: 'error', text: 'Error creating restaurant. Please try again.' });
      console.error('Error creating restaurant:', error);
    } finally {
      setLoading(false);
    }
  };

  const getDayName = (dayIndex) => {
    const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    return days[dayIndex];
  };

  const handleAddressValidation = async (addressToValidate) => {
      try {
          const result = await validateAddress(addressToValidate);
          const { verdict, correctedAddress } = result;

          if (verdict?.hasUnconfirmedComponents) {
              setAlertMessage({ type: 'warning', text: 'Please review the address fields' });
              return null;
          }
          return correctedAddress || addressToValidate;
      } catch (error) {
          setAlertMessage({ type: 'warning', text: 'Address validation failed. Please try again.' });
          return null;
      }
  };

  const handleNext = async () => {
      setAlertMessage(null);
      if (activeStep === 0) {
          if (!restaurantName.trim() || !vatNumber.trim()) {
              setAlertMessage({ type: 'warning', text: 'Please fill in all required fields' });
              return;
          }
          const fullVatNumber = vatCountryCode + vatNumber.trim();
          const vatCheck = checkVAT(fullVatNumber, vatCountries);
            if (!vatCheck.isValid) {
              setAlertMessage({ type: 'warning', text: 'Invalid VAT number.' });
              return;
          }
      }

      if (activeStep === 1) {
          const address = {
              addressLines: [legalAddress.address_line_1],
              locality: legalAddress.city,
              postalCode: legalAddress.postal_code,
              regionCode: countryOptions.find(country => country.label === legalAddress.country)?.code || 'IT',
          };

          const validatedAddress = await handleAddressValidation(address);
          if (!validatedAddress) return;

          setLegalAddress({
              ...legalAddress,
              address_line_1: validatedAddress.addressLines[0],
              city: validatedAddress.locality,
              postal_code: validatedAddress.postalCode,
          });
      }

    if (activeStep === 2) {
      const hasErrors = openingHours.some((day) => {
        if (day.is_open) {
          return day.time_slots.some(
            (slot) => !slot.opening_time || !slot.closing_time || slot.opening_time >= slot.closing_time
          );
        }
        return false;
      });
      if (hasErrors) {
        setAlertMessage({
          type: 'warning',
          text: 'Please fill in all opening and closing times for selected days.',
        });
        return;
      }

      // Check for overlapping time slots within each day
      const hasOverlap = openingHours.some((day) => {
        if (!day.is_open) return false;

        const times = day.time_slots.map((slot) => ({
          start: new Date(`1970-01-01T${slot.opening_time}:00`),
          end: new Date(`1970-01-01T${slot.closing_time}:00`),
        }));

        // Loop through time slots to check for overlap
        for (let i = 0; i < times.length; i++) {
          for (let j = i + 1; j < times.length; j++) {
            if (
              (times[i].start < times[j].end && times[i].end > times[j].start) ||
              (times[j].start < times[i].end && times[j].end > times[i].start)
            ) {
              return true; // Overlap detected
            }
          }
        }
        return false;
      });

      if (hasOverlap) {
        setAlertMessage({
          type: 'error',
          text: 'Please ensure there are no overlapping time slots for any day.',
        });
        return;
      }
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleFileSelectLogo = (file, previewUrl) => {
    setLogo(file);
    setPreviewLogoUrl(previewUrl);
  };

  const handleFileSelectCoverImage = (file, previewUrl) => {
    setCoverImage(file);
    setPreviewCoverImageUrl(previewUrl);
  };

  const handleClearLogo = () => {
    setLogo(null);
    setPreviewLogoUrl(null);
  };

  const handleClearCoverImage = () => {
    setCoverImage(null);
    setPreviewCoverImageUrl(null);
  };

  const handleSameAsLegalAddressChange = (event) => {
    setSameAsLegalAddress(event.target.checked);
  };

  // Handler to add a new time slot with adjusted defaults
  const handleAddTimeSlot = (dayIndex) => {
    setOpeningHours((prev) => {
      const updated = [...prev];
      const day = updated[dayIndex];
      const lastSlot = day.time_slots[day.time_slots.length - 1];

      // Determine the latest time from the last slot and format it as a string
      let newOpeningTime = lastSlot.closing_time || '09:00'; // Default to '09:00' if no time is set
      let newClosingTime = '22:00';

      // Add 1 hour to the latest time for new opening_time
      if (lastSlot.closing_time) {
        const [hours, minutes] = lastSlot.closing_time.split(':');
        newOpeningTime = `${String(Number(hours) + 1).padStart(2, '0')}:${minutes}`;
      }

      day.time_slots.push({
        opening_time: newOpeningTime,
        closing_time: newClosingTime,
      });
      return updated;
    });
  };

  // Handler to remove a time slot
  const handleRemoveTimeSlot = (dayIndex, slotIndex) => {
    setOpeningHours((prev) => {
      const updated = [...prev];
      updated[dayIndex].time_slots.splice(slotIndex, 1);
      if (updated[dayIndex].time_slots.length === 0) {
        // Ensure there's at least one time slot
        updated[dayIndex].time_slots.push({ opening_time: null, closing_time: null });
      }
      return updated;
    });
  };

  // Handler for when a day's is_open checkbox is changed
  const handleDayOpenChange = (e, dayIndex) => {
    const updatedHours = [...openingHours];
    const day = updatedHours[dayIndex];
    day.is_open = e.target.checked;

    if (e.target.checked) {
      // Copy time slots from previous open day
      let prevDayIndex = dayIndex;
      let found = false;

      while (true) {
        prevDayIndex = (prevDayIndex - 1 + openingHours.length) % openingHours.length;
        if (prevDayIndex === dayIndex) {
          break;
        }

        const prevDay = updatedHours[prevDayIndex];
        if (prevDay.is_open && prevDay.time_slots.length) {
          // Copy time_slots
          day.time_slots = prevDay.time_slots.map((slot) => ({
            opening_time: slot.opening_time || defaultTimeSlot.opening_time,
            closing_time: slot.closing_time || defaultTimeSlot.closing_time,
          }));
          found = true;
          break;
        }
      }

      if (!found) {
        // No previous open day found, set default time slot
        day.time_slots = [
          {
            opening_time: defaultTimeSlot.opening_time,
            closing_time: defaultTimeSlot.closing_time,
          },
        ];
      }
    } else {
      // Reset time_slots when unchecking the day
      day.time_slots = [{ opening_time: null, closing_time: null }];
    }
    setOpeningHours(updatedHours);
  };

  return (
    <WizardContainer>
      <Stepper activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel style={{ color: 'grey' }}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {loading ? (
        <StepContainer>
          <CircularProgress />
        </StepContainer>
      ) : (
        <StepContainer>
          {alertMessage && (
            <Alert severity={alertMessage.type} sx={{ width: '100%', maxWidth: '700px', mb: 2 }}>
              {alertMessage.text}
            </Alert>
          )}
          {activeStep === steps.length ? (
            <Typography>All steps completed - you're finished</Typography>
          ) : (
            <>
              {activeStep === 0 && (
                <Box width="100%" maxWidth="700px" mb={2} mt={2}>
                  <Typography variant="body2" color="textSecondary" mb={0} pb={0}>
                    Add a name to your restaurant
                  </Typography>
                  <TextField
                    sx={{ mt: '10px', mb: '20px'}}
                    fullWidth
                    required
                    variant="outlined"
                    label="Restaurant Name"
                    value={restaurantName}
                    onChange={(e) => setRestaurantName(e.target.value)}
                    margin="normal"
                  />
                  <Typography variant="body2" color="textSecondary" mb={0} pb={0}>
                    Add a description for your restaurant (optional)
                  </Typography>
                  <TextField
                    sx={{ mt: '10px', mb: '20px'}}
                    fullWidth
                    variant="outlined"
                    label="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    margin="normal"
                    multiline
                  />
                  <Typography variant="body2" color="textSecondary" mb={0} pb={0}>
                    VAT Number
                  </Typography>
                  <Box display="flex" alignItems="center">
                    <Autocomplete
                      options={vatCountryOptions}
                      value={vatCountryOptions.find((country) => country.code === vatCountryCode)}
                      onChange={(event, newValue) => {
                        setVatCountryCode(newValue ? newValue.code : 'IT'); // Default to 'IT' if null
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Country" variant="outlined" margin="normal" sx={{ mt: '10px', mb: '20px'}} />
                      )}
                      getOptionLabel={(option) => option.code}
                      sx={{ width: 100, mr: 2 }}
                    />
                    <TextField
                      sx={{ mt: '10px', mb: '20px'}}
                      fullWidth
                      required
                      variant="outlined"
                      label="VAT Number"
                      value={vatNumber}
                      onChange={(e) => setVatNumber(e.target.value)}
                      margin="normal"
                    />
                  </Box>
                  <Typography variant="body2" color="textSecondary" mb={0} pb={0}>
                    Phone Number (optional)
                  </Typography>
                  <MuiTelInput
                    sx={{ mt: '10px', mb: '20px'}}
                    fullWidth
                    variant="outlined"
                    value={phoneNumber}
                    defaultCountry="IT" // Default to Italy
                    onChange={(value) => setPhoneNumber(value)}
                    margin="normal"
                  />
                </Box>
              )}
              {activeStep === 1 && (
                <>
                  <Box width="100%" maxWidth="700px" mb={2}>
                    <Typography variant="h6">Legal Address</Typography>
                    <TextField
                      fullWidth
                      label="Address Line 1"
                      value={legalAddress.address_line_1}
                      onChange={(e) => setLegalAddress({ ...legalAddress, address_line_1: e.target.value })}
                      margin="normal"
                    />
                    <TextField
                      fullWidth
                      label="Postal Code"
                      value={legalAddress.postal_code}
                      onChange={(e) => setLegalAddress({ ...legalAddress, postal_code: e.target.value })}
                      margin="normal"
                    />
                    <TextField
                      fullWidth
                      label="City"
                      value={legalAddress.city}
                      onChange={(e) => setLegalAddress({ ...legalAddress, city: e.target.value })}
                      margin="normal"
                    />
                    <TextField
                      fullWidth
                      label="State (optional)"
                      value={legalAddress.state}
                      onChange={(e) => setLegalAddress({ ...legalAddress, state: e.target.value })}
                      margin="normal"
                    />
                    <Autocomplete
                      options={countryOptions}
                      value={countryOptions.find((country) => country.label === legalAddress.country)}
                      onChange={(event, newValue) => {
                        setLegalAddress({ ...legalAddress, country: newValue ? newValue.label : '' });
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Country" variant="outlined" fullWidth />
                      )}
                      getOptionLabel={(option) => option.label}
                      renderOption={(props, option) => (
                        <Box component="li" {...props}>
                          {option.icon} {option.label}
                        </Box>
                      )}
                      sx={{ mt: 2 }}
                    />
                  </Box>
                  <Box width="100%" maxWidth="700px" mb={2}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={sameAsLegalAddress}
                          onChange={handleSameAsLegalAddressChange}
                          color="primary"
                        />
                      }
                      label="Restaurant Address same as Legal Address"
                    />
                    {!sameAsLegalAddress && (
                      <>
                        <Typography variant="h6">Restaurant Address</Typography>
                        <TextField
                          fullWidth
                          label="Address Line 1"
                          value={restaurantAddress.address_line_1}
                          onChange={(e) => setRestaurantAddress({ ...restaurantAddress, address_line_1: e.target.value })}
                          margin="normal"
                        />
                        <TextField
                          fullWidth
                          label="Postal Code"
                          value={restaurantAddress.postal_code}
                          onChange={(e) => setRestaurantAddress({ ...restaurantAddress, postal_code: e.target.value })}
                          margin="normal"
                        />
                        <TextField
                          fullWidth
                          label="City"
                          value={restaurantAddress.city}
                          onChange={(e) => setRestaurantAddress({ ...restaurantAddress, city: e.target.value })}
                          margin="normal"
                        />
                        <TextField
                          fullWidth
                          label="State (optional)"
                          value={restaurantAddress.state}
                          onChange={(e) => setRestaurantAddress({ ...restaurantAddress, state: e.target.value })}
                          margin="normal"
                        />
                        <Autocomplete
                          options={countryOptions}
                          value={countryOptions.find((country) => country.label === restaurantAddress.country)}
                          onChange={(event, newValue) => {
                            setRestaurantAddress({ ...restaurantAddress, country: newValue ? newValue.label : '' });
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="Country" variant="outlined" fullWidth />
                          )}
                          getOptionLabel={(option) => option.label}
                          renderOption={(props, option) => (
                            <Box component="li" {...props}>
                              {option.icon} {option.label}
                            </Box>
                          )}
                          sx={{ mt: 2 }}
                        />
                      </>
                    )}
                  </Box>
                </>
              )}
              {activeStep === 2 && (
                <Box width="100%" maxWidth="700px" mb={2}>
                  <Typography variant="body">
                    Add operating hours for reservations and online orders
                  </Typography>
                  {openingHours.map((day, dayIndex) => (
                    <Box key={day.day_of_week} mt={2}>
                      <Divider sx={{ mb: 2 }} />
                      <Box display="flex" alignItems="center">
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              checked={day.is_open}
                              onChange={(e) => handleDayOpenChange(e, dayIndex)}
                              color="primary"
                            />
                          }
                          label={getDayName(day.day_of_week)}
                        />
                      </Box>
                      {day.is_open &&
                        day.time_slots.map((slot, slotIndex) => (
                          <Box
                            key={slotIndex}
                            display="flex"
                            alignItems="center"
                            mt={2}
                            gap={1}
                            sx={{ flexWrap: {xs: 'wrap', sm: 'nowrap'} }}
                          >
                            <CustomTimePicker
                              label="Opening Time"
                              value={slot.opening_time || defaultTimeSlot.opening_time}
                              onChange={(newValue) => {
                                setOpeningHours((prev) => {
                                  const updated = [...prev];
                                  updated[dayIndex].time_slots[slotIndex].opening_time = newValue;
                                  return updated;
                                });
                              }}
                              timeInterval={30}
                            />
                            <CustomTimePicker
                              label="Closing Time"
                              value={slot.closing_time || defaultTimeSlot.closing_time}
                              onChange={(newValue) => {
                                setOpeningHours((prev) => {
                                  const updated = [...prev];
                                  updated[dayIndex].time_slots[slotIndex].closing_time = newValue;
                                  return updated;
                                });
                              }}
                              timeInterval={30}
                            />
                            <IconButton
                              onClick={() => handleAddTimeSlot(dayIndex)}
                              color="primary"
                            >
                              <AddIcon fontSize="small" />
                            </IconButton>
                            {day.time_slots.length > 1 && (
                              <IconButton
                                onClick={() => handleRemoveTimeSlot(dayIndex, slotIndex)}
                                color="secondary"
                              >
                                <DeleteOutlineRounded fontSize="small" />
                              </IconButton>
                            )}
                          </Box>
                        ))}
                    </Box>
                  ))}
                </Box>
              )}

              {activeStep === 3 && (
                <>
                  <Box width="100%" maxWidth="700px" mb={2} textAlign="center">
                    <Typography variant="body2">Upload a logo for your restaurant (optional)</Typography>
                    <Box display="flex" justifyContent="center" mt={2}>
                      <PictureUpload
                        existingFile={null}
                        previewUrl={previewLogoUrl}
                        onFileSelect={handleFileSelectLogo}
                        onClearImage={handleClearLogo}
                      />
                    </Box>
                  </Box>
                  <Box width="100%" maxWidth="700px" mb={2} textAlign="center">
                    <Typography variant="body2">Upload a cover image for your restaurant (optional)</Typography>
                    <Box display="flex" justifyContent="center" mt={2}>
                      <PictureUpload
                        existingFile={null}
                        previewUrl={previewCoverImageUrl}
                        onFileSelect={handleFileSelectCoverImage}
                        onClearImage={handleClearCoverImage}
                      />
                    </Box>
                  </Box>
                </>
              )}
            </>
          )}
        </StepContainer>
      )}
      <Box
        display="flex"
        justifyContent="space-between"
        padding="1rem 0"
        width="95%"
        marginLeft="auto"
        marginRight="auto"
      >
        <Button
          disabled={activeStep === 0}
          onClick={handleBack}
          variant="outlined"
          color="primary"
        >
          Back
        </Button>
        <Button onClick={activeStep === steps.length - 1 ? handleFinish : handleNext} variant="contained" color="primary">
          {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
        </Button>
      </Box>
    </WizardContainer>
  );
};

export default RestaurantWizard;