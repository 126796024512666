import React from 'react';
import { Dialog, DialogContent, Typography, Button, Box, Divider } from '@mui/material';
import { styled } from '@mui/system';

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  width: '100%',
}));

const BillReviewModal = ({ isOpen, onClose, billDetails, orderId, onOpenPaymentDrawer }) => {
  const sessionKey = localStorage.getItem('session_key') || '';

  // Helper function to format date to a more readable format
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' });
  };

  // Helper function to safely render item prices
  const formatPrice = (price) => (price ? Number(price).toFixed(2) : '0.00');

  // Helper function to render items based on their session_key
  const renderItems = (items, title) => (
    <>
      <Typography variant="h6">{title}</Typography>
      {items && items.length > 0 ? (
        items.map((item, index) => (
          <Box key={`${title.toLowerCase()}-item-${index}`} display="flex" justifyContent="space-between">
            <Typography>{item.name}</Typography>
            <Typography>
              {item.quantity} x €{formatPrice(item.price)}
            </Typography>
          </Box>
        ))
      ) : (
        <Typography>No {title.toLowerCase()} found.</Typography>
      )}
      <Divider sx={{ my: 2 }} />
    </>
  );

  if (!isOpen || !billDetails) return null;

  const yourItems = (billDetails.all_items || []).filter((item) => item.session_key === sessionKey);
  const otherItems = (billDetails.all_items || []).filter((item) => item.session_key !== sessionKey);
  const amountDue = parseFloat(billDetails.amount_due || 0);

  // Handle opening the Payment Drawer
  const handleOpenPayment = () => {
    onClose(); // Close the BillReviewModal
    onOpenPaymentDrawer(); // Open the PaymentDrawer
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <DialogContent dividers>
        <Typography><strong>{billDetails.restaurant_name}</strong></Typography>
        <Typography>{billDetails.restaurant_address}</Typography>
        <Typography>Table #{billDetails.table_number}</Typography>
        <Typography>{formatDate(billDetails.issue_date)}</Typography>
        <Divider sx={{ my: 2 }} />

        {/* Render Your Items */}
        {renderItems(yourItems, 'Your Items')}

        {/* Render Other Items */}
        {renderItems(otherItems, 'Other Items')}

        <Typography variant="h6" sx={{ paddingBottom: '10px' }}>
          <strong>Total: €{formatPrice(billDetails.total_amount)}</strong>
        </Typography>

        <Typography variant="body1" color="primary">
          Total Paid: €{formatPrice(billDetails.total_paid)}
        </Typography>

        <Typography variant="body1" color={amountDue > 0 ? 'secondary' : 'primary'}>
          Amount Due: €{formatPrice(amountDue)}
        </Typography>

        {/* Conditionally render the "Pay Bill" button only if there is an amount due */}
        {amountDue > 0 && (
          <StyledButton variant="contained" color="primary" onClick={handleOpenPayment}>
            Pay Bill
          </StyledButton>
        )}

        {/* Close Button */}
        <StyledButton onClick={onClose} variant="outlined" color="secondary">
          Close
        </StyledButton>
      </DialogContent>
    </Dialog>
  );
};

export default BillReviewModal;
