import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import styled from 'styled-components';
import PaymentWidget from './PaymentWidget';

const PaymentDetailsContainer = styled.div`
  padding: 20px;
  height: 100%;
`;

const SelectItemsPayment = ({ selectedItems, orderData, onPaymentSuccess }) => {
  const [amountDue, setAmountDue] = useState(0);

  useEffect(() => {
    const calculateSelectedItemsTotal = () => {
      let total = 0;
      if (orderData && orderData.items) {
        Object.entries(orderData.items).forEach(([courseName, items]) => {
          Object.entries(items).forEach(([itemName, data]) => {
            if (selectedItems.includes(data.id)) {
              total += data.price * data.quantity;
            }
          });
        });
      }
      return total.toFixed(2);
    };

    setAmountDue(calculateSelectedItemsTotal());
  }, [selectedItems, orderData]);

  const handlePaymentSuccess = () => {
    setAmountDue(0);
    onPaymentSuccess(); // Reset the payment state and fetch new payment details
  };

  return (
    <PaymentDetailsContainer>
      <Typography variant="h6">Select Items Payment</Typography>
      <PaymentWidget
        amountDue={amountDue}
        orderId={orderData.orderId}
        onPaymentSuccess={handlePaymentSuccess}
        paymentType="custom_payment"
        selectedItems={selectedItems}
      />
    </PaymentDetailsContainer>
  );
};

export default SelectItemsPayment;
