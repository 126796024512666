// ReservationDialog v1.20 
import React, { useEffect } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

const ReservationDialog = ({
  open,
  onClose,
  onSave,
  formValues,
  handleInputChange,
  handleDateChange,
  handleTimeChange,
  formErrors,
  tables,
}) => {
  const [localFormErrors, setLocalFormErrors] = React.useState({});

  // Initialize default values for date and time on initial render
  useEffect(() => {
    if (!formValues.reservation_date) {
      handleDateChange(dayjs().toDate());
    }
    if (!formValues.reservation_time) {
      handleTimeChange(dayjs().set('hour', 20).set('minute', 0).toDate());
    }
    setLocalFormErrors(formErrors); // Sync local errors with props
  }, [formErrors, handleDateChange, handleTimeChange, formValues.reservation_date, formValues.reservation_time]);

  const handleSave = () => {
    const errors = {};

    // Required field validation
    if (!formValues.customer_name) errors.customer_name = 'Customer name is required';
    if (!formValues.reservation_date) errors.reservation_date = 'Reservation date is required';
    if (!formValues.reservation_time) errors.reservation_time = 'Reservation time is required';
    if (!formValues.number_of_guests) errors.number_of_guests = 'Number of guests is required';

    setLocalFormErrors(errors); // Set validation errors

    if (Object.keys(errors).length === 0) {
      onSave(); // Only save if no errors
    }
  };

  // Convert reservation_time to a Date object if it exists
  const getFormattedTime = () => {
    return formValues.reservation_time
      ? new Date(`1970-01-01T${formValues.reservation_time}`)
      : null;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>{formValues.id ? 'Edit Reservation' : 'Add Reservation'}</DialogTitle>
        <DialogContent>
          <TextField
            label="Customer Name"
            name="customer_name"
            value={formValues.customer_name || ''}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            error={!!localFormErrors.customer_name}
            helperText={localFormErrors.customer_name}
          />
          <TextField
            label="Customer Email"
            name="customer_email"
            value={formValues.customer_email || ''}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Customer Phone"
            name="customer_phone"
            value={formValues.customer_phone || ''}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Number of Guests"
            name="number_of_guests"
            type="number"
            value={formValues.number_of_guests || ''}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            error={!!localFormErrors.number_of_guests}
            helperText={localFormErrors.number_of_guests}
          />

          {/* Date and Time Pickers */}
          <Box display="flex" justifyContent="space-between" my={2}>
            <DatePicker
              label="Reservation Date"
              value={formValues.reservation_date ? new Date(formValues.reservation_date) : null}
              onChange={(newDate) => handleDateChange(newDate)}
              textField={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  error={!!localFormErrors.reservation_date}
                  helperText={localFormErrors.reservation_date}
                />
              )}
            />
            <TimePicker
              label="Reservation Time"
              value={getFormattedTime()} // Using the formatted Date object
              onChange={(newTime) => handleTimeChange(newTime)}
              ampm={false}
              textField={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  error={!!localFormErrors.reservation_time}
                  helperText={localFormErrors.reservation_time}
                />
              )}
            />
          </Box>

          {/* Table selection */}
          <FormControl fullWidth margin="normal">
            <InputLabel id="table-label">Table</InputLabel>
            <Select
              labelId="table-label"
              name="table"
              value={formValues.table || ''}
              onChange={handleInputChange}
              label="Table"
            >
              {tables.map((table) => (
                <MenuItem key={table.id} value={table.id}>
                  {`Table ${table.number} (${table.number_of_seats} seats)`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Special Requests"
            name="special_requests"
            value={formValues.special_requests || ''}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            multiline
            rows={1}
          />
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={onClose}>Cancel</Button>
          <Button onClick={handleSave} color="primary">
            {formValues.id ? 'Update' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};

export default ReservationDialog;