import React from 'react';
import styled from 'styled-components';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/logo_tabomenu.png'; 
import MenuIcon from '@mui/icons-material/Menu';
import { SettingsOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { ClickLabel } from '../Common/MUI.StyledComponents';
import useMediaQuery from '@mui/material/useMediaQuery';

const HeaderContainer = styled.div`
  position: sticky;    /* Make the header sticky */
  top: 0;              /* Stick to the top of the viewport */
  z-index: 1000;       /* Ensure the header is above other content */
  background-color: white; /* Ensure the background color is not transparent */
  display: flex;
  align-items: center;
  padding: 5px 20px 5px 20px;
  border-bottom: 1px solid var(--color-lightgrey);
  box-shadow: 0px 3px 6px -1px rgba(0, 0, 0, 0.1);
`;

const Logo = styled.img`
  width: 150px;
`;

const Spacer = styled.div`
  flex-grow: 1;
`;

const DashboardHeader = ({ toggleDrawer, toggleSettingsDrawer }) => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 960px)'); // Custom breakpoint

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login'); // Redirect to login
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  return (
    <HeaderContainer>
      {isMobile && (
        <IconButton color="inherit" aria-label="menu" onClick={toggleDrawer}>
          <MenuIcon />
        </IconButton>
      )}
      <Logo style={{ marginLeft: '10px' }} src={logo} alt="Logo" />
      <Spacer />
      <ClickLabel onClick={handleLogout} style={{ cursor: 'pointer' }}>Logout</ClickLabel>
      <IconButton style={{ marginLeft: '20px' }} color="inherit" aria-label="settings" onClick={toggleSettingsDrawer}>
        <SettingsOutlined />
      </IconButton>
    </HeaderContainer>
  );
};

export default DashboardHeader;
