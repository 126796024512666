// AuthLayout.js
import React from 'react';
import './AuthLayout.css'; // Importing the specific CSS for AuthLayout
import logo from '../../assets/logo_tabomenu.png'; // Corrected path

const AuthLayout = ({ children }) => {
    return (
        <div>
            <header className="auth-header">
                <img src={logo} alt="TaboMenu Logo" className="auth-logo" />
                {/* Any other header content */}
            </header>
            <main>
                {children} {/* This will render the specific authentication component */}
            </main>
        </div>
    );
};

export default AuthLayout;
