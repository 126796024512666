// v. 2.10

import { groupBy } from 'lodash';

export const aggregateAndOptimizeForUsers = (orderItems, customCourseOrder = null) => {
  // Default course order, which can be overridden
  const courseOrder = customCourseOrder || {
    'Starter': 1,
    'Pasta': 2,
    'Main Course': 3,
    'Side Dish': 3,
    'Dessert': 4,
  };

  const groupedByCourse = groupBy(orderItems, 'course_type');
  const drinks = {};

  // Initialize an empty object for the optimized order
  const optimizedOrder = {};

  // Create an array to keep track of the added course types
  const addedCourseTypes = [];

  // Handle drinks separately
  if (groupedByCourse['Drinks']) {
    groupedByCourse['Drinks'].forEach(item => {
      const itemKey = item.id;
      if (!drinks[itemKey]) {
        drinks[itemKey] = {
          name: item.name,
          quantity: item.quantity,
          price: item.price,
          type: item.type, // menu_item or combo
          note: item.note || '', // Handle optional note
          status: item.status, // Status if relevant
        };
      } else {
        drinks[itemKey].quantity += item.quantity;
      }
    });
    delete groupedByCourse['Drinks']; // Remove drinks from grouped courses
  }

  // Group and aggregate items by course type
  Object.keys(courseOrder).forEach(courseType => {
    const items = groupedByCourse[courseType];
    if (items && !addedCourseTypes.includes(courseType)) {
      const mealCourseNumber = courseOrder[courseType];
      const mealCourse = `Meal Course ${mealCourseNumber}`;
      if (!optimizedOrder[mealCourse]) {
        optimizedOrder[mealCourse] = {};
      }
      items.forEach(item => {
        const itemName = item.name;
        if (!optimizedOrder[mealCourse][itemName]) {
          optimizedOrder[mealCourse][itemName] = {
            name: item.name,
            quantity: item.quantity,
            price: item.price,
            type: item.type, // Distinguish between menu_item and combo
            note: item.note || '', // Optional notes for specific items
            status: item.status, // Status if necessary
          };
        } else {
          optimizedOrder[mealCourse][itemName].quantity += item.quantity;
        }
      });
      addedCourseTypes.push(courseType);
    }
  });

  // Sort the meal courses by their number to maintain correct order
  const sortedMealCourses = Object.keys(optimizedOrder).sort((a, b) => {
    const aNumber = parseInt(a.replace('Meal Course ', ''), 10);
    const bNumber = parseInt(b.replace('Meal Course ', ''), 10);
    return aNumber - bNumber;
  });

  // Renumber the meal courses
  const renumberedOrder = {};
  sortedMealCourses.forEach((mealCourse, index) => {
    renumberedOrder[`Meal Course ${index + 1}`] = optimizedOrder[mealCourse];
  });

  // Add drinks as a separate section at the end
  if (Object.keys(drinks).length > 0) {
    renumberedOrder['Drinks'] = drinks;
  }

  return renumberedOrder;
};


export const aggregateAndOptimizeOrderItems = (items) => {
  const courseOrder = {
    'Starter': 1,
    'Pasta': 2,
    'Main Course': 3,
    'Side Dish': 3,
    'Dessert': 4,
  };

  const groupedByCourse = {};
  const drinks = {};

  // Group and aggregate items
  Object.values(items).forEach(courseItems => {
    Object.values(courseItems).forEach(item => {
      const courseType = item.course_type;
      const itemKey = item.menu_item_id || item.combo_id;

      if (courseType === 'Drinks') {
        if (!drinks[itemKey]) {
          drinks[itemKey] = {
            ...item,
            quantity: item.quantity,
          };
        } else {
          drinks[itemKey].quantity += item.quantity;
        }
      } else {
        if (!groupedByCourse[courseType]) {
          groupedByCourse[courseType] = {};
        }
        if (!groupedByCourse[courseType][itemKey]) {
          groupedByCourse[courseType][itemKey] = {
            ...item,
            quantity: item.quantity,
          };
        } else {
          groupedByCourse[courseType][itemKey].quantity += item.quantity;
        }
      }
    });
  });

  let optimizedOrder = {};
  const addedCourseTypes = [];

  // Aggregate new items according to default course order
  Object.entries(courseOrder).forEach(([courseType, courseNumber]) => {
    if (groupedByCourse[courseType] && !addedCourseTypes.includes(courseType)) {
      const mealCourse = `Meal Course ${courseNumber}`;
      if (!optimizedOrder[mealCourse]) {
        optimizedOrder[mealCourse] = {};
      }

      Object.values(groupedByCourse[courseType]).forEach(item => {
        optimizedOrder[mealCourse][item.name] = item;
      });

      addedCourseTypes.push(courseType);
    }
  });

  // Handle drinks separately
  if (Object.keys(drinks).length > 0) {
    const drinkCourse = "Drinks";
    optimizedOrder[drinkCourse] = {};
    Object.values(drinks).forEach(item => {
      optimizedOrder[drinkCourse][item.name] = item;
    });
  }

  // Sort meal courses by their number and renumber them
  const sortedMealCourses = Object.keys(optimizedOrder)
    .filter(key => key.startsWith('Meal Course'))
    .sort((a, b) => parseInt(a.split(' ')[2]) - parseInt(b.split(' ')[2]));

  const renumberedOrder = {};
  sortedMealCourses.forEach((mealCourse, index) => {
    renumberedOrder[`Meal Course ${index + 1}`] = optimizedOrder[mealCourse];
  });

  // Add drinks back into the final order after meal courses
  if (optimizedOrder['Drinks']) {
    renumberedOrder['Drinks'] = optimizedOrder['Drinks'];
  }

  return renumberedOrder;
};
