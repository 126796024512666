import React, { useState } from 'react';
import { SwipeableDrawer, Button } from '@mui/material';
import styled from 'styled-components';
import PaymentWidget from './PaymentWidget';

// Styled Swipe Handle
const SwipeHandle = styled.div`
  width: 50px;
  height: 6px;
  background-color: #ccc;
  border-radius: 3px;
  margin: 10px auto;
`;

// Container for smooth transition of height
const DrawerContent = styled.div`
  display: flex;
  flex-direction: row;
  padding: 30px 20px 0px 20px;
  gap: 10px;
  transition: all 0.3s ease-in-out;
  height: ${({ $expanded }) => ($expanded ? 'auto' : '25%')};
`;

// Get unique session keys from all items
const getUniqueSessionKeys = (allItems) => {
  const sessionKeys = allItems.map(item => item.session_key);
  return [...new Set(sessionKeys)];
};

// Main PaymentDrawer Component
const PaymentDrawer = ({ open, onClose, billDetails, orderId }) => {
  const sessionKey = localStorage.getItem('session_key') || '';
  const [selectedPaymentType, setSelectedPaymentType] = useState(null);

  const allItems = billDetails?.all_items || [];
  const userItems = billDetails?.session_items?.filter(item => item.session_key === sessionKey) || [];

  // Check for multiple session keys
  const uniqueSessionKeys = getUniqueSessionKeys(allItems);
  const hasMultipleSessionKeys = uniqueSessionKeys.length > 1;

  const handlePaymentClick = (paymentType) => {
    setSelectedPaymentType(paymentType);
  };

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      onOpen={() => {}}
      PaperProps={{ style: { minHeight: '20%', borderRadius: '10px 10px 0 0' } }}
    >
      {/* Swipe Handle */}
      <SwipeHandle />

      <DrawerContent $expanded={selectedPaymentType ? 'true' : 'false'}>
        {userItems.length > 0 && hasMultipleSessionKeys && (
          <Button
            fullWidth
            variant={selectedPaymentType === 'your_items' ? 'contained' : 'outlined'}
            color="primary"
            onClick={() => handlePaymentClick('your_items')}
            sx={{ fontSize: '16px'}}
          >
            Your Bill
          </Button>
        )}
        <Button
          fullWidth
          variant={selectedPaymentType === 'full_bill' ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => handlePaymentClick('full_bill')}
          sx={{ fontSize: '16px'}}
        >
          Full Bill
        </Button>
        <Button
          fullWidth
          variant={selectedPaymentType === 'split_bill' ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => handlePaymentClick('split_bill')}
          sx={{ fontSize: '16px'}}
        >
          Split Bill
        </Button>
      </DrawerContent>

      {/* Conditionally render the PaymentWidget based on the selected payment type */}
      {selectedPaymentType && (
        <PaymentWidget
          paymentType={selectedPaymentType}
          sessionKey={sessionKey}
          billDetails={billDetails}
          orderId={orderId}
        />
      )}

    </SwipeableDrawer>
  );
};

export default PaymentDrawer;
