// v. 2.00
import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  Box,
  Divider,
} from '@mui/material';
import { styled } from '@mui/system';

const TotalPrice = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  fontWeight: 'bold',
  textAlign: 'right',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  width: '100%',
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  marginTop: theme.spacing(2),
}));

const ReviewOrderModal = ({ isOpen, onClose, orderItems, onConfirm }) => {
  if (!isOpen) return null;

  // Calculate total price
  const calculateTotal = () => {
    const total = Object.values(orderItems).reduce((courseTotal, course) => {
      return (
        courseTotal +
        Object.values(course).reduce((itemTotal, item) => {
          return itemTotal + item.quantity * item.price;
        }, 0)
      );
    }, 0);
    return total.toFixed(2); // Ensures total has 2 decimal places
  };

  // Get the keys of the orderItems to determine the number of courses
  const courses = Object.keys(orderItems);
  const numberOfCourses = courses.length;

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Review Your Order</DialogTitle>
      <DialogContent dividers>
        {orderItems && numberOfCourses > 0 ? (
          <>
            {courses
              .filter((course) => course !== 'Drinks')
              .map((course, index) => (
                <Box key={course} mb={2}>
                  <Title variant="h6">
                    {numberOfCourses === 1
                      ? 'Your order:'
                      : index === 0
                      ? "We'll start with:"
                      : index === numberOfCourses - 1
                      ? 'To finish:'
                      : 'Then:'}
                  </Title>
                  {Object.entries(orderItems[course]).map(
                    ([dishKey, dishDetails]) => (
                      <Box key={dishKey} display="flex" justifyContent="space-between">
                        <Typography>{dishDetails.name}</Typography>
                        <Typography>
                          {dishDetails.quantity} × €{dishDetails.price}
                        </Typography>
                        {dishDetails.note && (
                          <Typography variant="body2" color="textSecondary">
                            Note: {dishDetails.note}
                          </Typography>
                        )}
                      </Box>
                    )
                  )}
                </Box>
              ))}
            {orderItems['Drinks'] && (
              <Box mb={2}>
                <Title variant="h6">Drinks:</Title>
                {Object.entries(orderItems['Drinks']).map(
                  ([drinkKey, drinkDetails]) => (
                    <Box key={drinkKey} display="flex" justifyContent="space-between">
                      <Typography>{drinkDetails.name}</Typography>
                      <Typography>
                        {drinkDetails.quantity} × €{drinkDetails.price}
                      </Typography>
                      {drinkDetails.note && (
                        <Typography variant="body2" color="textSecondary">
                          Note: {drinkDetails.note}
                        </Typography>
                      )}
                    </Box>
                  )
                )}
              </Box>
            )}
          </>
        ) : (
          <Typography>No items to display</Typography>
        )}
        <Divider />
        <TotalPrice>Total: €{calculateTotal()}</TotalPrice>
        <StyledButton
          variant="contained"
          color="primary"
          onClick={onConfirm}
        >
          Confirm Order
        </StyledButton>
        <StyledButton
          variant="outlined"
          color="secondary"
          onClick={onClose}
        >
          Close
        </StyledButton>
      </DialogContent>
    </Dialog>
  );
};

export default ReviewOrderModal;
