import React, { createContext, useContext, useState } from 'react';
import api from '../services/apiInstance';

// Use environment variable for API base URL
const API_BASE_URL = process.env.REACT_APP_API_URL;

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [roles, setRoles] = useState([]);

  // Admin Login
  const login = async (credentials) => {
    try {
      const response = await api.post(`${API_BASE_URL}auth/login/`, credentials);
      setIsAuthenticated(true);
      setRoles(response.data.roles);  // Set user roles from response
    } catch (error) {
      console.error('Login failed:', error);
      setIsAuthenticated(false);
    }
  };

  // Admin Logout
  const logout = async () => {
    try {
      await api.post(`${API_BASE_URL}auth/logout/`);
      setIsAuthenticated(false);
      setRoles([]);
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  // Staff Login
  const loginStaff = async (uniqueIdentifier, passcode) => {
    try {
      const response = await api.post(`${API_BASE_URL}auth/staff-login/`, {
        unique_identifier: uniqueIdentifier,
        passcode,
      });
      setIsAuthenticated(true);
      setRoles(response.data.roles);
    } catch (error) {
      console.error('Staff login failed:', error);
      setIsAuthenticated(false);
      throw error; // Pass error to component if handling is needed
    }
  };

  // Staff Logout
  const staffLogout = async () => {
    try {
      await api.post(`${API_BASE_URL}auth/staff-logout/`);
      setIsAuthenticated(false);
      setRoles([]);
    } catch (error) {
      console.error('Staff logout failed:', error);
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, roles, login, logout, loginStaff, staffLogout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);