import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth } from '../../contexts/AuthContext';
import { usePOS } from '../../contexts/POSContext';
import { updateStaffPasscode } from '../../services/authApiService';
import NumberPad from './NumberPad';
import { TextField, Alert, Collapse } from '@mui/material';

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #121212; /* Dark mode background */
`;

const UsernameField = styled(TextField)`
  && {
    margin-bottom: 25px;
    width: 300px;
    
    .MuiInputBase-input {
      color: ${({ disabled }) => (disabled ? '#888' : '#fff')}; // Change text color when disabled
      background-color: black; // Ensure background color is black
      
      // Target autofill background color
      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px black inset !important;
        -webkit-text-fill-color: ${({ disabled }) => (disabled ? '#888' : '#fff')} !important;
      }
    }
    
    .MuiOutlinedInput-root {
      fieldset {
        border-color: ${({ disabled }) => (disabled ? '#888' : '#fff')}; // Change border color when disabled
        border-radius: 10px;
      }
      
      &:hover fieldset {
        border-color: ${({ disabled }) => (disabled ? '#888' : '#1976d2')}; // Change border color on hover if not disabled
      }
      
      &.Mui-focused fieldset {
        border-color: ${({ disabled }) => (disabled ? '#888' : '#1976d2')}; // Change border color when focused if not disabled
      }
    }
    
    .MuiInputLabel-root {
      color: #aaa; // Change label color
      
      &.Mui-focused {
        color: ${({ disabled }) => (disabled ? '#888' : '#1976d2')}; // Change label color when focused if not disabled
      }
    }
  }
`;

const PasscodeDisplay = styled.div`
  display: flex;
  margin-top: 25px;
  margin-bottom: 10px;
  justify-content: center;
`;

const PasscodeDot = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: ${({ filled }) => (filled ? "#fff" : "transparent")};
  border: 2px solid #fff;
  margin-bottom: 10px;
  margin-right: 8px;
  margin-left: 8px;
`;

const AlertContainer = styled.div`
  position: relative;
  width: auto;
`;

const POSLogin = () => {
  const { loginStaff } = useAuth();
  const { updateRestaurantId, alert, showAlert, clearAlert } = usePOS();
  const [uniqueIdentifier, setUniqueIdentifier] = useState('');
  const [passcode, setPasscode] = useState('');
  const [isChangingPasscode, setIsChangingPasscode] = useState(false);
  const navigate = useNavigate();

  // Logic for NumberPad
  const handlePasscodeInput = (number) => {
    const updatedPasscode = passcode + number.toString();
    setPasscode(updatedPasscode);

    // Automatically submit when the 4th digit is entered
    if (updatedPasscode.length === 4) {
      if (!isChangingPasscode) {
        handleLogin(uniqueIdentifier, updatedPasscode);
      } else {
        handleChangePasscode(updatedPasscode);
      }
    }
  };

  // Delete passcode
  const handleDelete = () => {
    setPasscode(passcode.slice(0, -1));
  };
  
  // Function to login staff and update passcode
  const handleLogin = async (identifier, currentPasscode) => {
    if (!identifier) {
      showAlert('Please input your username as "firstname.lastname"', 'warning', 'username');
      setPasscode('');
      return;
    }

    if (!currentPasscode) {
      showAlert('Please input your passcode', 'warning', 'passcode');
      return;
    }

    try {
      const response = await loginStaff(identifier, currentPasscode);
      console.log('Login successful:', response);

      if (response.needs_passcode_change) {
        setIsChangingPasscode(true);
        setPasscode('');
        showAlert('Please enter your new passcode.', 'info', 'passcode');
      } else {
        if (response.restaurant_id) {
          updateRestaurantId(response.restaurant_id);
        }
        navigate('/pos/role-selection'); 
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        showAlert('Invalid credentials', 'error', 'username');
      } else {
        showAlert('Something went wrong, try again later.', 'error', 'username');
      }
      console.error('Login failed:', error);
      setPasscode('');
    }
  };

  // Handle the required change of passcode
  const handleChangePasscode = async (newPasscode) => {
    try {
      const response = await updateStaffPasscode(newPasscode);
      console.log('Passcode updated successfully', response);
      if (response.restaurant_id) {
        updateRestaurantId(response.restaurant_id);
      }
      navigate('/pos/role-selection');
    } catch (error) {
      console.error('Error updating passcode:', error);
    }
  };

  return (
    <LoginContainer>
      <UsernameField
        label="firstname.lastname"
        variant="outlined"
        value={uniqueIdentifier}
        onChange={(e) => setUniqueIdentifier(e.target.value)}
        disabled={isChangingPasscode}
      />
      {alert.field === 'username' && (
        <Collapse in={Boolean(alert.message)}>
          <AlertContainer>
            <Alert severity={alert.type} onClose={clearAlert}>
              {alert.message}
            </Alert>
          </AlertContainer>
        </Collapse>
      )}
      <PasscodeDisplay>
        {[...Array(4)].map((_, i) => (
          <PasscodeDot key={i} filled={i < passcode.length} />
        ))}
      </PasscodeDisplay>
      {alert.field === 'passcode' && (
        <Collapse in={Boolean(alert.message)}>
          <AlertContainer>
            <Alert severity={alert.type} onClose={clearAlert}>
              {alert.message}
            </Alert>
          </AlertContainer>
        </Collapse>
      )}
      <NumberPad onInput={handlePasscodeInput} onDelete={handleDelete} />
    </LoginContainer>
  );
};

export default POSLogin;