import React, { useState, useEffect } from 'react';
import { useRestaurant } from '../../../contexts/RestaurantContext';
import { handleMenuCategory } from '../../../services/apiService';
import { TextField, Button, Typography, Box, Switch, FormControl, FormControlLabel, InputLabel, MenuItem, Select } from '@mui/material';

const courseTypeOptions = [
  { value: 'Drinks', label: 'Drinks' },
  { value: 'Starter', label: 'Starters' },
  { value: 'Pasta', label: 'Pasta & Noodles' },
  { value: 'Main Course', label: 'Main Courses' },
  { value: 'Side Dish', label: 'Side Dishes' },
  { value: 'Dessert', label: 'Desserts' }
];

const dayOptions = [
  { value: 'MO', label: 'Monday' },
  { value: 'TU', label: 'Tuesday' },
  { value: 'WE', label: 'Wednesday' },
  { value: 'TH', label: 'Thursday' },
  { value: 'FR', label: 'Friday' },
  { value: 'SA', label: 'Saturday' },
  { value: 'SU', label: 'Sunday' }
];

const CategoryForm = ({ context, onClose }) => {
  const [newCategoryName, setNewCategoryName] = useState('');
  const [newCategoryDescription, setNewCategoryDescription] = useState('');
  const [selectedCourseType, setSelectedCourseType] = useState('');
  const [showCategoryDaySelector, setShowCategoryDaySelector] = useState(false);
  const [categoryAvailableDays, setCategoryAvailableDays] = useState([]);
  const { showSidebar, editingCategory, addNewCategory, updateCategories, selectedMenu } = useRestaurant();

  const resetForm = () => {
    setNewCategoryName('');
    setNewCategoryDescription('');
    setSelectedCourseType('');
    setCategoryAvailableDays([]);
    setShowCategoryDaySelector(false);
  };

  useEffect(() => {
    if (context === 'editCategory' && editingCategory) {
      setNewCategoryName(editingCategory.name || '');
      setNewCategoryDescription(editingCategory.description || '');
      setSelectedCourseType(editingCategory.course_type || '');
      if (editingCategory.available_days && editingCategory.available_days !== 'EVERYDAY') {
        setShowCategoryDaySelector(true);
        setCategoryAvailableDays(editingCategory.available_days.split(','));
      } else {
        setShowCategoryDaySelector(false);
        setCategoryAvailableDays([]);
      }
    } else if (context === 'createCategory') {
      resetForm();
    }
  }, [context, editingCategory, showSidebar]);

  useEffect(() => {
    if (!showSidebar) {
      resetForm();
    }
  }, [showSidebar]);

  const handleCreateOrUpdateCategory = async (event) => {
    event.preventDefault();
    if (!newCategoryName.trim()) return;

    try {
      const categoryData = {
        name: newCategoryName,
        description: newCategoryDescription,
        menus: [selectedMenu],
        available_days: categoryAvailableDays.length > 0 ? categoryAvailableDays.join(',') : 'EVERYDAY',
        course_type: selectedCourseType,
      };

      if (context === 'editCategory' && editingCategory) {
        const response = await handleMenuCategory(categoryData, editingCategory.id);
        updateCategories(response);
      } else {
        const response = await handleMenuCategory(categoryData);
        addNewCategory(response);
      }

      onClose();
    } catch (error) {
      console.error('Error handling menu category:', error);
    }
  };

  const handleDayChange = (event) => {
    const value = event.target.value;
    setCategoryAvailableDays(typeof value === 'string' ? value.split(',') : value);
  };

  const handleToggleDaySelector = (event) => {
    setShowCategoryDaySelector(event.target.checked);
    if (!event.target.checked) {
      setCategoryAvailableDays([]);
    }
  };

  const handleCourseTypeChange = (event) => {
    setSelectedCourseType(event.target.value);
  };

  return (
    <Box component="form" onSubmit={handleCreateOrUpdateCategory}>
      <Typography variant="h6">{context === 'editCategory' ? 'Edit Category' : 'Add a New Category'}</Typography>
      <TextField
        fullWidth
        variant="outlined"
        label="Name"
        placeholder="e.g. Starters, Main Courses, etc"
        value={newCategoryName}
        onChange={(e) => setNewCategoryName(e.target.value)}
        margin="normal"
      />
      <TextField
        fullWidth
        variant="outlined"
        label="Description"
        placeholder="e.g. All Burgers come with fries"
        value={newCategoryDescription}
        onChange={(e) => setNewCategoryDescription(e.target.value)}
        margin="normal"
      />
      <FormControl fullWidth margin="normal">
        <InputLabel>Course Type</InputLabel>
        <Select
          value={selectedCourseType}
          onChange={handleCourseTypeChange}
          label="Course Type"
        >
          {courseTypeOptions.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControlLabel
        control={
          <Switch
            checked={showCategoryDaySelector}
            onChange={handleToggleDaySelector}
            name="showCategoryDaySelector"
            color="primary"
          />
        }
        label="Select days for availability"
      />
      {showCategoryDaySelector && (
        <FormControl fullWidth margin="normal">
          <InputLabel>Available Days</InputLabel>
          <Select
            multiple
            value={categoryAvailableDays}
            onChange={handleDayChange}
            label="Available Days"
            renderValue={(selected) => selected.map(value => dayOptions.find(option => option.value === value)?.label).join(', ')}
          >
            {dayOptions.map(option => (
              <MenuItem key={option.value} value={option.value} style={{ fontWeight: categoryAvailableDays.includes(option.value) ? 'bold' : 'normal' }}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <Button variant="contained" color="primary" type="submit">
        {context === 'editCategory' ? 'Update Category' : 'Add Category'}
      </Button>
    </Box>
  );
};

export default CategoryForm;
