import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { TextField, Button, Checkbox, Typography, LinearProgress, Alert } from '@mui/material';
import { registerUser } from '../../services/authApiService';
import ReactFlagsSelect from 'react-flags-select';

// Styled components for flexbox containers
const Container = styled.div`
  padding: 4% 10% 2% 10%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) { /* Tablets */
    padding: 4% 20% 2% 20%;
  }

  @media (min-width: 1024px) { /* Desktops */
    padding: 4% 35% 2% 35%;
  }
`;

const Intro = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FlagsSelectWrapper = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
  width: 100%;
`;

const Login = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

// Registration form component
const RegistrationForm = () => {
  // State Management for Form Inputs
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [selectedCountry, setSelectedCountry] = useState('IT');
  
  const navigate = useNavigate(); // Hook to navigate

  // Function to calculate password strength
  const getPasswordStrength = (password) => {
    if (password.length === 0) return 0; // No feedback if nothing is typed.

    const lengthRequirement = password.length >= 8;
    const digitRequirement = /[0-9]/.test(password);
    const uppercaseRequirement = /[A-Z]/.test(password);
    const lowercaseRequirement = /[a-z]/.test(password);
    const specialCharRequirement = /[^A-Za-z0-9]/.test(password);

    let strength = 0;
    if (password.length > 0) strength = 1;
    if (lengthRequirement && digitRequirement && lowercaseRequirement) strength += 2;
    if (uppercaseRequirement && lowercaseRequirement && lengthRequirement) strength += 1;
    if (specialCharRequirement) strength += 1;

    return strength; // Returns a value between 0 and 5
  };

  // Function to get password strength text
  const getPasswordStrengthText = (strength) => {
    switch (strength) {
      case 0:
        return "Password must contain at least 8 characters, a digit and a letter."
      case 1:
      case 2:
        return "Your password is too weak";
      case 3:
        return "Your password looks good, but could be stronger.";
      case 4:
        return "Good! Your password is strong.";
      case 5:
        return "Excellent! Your password is very strong.";
      default:
        return "Password must contain at least 8 characters, a digit and a letter.";
    }
  };

  // Input Handlers
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    const newPassword = event.target.value;
    setPassword(newPassword);
    setPasswordStrength(getPasswordStrength(newPassword));
  };

  const handleTermsChange = (event) => {
    setAcceptedTerms(event.target.checked);
  };

  // Form Validation
  const validateForm = () => {
    const errors = {};
    const emailRegex = /\S+@\S+\.\S+/;
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d).{8,}$/;

    if (!email || !emailRegex.test(email)) {
      errors.email = 'A valid email is required';
    }

    if (!password || !passwordRegex.test(password)) {
      errors.password = 'Password must contain at least 8 characters, a digit and a letter.';
    }

    if (!acceptedTerms) {
      errors.terms = 'Please accept our Terms and Conditions and Privacy Policy to continue.';
    }

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };

  // Form Submission handling
   const handleSubmit = (event) => {
    event.preventDefault();
    const isValid = validateForm();

    if (isValid) {
      // Submit the form data
      const userData = { email, password, country: selectedCountry };
      registerUser(userData)
        .then(response => {
          // On successful registration, redirect to the SuccessPage
          navigate('/success');
        })
        .catch(error => {
          // Handle any errors here
          if (error.response && error.response.data) {
            setFormErrors(error.response.data);
          }
        });
    }
  };

  // Determine color based on password strength
  const getPasswordStrengthColor = (strength) => {
    if (strength < 1) return 'primary';
    if (strength <= 2) return 'error';
    if (strength <= 3) return 'secondary';
    if (strength >= 4) return 'success';
    return 'success';
  };

  return (
    <Container>
      <Intro>
        <Typography style={{paddingBottom: '5px'}} variant="h3">Let's create your account</Typography>
        <Typography style={{paddingBottom: '5px'}} variant="body1"> Signing up is easy and free. No long-term contract required</Typography>
      </Intro>
      <Form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          type="email"
          label="Email"
          value={email}
          onChange={handleEmailChange}
          margin="normal"
          variant="outlined"
          error={!!formErrors.email}
          helperText={formErrors.email}
          autoComplete="email"
        />
        <TextField
          fullWidth
          type="password"
          label="Password"
          value={password}
          onChange={handlePasswordChange}
          margin="normal"
          variant="outlined"
          error={!!formErrors.password}
          helperText={formErrors.password}
          autoComplete="new-password"
        />
        <LinearProgress
          value={(passwordStrength / 5) * 100}
          variant="determinate"
          style={{ width: '100%', marginTop: '10px' }}
          color={getPasswordStrengthColor(passwordStrength)}
        />
        <Typography variant="body2" style={{ margin: '10px 0 10px 0' }}>{getPasswordStrengthText(passwordStrength)}</Typography>
        <FlagsSelectWrapper>
          <ReactFlagsSelect
            countries={["IT", "DE", "FR"]}
            customLabels={{"IT": "Italia", "DE": "Deutschland", "FR": "France"}}
            selected={selectedCountry}
            onSelect={code => setSelectedCountry(code)}
          />
        </FlagsSelectWrapper>  
        <CheckboxContainer>
          <Checkbox checked={acceptedTerms} onChange={handleTermsChange} />
          <Typography variant="body2">
            By ticking this box you accept the <a href="/terms">Terms and Conditions</a> and acknowledge that you have read and understood the <a href="/privacy">Privacy Policy</a>.
          </Typography>
        </CheckboxContainer>
        {formErrors.terms && <Alert severity="error" style={{ width: '100%', marginTop: '10px' }}>{formErrors.terms}</Alert>}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          style={{ marginTop: '20px' }}
        >
          Signup
        </Button>
        <Login>
          <Typography variant="body1" style={{ paddingTop: '15px' }}> Already registered? <a href="/login">Login</a></Typography>
        </Login>
      </Form>
    </Container>
  );
};

export default RegistrationForm;
