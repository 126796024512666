// TeamManagement.jsx
import React, { useState, useEffect } from 'react';
import { 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Button, Drawer, Typography, TextField, MenuItem, Select,
  InputLabel, FormControl, Alert, Snackbar, IconButton, Dialog, DialogActions, DialogContent, DialogTitle
} from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import { addStaffMember, fetchUserRestaurants, fetchRoles, fetchStaffMembers, deleteStaffMember, updateStaffMember } from '../../services/apiService';

const TeamManagement = () => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [newRole, setNewRole] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [roles, setRoles] = useState([]);
  const [selectedRoleForForm, setSelectedRoleForForm] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  // Local state variables
  const [localUserRestaurants, setLocalUserRestaurants] = useState([]);
  const [selectedRestaurantForForm, setSelectedRestaurantForForm] = useState('');

  useEffect(() => {
    const loadData = async () => {
      const [restaurants, rolesData, staffMembers] = await Promise.all([
        fetchUserRestaurants(),
        fetchRoles(),
        fetchStaffMembers(),
      ]);

      // Store transformed data in local state
      setLocalUserRestaurants(
        restaurants.map((restaurant) => ({
          value: restaurant.id,
          label: restaurant.name,
        }))
      );

      setRoles(
        rolesData.map((role) => ({
          value: role.id,
          label: role.name,
        }))
      );

      setTeamMembers(staffMembers);
    };

    loadData();
  }, []);

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
    // Reset form fields
    setFirstName('');
    setLastName('');
    setSelectedRestaurantForForm('');
    setSelectedRoleForForm('');
  };

  const handleRoleClick = (member) => {
    setSelectedMember(member);
    // Find the role ID based on the role name
    const role = roles.find((r) => r.label === member.role);
    setNewRole(role ? role.value : ''); // Set the current role ID to newRole state
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setSelectedMember(null);
    setNewRole('');
  };

  const handleRoleUpdate = async () => {
    try {
      await updateStaffMember(selectedMember.id, { role_id: newRole } );
      setAlertMessage('Role updated successfully');
      setAlertSeverity('success');
      setSnackbarOpen(true);

      // Refresh the team members list
      const updatedStaffMembers = await fetchStaffMembers();
      setTeamMembers(updatedStaffMembers);
      handleDialogClose();
    } catch (error) {
      setAlertMessage('Failed to update role');
      setAlertSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const staffData = {
      first_name: firstName,
      last_name: lastName,
      restaurant_id: selectedRestaurantForForm,
      role: selectedRoleForForm,
    };

    try {
      await addStaffMember(staffData);
      setAlertMessage('Staff member added successfully');
      setAlertSeverity('success');
      setSnackbarOpen(true);
      // Reset fields
      setFirstName('');
      setLastName('');
      setSelectedRestaurantForForm('');
      setSelectedRoleForForm('');
      setIsDrawerOpen(false);

      // Refresh the team members list
      const updatedStaffMembers = await fetchStaffMembers();
      setTeamMembers(updatedStaffMembers);
    } catch (error) {
      setAlertMessage('Failed to add staff member');
      setAlertSeverity('error');
      setSnackbarOpen(true);
      console.error('Failed to add staff member:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteStaffMember(id);
      setAlertMessage('Staff member deleted successfully');
      setAlertSeverity('success');
      setSnackbarOpen(true);

      // Refresh the team members list
      const updatedStaffMembers = await fetchStaffMembers();
      setTeamMembers(updatedStaffMembers);
    } catch (error) {
      setAlertMessage('Failed to delete staff member');
      setAlertSeverity('error');
      setSnackbarOpen(true);
      console.error('Failed to delete staff member:', error);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div style={{ padding: '20px' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '20px',
        }}
      >
        <Typography variant="h4">Team Members</Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Add />}
          onClick={handleDrawerOpen}
        >
          Add Staff
        </Button>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: '600' }}>First Name</TableCell>
              <TableCell style={{ fontWeight: '600' }}>Last Name</TableCell>
              <TableCell style={{ fontWeight: '600' }}>Role</TableCell>
              <TableCell style={{ fontWeight: '600' }}>Restaurant</TableCell>
              <TableCell style={{ fontWeight: '600' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teamMembers.map((member) => (
              <TableRow key={member.id}>
                <TableCell>{member.first_name}</TableCell>
                <TableCell>{member.last_name}</TableCell>
                <TableCell>
                  <Typography
                    variant="body2"
                    color="primary"
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                    onClick={() => handleRoleClick(member)}
                  >
                    {member.role}
                  </Typography>
                </TableCell>
                <TableCell>{member.restaurant}</TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => handleDelete(member.id)}
                    color="secondary"
                  >
                    <Delete fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Drawer anchor="right" open={isDrawerOpen} onClose={handleDrawerClose}>
        <div style={{ width: '400px', padding: '20px' }}>
          <Typography variant="h4" gutterBottom>
            Add Staff Member
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              fullWidth
              required
              margin="normal"
            />
            <TextField
              label="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              fullWidth
              required
              margin="normal"
            />
            <FormControl fullWidth margin="normal" required>
              <InputLabel>Select Restaurant</InputLabel>
              <Select
                value={selectedRestaurantForForm}
                onChange={(e) => setSelectedRestaurantForForm(e.target.value)}
                label="Select Restaurant"
              >
                {localUserRestaurants.map((restaurant) => (
                  <MenuItem key={restaurant.value} value={restaurant.value}>
                    {restaurant.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal" required>
              <InputLabel>Select Role</InputLabel>
              <Select
                value={selectedRoleForForm}
                onChange={(e) => setSelectedRoleForForm(e.target.value)}
                label="Select Role"
              >
                {roles
                  .filter((role) => role.label !== 'Admin')
                  .map((role) => (
                    <MenuItem key={role.value} value={role.value}>
                      {role.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Add Staff
            </Button>
          </form>
        </div>
      </Drawer>

      <Dialog open={isDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Update Role</DialogTitle>
        <DialogContent>
          <FormControl sx={{ width: '200px' }}>
            <Select
              value={newRole}
              onChange={(e) => setNewRole(e.target.value)}
            >
              {roles
                .filter((role) => role.label !== 'Admin')
                .map((role) => (
                  <MenuItem key={role.value} value={role.value}>
                    {role.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleRoleUpdate} color="primary">
              Update
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={alertSeverity}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default TeamManagement;
