import React from 'react';
import { Button, Typography, Box, Container } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import logo from '../../assets/logo_tabo-inverted.png';

const PaymentCancelUrl = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tableId = searchParams.get('tableId');

  const handleRetryPayment = () => {
    navigate(-1); // Navigate back to the payment page
  };

  const handleReturnToMenu = () => {
    const menuUrl = `${process.env.REACT_APP_API_URL}users/qr-redirect/${tableId}`;  // Generate the menu URL
    window.location.href = menuUrl;  // Redirect to the menu page for the specific table
  };

  return (
    <Container maxWidth="sm" sx={{ textAlign: 'center', paddingTop: '50px' }}>
      <Box mb={3}>
        <img src={logo} alt="TaboMenu Logo" style={{ height: '50px' }} />
      </Box>
      <Typography variant="h4" gutterBottom>
        Payment Canceled
      </Typography>
      <Typography variant="body1" color="textSecondary">
        It looks like the payment process was canceled. You can try again.
      </Typography>
      <Box mt={5}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleRetryPayment}
          sx={{ fontSize: '16px', padding: '10px 20px' }}
        >
          Retry Payment
        </Button>
      </Box>
      <Box mt={2}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleReturnToMenu}
          sx={{ fontSize: '16px', padding: '10px 20px' }}
        >
          Return to Menu
        </Button>
      </Box>
    </Container>
  );
};

export default PaymentCancelUrl;
