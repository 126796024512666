import React from 'react';
import styled from 'styled-components';
import { Typography } from '@mui/material';

// Styled components
const Container = styled.div`
  padding: 15% 10% 2% 10%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) { /* Tablets */
    padding: 15% 20% 2% 20%;
  }

  @media (min-width: 1024px) { /* Desktops */
    padding: 15% 35% 2% 35%;
  }
`;

const PasswordEmailSent = () => (
  <Container>
    <Typography variant="h2" gutterBottom>Email sent</Typography>
    <Typography variant="body1">
      We have sent a link to reset your password.<br/>Please follow the instructions provided in the email.
    </Typography>
  </Container>
);

export default PasswordEmailSent;
