import React from 'react';
import { usePOS } from '../../../contexts/POSContext';
import styled from 'styled-components';
import { CircularProgress, FormControl, MenuItem, Select } from '@mui/material';

const CentralContainer = styled.div`
  width: 20%;
  background-color: #121212;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  max-height: calc(100vh - 130px); /* Adjust height to ensure it fits within the viewport */
  @media (max-width: 600px) {
    width: 100%;
    flex: 1;
  }
`;

const MenuSelectContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  background-color: white;
`;

const getBackgroundColor = (courseType) => {
  switch (courseType) {
    case 'Drinks':
      return '#FF00FFe3';
    case 'Starter':
      return '#3e3effe3';
    case 'Pasta':
      return '#3eb6ffe3';
    case 'Main Course':
      return '#4aff3ee3';
    case 'Side Dish':
      return '#ebff3ee3';
    case 'Dessert':
      return '#ff6a3ee3';
    default:
      return '#3e3effe3';
  }
};

const CategoryContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ $course_type }) => getBackgroundColor($course_type)};
  padding: 0px;
  margin: 0px;
  border-top: 0.5px solid #1f1f1f;
  border-bottom: 0.5px solid #1f1f1f;
  box-sizing: border-box;
  cursor: pointer;
`;

const CategoryName = styled.h5`
  color: white;
`;

const CategoryList = () => {
  const { loading, menus, selectedMenu, setSelectedMenu, categories, setSelectedCategory } = usePOS();

  const handleMenuChange = (event) => {
    setSelectedMenu(event.target.value);
  };

  const handleCategoryClick = (categoryId) => {
    setSelectedCategory(categoryId);
  };

  const renderCategories = () => (
    <>
      {categories.map((category) => (
        <CategoryContainer key={category.id} $course_type={category.course_type} onClick={() => handleCategoryClick(category.id)}>
          <CategoryName>{category.name}</CategoryName>
        </CategoryContainer>
      ))}
    </>
  );

  return (
    <CentralContainer>
      <MenuSelectContainer>
        {loading ? (
          <CircularProgress />
        ) : (
          <FormControl fullWidth>
            <Select
              value={selectedMenu || ''}
              onChange={handleMenuChange}
              displayEmpty
              style={{ borderRadius: '0' }}
            >
              <MenuItem value="" disabled>
                Select Menu
              </MenuItem>
              {menus.map((menu) => (
                <MenuItem key={menu.id} value={menu.id}>
                  {menu.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </MenuSelectContainer>
      {loading ? <CircularProgress /> : renderCategories()}
    </CentralContainer>
  );
};

export default CategoryList;
