// ReservationPage v1.45
import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  Tabs,
  Tab,
  AppBar,
  Toolbar,
} from '@mui/material';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { useParams } from 'react-router-dom';
import { fetchUserRestaurantDetails } from '../../services/usersApiService';
import ReservationWidget from './ReservationWidget';
import DefaultMenu from './DefaultMenu';
import logo from '../../assets/logo_tabomenu.png'; // Import the logo
import styled from 'styled-components'; // Use styled-components

// Styled components
const ReservationPageContainer = styled(Container)`
  padding: 20px;
  margin-top: 80px; /* To create space for the header */
`;

const LeftColumn = styled(Box)`
  flex: 1;
  margin-right: 20px;
`;

const RightColumn = styled(Box)`
  flex: 1;
  position: sticky;
  top: 100px; /* Adjust as needed */
  align-self: flex-start; /* Ensure proper alignment */
  max-width: 500px;
`;

const Header = styled(AppBar)`
  && {
    background-color: #fff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  }
`;

const Logo = styled.img`
  width: 150px;
`;

const ReservationPage = () => {
  const { restaurantId } = useParams();
  const [restaurant, setRestaurant] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    const loadRestaurant = async () => {
      try {
        const data = await fetchUserRestaurantDetails(restaurantId);
        setRestaurant(data);
      } catch (error) {
        console.error('Error fetching restaurant details:', error);
      }
    };

    loadRestaurant();
  }, [restaurantId]);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <>
      {/* Header with logo */}
      <Header position="fixed">
        <Toolbar>
          <Logo src={logo} alt="TaboMenu Logo" />
        </Toolbar>
      </Header>

      {/* Main Content */}
      <ReservationPageContainer maxWidth="xl">
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
          {/* Left Column */}
          <LeftColumn>
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Restaurant Info" />
              <Tab label="Menu" />
            </Tabs>
            {tabIndex === 0 && (
              <Box p={2} >
                <Typography variant="h4" gutterBottom>
                  {restaurant ? restaurant.name : 'Restaurant'}
                </Typography>
                <Typography variant="body1" paragraph>
                  {restaurant ? restaurant.description : 'Loading...'}
                </Typography>
                {restaurant && restaurant.legal_address && (
                  <>
                    <Typography
                      variant="body1"
                      paragraph
                      sx={{ display: 'flex', alignItems: 'center' }}
                    >
                      <LocationOnOutlinedIcon sx={{ marginRight: '8px' }} />
                      {restaurant.legal_address}
                    </Typography>
                    {/* Google Maps iframe */}
                    <iframe
                      width="100%"
                      height="375"
                      title="Restaurant map"
                      frameBorder="0"
                      style={{ border: 0, borderRadius: '8px' }}
                      src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                        &q=${encodeURIComponent(restaurant.legal_address)}
                        &zoom=15
                      `}
                      allowFullScreen
                    ></iframe>
                  </>
                )}
              </Box>
            )}
            {tabIndex === 1 && (
              <Box p={2}>
                <Typography variant="h6">Our Menu</Typography>
                {/* Use DefaultMenu component */}
                <DefaultMenu restaurantId={restaurantId} />
              </Box>
            )}
          </LeftColumn>

          {/* Right Column with Sticky ReservationWidget */}
          <RightColumn>
            {restaurant && <ReservationWidget restaurantId={restaurant} />}
          </RightColumn>
        </Box>
      </ReservationPageContainer>
    </>
  );
};

export default ReservationPage;