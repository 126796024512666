import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextField, Snackbar, Alert, CircularProgress } from '@mui/material';
import { createMenuWithAI } from '../../../services/apiService';
import { useRestaurant } from '../../../contexts/RestaurantContext';

const AIMenuCreationDialog = ({ open, onClose, onMenuCreated }) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const { prompt, setPrompt } = useRestaurant();


  // Handle the close of Snackbar
  const handleCloseSnackbar = () => {
    setErrorMessage(null);
    setSuccessMessage(null);
  };

  const handleCreateMenu = async () => {
    setLoading(true);
    try {
      const response = await createMenuWithAI(prompt);
      console.log('API Response:', response);
      if (response?.menu?.id) {
        onMenuCreated(response.menu);  // Pass the menu object to the parent component
        setSuccessMessage('Menu created successfully!');
        onClose();  // Close the dialog after success
      } else {
        setErrorMessage('Failed to create menu. Please try again.');
      }
    } catch (error) {
      setErrorMessage('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Generate with AI</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Generate menu examples with AI by writing a description below
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            placeholder="e.g. Give me ideas on gorumet Pizzas"
            fullWidth
            variant="outlined"
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            disabled={loading}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary" disabled={loading}>
            Cancel
          </Button>
          <Button onClick={handleCreateMenu} color="primary" disabled={loading || !prompt}>
            {loading ? <CircularProgress size={24} /> : 'Generate'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Success Snackbar */}
      <Snackbar open={!!successMessage} autoHideDuration={4000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>

      {/* Error Snackbar */}
      <Snackbar open={!!errorMessage} autoHideDuration={4000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AIMenuCreationDialog;
