import React, { useEffect, useState, useMemo } from 'react';
import { usePOS } from '../../contexts/POSContext';
import { fetchAggregatedOrdersByTable, updateOrderItemStatus } from '../../services/posApiService';
import POSSidebar from './POSSidebar';
import styled from 'styled-components';
import {
  Card, CardContent, Typography, CircularProgress,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Tabs, Tab, Box
} from '@mui/material';

const MainContainer = styled.div`
  padding: 10px;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
  height: 100%;
`;

const ColumnsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  overflow: hidden;
  flex: 1;
`;

const Column = styled.div`
  flex: 1;
  padding: 0px;
  margin: 0px;
  background-color: #1f1f1f;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const ColumnHeader = styled.h4`
  font-weight: 600;
  color: var(--color-primary);
  margin: 0px;
  padding: 8px 0 8px 0;
  text-align: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: var(--color-light);
`;

const ColumnContent = styled.div`
  flex: 1;
  padding: 20px;
  margin: 0px;
  background-color: var(--color-lightgrey);
  overflow-y: auto;
  max-height: calc(100vh - 80px);
`;

const OrderContainer = styled.div`
  background-color: #2c2c2c;
  padding: 10px 20px;
  border-radius: 10px;
  margin-bottom: 10px;
`;

const TableHeading = styled.h4`
  font-weight: 600;
  color: #CBD5E1;
  margin-bottom: 10px;
  margin-top: 10px;
`;

const MealHeading = styled.h5`
  font-weight: 600;
  color: var(--color-secondary);
  margin-bottom: 10px;
  margin-top: 10px;
`;

const OrderCard = styled(Card)`
  && {
    position: relative;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    cursor: pointer;
    text-decoration: ${props => props.consumed ? 'line-through' : 'none'};
    color: ${props => props.consumed ? '#000' : 'inherit'};
  }
`;

const OrderCardContent = styled(CardContent)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const OrderInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const Dot = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 16px;
  height: 16px;
  border: 1px solid grey;
  border-radius: 50%;
  background-color: ${props => props.color};
`;

const SummaryContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 20px;
`;

const SummaryItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const POSOrders = () => {
  const { handleTableSelect, selectedTable, showAlert } = usePOS();
  const [ordersByTable, setOrdersByTable] = useState({});
  const [loading, setLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [tabValue, setTabValue] = useState(0); // New state for managing the tab selection

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const ordersTable = await fetchAggregatedOrdersByTable();
        setOrdersByTable(ordersTable);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching orders:', error);
        setLoading(false);
      }
    };
    fetchOrders();
  }, []);

  const updateOrderItemStatusHandler = async (itemId, action) => {
    try {
      const response = await updateOrderItemStatus(itemId, action);
      showAlert(response.status, 'success');
      const updatedOrders = await fetchAggregatedOrdersByTable();
      setOrdersByTable(updatedOrders);
    } catch (error) {
      showAlert('Error updating item status', 'error');
    }
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedItem(null);
  };

  const handleDialogConfirm = () => {
    const nextStatus = getNextStatus(selectedItem.status);
    updateOrderItemStatusHandler(selectedItem.id, nextStatus);
    handleDialogClose();
  };

  const getNextStatus = (status) => {
    const statusOrder = ['received', 'in_preparation', 'ready', 'consumed'];
    const currentIndex = statusOrder.indexOf(status);
    return statusOrder[currentIndex + 1] || statusOrder[currentIndex];
  };

  const summary = useMemo(() => {
    let totalOrders = 0;
    let activeItems = 0;
    let readyToPrepare = 0;
    let onHold = 0;

    Object.values(ordersByTable).forEach(({ order_details }) => {
      totalOrders++; // Count unique order instances
      let previousCourseConsumed = true; // Initialize 'Starters'
      Object.entries(order_details).forEach(([mealCourse, items]) => {
        // Determine if current course is ready to prepare or on hold
        Object.entries(items).forEach(([dish, item]) => {
          if (item.status === 'received' || item.status === 'in_preparation') {
            activeItems++; // Count all items received or in_preparation

            // Green dot: ready to prepare if previous course is consumed or if it's the first course
            if (item.status === 'received' && previousCourseConsumed) {
              readyToPrepare++;
            }
            // Yellow dot: on hold if previous course is not fully consumed
            else if (item.status === 'received' && !previousCourseConsumed) {
              onHold++;
            }
          }
        });
        // After processing this course, determine if all items in this course are consumed
        previousCourseConsumed = Object.values(items).every(item => item.status === 'consumed');
      });
    });

    return { totalOrders, activeItems, readyToPrepare, onHold };
  }, [ordersByTable]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const renderOrdersByTable = () => {
    const groupedOrders = ordersByTable;

    const isCourseConsumed = (course) => {
      return Object.values(course).every(item => item.status === 'consumed');
    };

    const renderOrdersByStatus = (statusList, columnTitle) => {
      const renderedMealCourses = {};

      return (
        <Column key={columnTitle}>
          <ColumnHeader>{columnTitle}</ColumnHeader>
          <ColumnContent>
            {Object.values(groupedOrders).map(({ table_number, order_details }) => {
              const tableContent = [];
              let TableHeadingRendered = false;
              let previousCourseConsumed = true;

              Object.entries(order_details).forEach(([mealCourse, items], courseIndex) => {
                const courseConsumed = isCourseConsumed(items);
                Object.entries(items)
                  .filter(([dish, item]) => statusList.includes(item.status))
                  .forEach(([dish, item], index) => {
                    if (!TableHeadingRendered) {
                      tableContent.push(<TableHeading key={`table-${table_number}`}>Table #{table_number}</TableHeading>);
                      TableHeadingRendered = true;
                    }

                    if (!renderedMealCourses[table_number]) {
                      renderedMealCourses[table_number] = new Set();
                    }

                    if (!renderedMealCourses[table_number].has(mealCourse)) {
                      tableContent.push(<MealHeading key={`meal-${table_number}-${mealCourse}`}>{mealCourse}</MealHeading>);
                      renderedMealCourses[table_number].add(mealCourse);
                    }

                    const visualCueColor = previousCourseConsumed ? '#4aff3ee3' : '#ebff3ee3';

                    tableContent.push(
                      <OrderCard key={`${item.id}-${statusList}-${index}`} onClick={() => handleItemClick(item)} consumed={item.status === 'consumed'}>
                        {item.status === 'received' && <Dot color={visualCueColor} />}
                        <OrderCardContent>
                          <OrderInfo>
                            <Typography variant="h5">{dish}</Typography>
                            <Typography variant="body2">Quantity: {item.quantity}</Typography>
                          </OrderInfo>
                        </OrderCardContent>
                      </OrderCard>
                    );
                  });

                previousCourseConsumed = courseConsumed;
              });

              return tableContent.length > 0 ? (
                <OrderContainer key={`table-container-${table_number}`}>
                  {tableContent}
                </OrderContainer>
              ) : null;
            })}
          </ColumnContent>
        </Column>
      );
    };

    return (
      <ColumnsContainer key="table-view">
        {renderOrdersByStatus(['received'], 'RECEIVED')}
        {renderOrdersByStatus(['in_preparation'], 'IN PREPARATION')}
        {renderOrdersByStatus(['ready', 'consumed'], 'READY')}
      </ColumnsContainer>
    );
  };

  const renderOrdersByItems = () => {
    const groupedItems = [];

    // Group and sort items
    Object.values(ordersByTable).forEach(({ table_number, order_details }) => {
      let previousCourseConsumed = true; // Initialize 'Starters'
      Object.entries(order_details).forEach(([mealCourse, items]) => {
        Object.entries(items).forEach(([dish, item]) => {
          // Calculate if the item is ready to prepare (green dot) or on hold (yellow dot)
          const readyToPrepare = item.status === 'received' && previousCourseConsumed;
          groupedItems.push({
            table_number,
            mealCourse,
            dish,
            item,
            readyToPrepare,
          });
        });

        // After processing this course, determine if all items in this course are consumed
        previousCourseConsumed = Object.values(items).every(item => item.status === 'consumed');
      });
    });

    // Sort items: first ready to prepare, then others
    groupedItems.sort((a, b) => {
      if (a.readyToPrepare && !b.readyToPrepare) return -1;
      if (!a.readyToPrepare && b.readyToPrepare) return 1;
      return 0;
    });

    const renderOrdersByStatus = (statusList, columnTitle) => {
      const renderedItems = [];

      groupedItems.forEach(({ table_number, mealCourse, dish, item, readyToPrepare }) => {
        if (statusList.includes(item.status)) {
          // Use the readyToPrepare flag to determine the dot color
          const visualCueColor = readyToPrepare ? '#4aff3ee3' : '#ebff3ee3';
          renderedItems.push(
            <OrderCard
              key={`${item.id}-${statusList}`}
              onClick={() => handleItemClick(item)}
              consumed={item.status === 'consumed'}
            >
              {item.status === 'received' && <Dot color={visualCueColor} />}
              <OrderCardContent>
                <OrderInfo>
                  <Typography variant="h5">{dish}</Typography>
                  <Typography color="secondary" variant="h6">Table: {table_number}</Typography>
                  <Typography variant="body2">Quantity: {item.quantity}</Typography>
                </OrderInfo>
              </OrderCardContent>
            </OrderCard>
          );
        }
      });

      return (
        <Column key={columnTitle}>
          <ColumnHeader>{columnTitle}</ColumnHeader>
          <ColumnContent>{renderedItems}</ColumnContent>
        </Column>
      );
    };

    return (
      <ColumnsContainer key="item-view">
        {renderOrdersByStatus(['received'], 'RECEIVED')}
        {renderOrdersByStatus(['in_preparation'], 'IN PREPARATION')}
        {renderOrdersByStatus(['ready', 'consumed'], 'READY')}
      </ColumnsContainer>
    );
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <POSSidebar isOpen={Boolean(selectedTable)} tableId={selectedTable} onClose={() => handleTableSelect(null)} />
      <MainContainer>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <SummaryContainer>
            <SummaryItem>
              <Typography style={{ marginLeft: '20px', marginRight: '50px' }} color="white" variant="h7">Active Tables</Typography> 
              <Typography style={{ marginLeft: '20px', marginRight: '50px' }} color="secondary" variant="h6">{summary.totalOrders}</Typography>
            </SummaryItem>
            <SummaryItem>
              <Typography color="white" variant="h7">Total Dishes</Typography>
              <Typography color="secondary" variant="h6">{summary.activeItems}</Typography>
            </SummaryItem>
            <SummaryItem>
              <Typography color="white" variant="h7">Ready to Prepare</Typography>
              <Typography color="secondary" variant="h6">{summary.readyToPrepare}</Typography>
            </SummaryItem>
            <SummaryItem>
              <Typography color="white" variant="h7">On Hold</Typography>
              <Typography color="secondary" variant="h6">{summary.onHold}</Typography>
            </SummaryItem>
          </SummaryContainer>
          <Tabs indicatorColor="secondary" value={tabValue} onChange={handleTabChange}>
            <Tab style={{ color: 'var(--color-light' }} label="Sort by Tables" />
            <Tab style={{ color: 'var(--color-light' }} label="Sort by Items" />
          </Tabs>
        </Box>
        {tabValue === 0 && renderOrdersByTable()}
        {tabValue === 1 && renderOrdersByItems()}
      </MainContainer>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Change Order Item Status</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Flag '{selectedItem?.dish}' as '{getNextStatus(selectedItem?.status)}'?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">Cancel</Button>
          <Button onClick={handleDialogConfirm} color="primary">Yes</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default POSOrders;
