import React, { useEffect, useState } from 'react';
import { usePOS } from '../../contexts/POSContext';
import { fetchAggregatedOrder } from '../../services/posApiService';
import styled from 'styled-components';
import { Drawer, Typography, CircularProgress, IconButton, Button, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import POSPayment from './POSPayment';

const SidebarContent = styled.div`
  padding: 20px;
  height: 100vh;
  background-color: #121212; /* Dark mode background */
`;

const Title = styled.h5`
  display: flex;
  color: white;
  justify-content: center;
  padding-bottom: 20px;
  padding-top: 10px;
  margin: 0px;
`;

const TableNumber = styled.p`
  display: flex;
  color: var(--color-secondary);
  justify-content: flex-start;
  padding-bottom: 20px;
  padding-top: 10px;
  margin: 0px;
`;

const OrderItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
  color: white;
`;

const IconItemContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TotalContainer = styled.div`
  display: flex;
  margin-top: 16px;
  font-weight: bold;
  justify-content: flex-end;
  padding-right: 20px;
  color: #ff6a3ee3;
`;

const POSSidebar = ({ isOpen, onClose }) => {
  const { selectedTable } = usePOS();
  const [orderData, setOrderData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isPaymentModalOpen, setPaymentModalOpen] = useState(false);

  const isMobile = useMediaQuery('(max-width:600px)');
  const isLargeScreen = useMediaQuery('(min-width:1200px)');

  useEffect(() => {
    const fetchOrderData = async () => {
      if (!selectedTable) return;
      setLoading(true);
      try {
        const data = await fetchAggregatedOrder(selectedTable);
        setOrderData(data);
      } catch (error) {
        console.error('Error fetching order data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrderData();
  }, [selectedTable]);

  const handleOpenPaymentModal = () => {
    setPaymentModalOpen(true);
  };

  const handleClosePaymentModal = () => {
    setPaymentModalOpen(false);
  };

  const calculateTotal = () => {
    let total = 0;
    if (orderData && orderData.items) {
      Object.values(orderData.items).forEach(course => {
        Object.values(course).forEach(item => {
          total += item.quantity * item.price;
        });
      });
    }
    return total.toFixed(2);
  };

  const renderOrderItems = () => (
    <>
      {orderData && orderData.items && Object.entries(orderData.items).map(([courseName, items]) => (
        <div key={courseName}>
          <Typography color="secondary">{courseName}</Typography>
          {Object.entries(items).map(([itemName, data]) => (
            <OrderItemContainer key={itemName}>
              <IconItemContainer>
                <Typography color="white">{itemName} x{data.quantity}</Typography>
              </IconItemContainer>
              <Typography color="white">€{(data.price * data.quantity).toFixed(2)}</Typography>
            </OrderItemContainer>
          ))}
        </div>
      ))}
    </>
  );

  return (
    <>
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={onClose}
        PaperProps={{
          style: {
            width: isLargeScreen ? '35%' : isMobile ? '90%' : '300px',
          },
        }}
      >
        <SidebarContent>
          <IconButton onClick={onClose} style={{ color: 'lightgrey', float: 'right' }}>
            <CloseIcon />
          </IconButton>
          <Title variant="h6">Order Details</Title>
          {loading ? (
            <CircularProgress />
          ) : orderData && Object.keys(orderData.items).length > 0 ? (
            <div>
              <TableNumber variant="h6">Table #{orderData.table_number}</TableNumber>
              {renderOrderItems()}
              <TotalContainer>
                Total: €{calculateTotal()}
              </TotalContainer>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenPaymentModal}
                style={{ width: '100%', marginTop: '20px' }}
              >
                Initiate Payment
              </Button>
            </div>
          ) : (
            <OrderItemContainer>
              <Typography color="white">There are no active orders for this table</Typography>
            </OrderItemContainer>
          )}
        </SidebarContent>
      </Drawer>
      <POSPayment
        isOpen={isPaymentModalOpen}
        onClose={handleClosePaymentModal}
        orderData={orderData}
      />
    </>
  );
};

export default POSSidebar;
