import React, { useState } from 'react';
import { useRestaurant } from '../../../contexts/RestaurantContext';
import { FormControl, InputLabel, MenuItem, Button } from '@mui/material';
import styled from 'styled-components';
import { CustomSelect, ClickLabel } from '../../Common/MUI.StyledComponents';
import AIMenuCreationDialog from './AIMenuCreationDialog';

const HeaderContainerStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100px;
`;

const ToggleButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 30%;
  padding-left: 20px;
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 20px;
  padding-bottom: 20px;
`;

const MenuHeader = ({
  handlePreview,
  selectOptions,
  openMenuCreationSidebar,
  openEditMenuSidebar,
  openDeleteConfirmation,
  toggleMenuAreaAssignment,
  closeMenuAreaAssignment,
  showMenuAreaAssignment,
  MenuAreaAssignmentWidget,
  handleNewMenuCreated,
}) => {
  const { selectedMenu, setSelectedMenu, setPrompt } = useRestaurant();
  const [aiDialogOpen, setAIDialogOpen] = useState(false);  // State to manage AI dialog visibility

  const handleOpenAIDialog = () => {
    setAIDialogOpen(true);
  };

  const handleCloseAIDialog = () => {
    setAIDialogOpen(false);
    setPrompt('');
  };

  return (
    <HeaderContainerStyled>
      <ToggleButtonsContainer>
        <FormControl variant="outlined" style={{ width: 300 }}>
          <InputLabel id="menu-select-label">Select Menu</InputLabel>
          <CustomSelect
            labelId="menu-select-label"
            value={selectedMenu || ''}
            onChange={(event) => setSelectedMenu(event.target.value)}
            label="Select Menu"
          >
            {selectOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </CustomSelect>
        </FormControl>
        <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '5px' }}>
          <ClickLabel style={{ marginRight: '15px', padding: '4px' }} onClick={() => openEditMenuSidebar(selectedMenu, 'menu')}>Edit</ClickLabel>
          <ClickLabel style={{ marginRight: '15px', padding: '4px' }} onClick={() => openDeleteConfirmation(selectedMenu, 'menu')}>Delete</ClickLabel>
          <ClickLabel style={{ padding: '4px', color: '#121212' }} onClick={toggleMenuAreaAssignment}>Assign to restaurant</ClickLabel>
        </div>
      </ToggleButtonsContainer>

      <ActionButtonsContainer>
        <Button variant="outlined" style={{ marginRight: '35px' }} onClick={handlePreview}>Preview Menu</Button>
        <Button variant="contained" onClick={openMenuCreationSidebar}>Add Menu</Button>
        <Button variant="contained" style={{ marginLeft: '10px', backgroundImage: 'linear-gradient(45deg, #ff6a3ee3, #3e3effe3)' }} onClick={handleOpenAIDialog}>Generate with AI</Button>
      </ActionButtonsContainer>

      {/* AI Dialog */}
      <AIMenuCreationDialog open={aiDialogOpen} onClose={handleCloseAIDialog} onMenuCreated={handleNewMenuCreated} />

      {showMenuAreaAssignment && (
        <MenuAreaAssignmentWidget
          isVisible={showMenuAreaAssignment}
          onClose={closeMenuAreaAssignment}
        />
      )}
    </HeaderContainerStyled>
  );
};

export default MenuHeader;
