import React, { useRef } from 'react';
import { useRestaurant } from '../../contexts/RestaurantContext';
import { styled } from '@mui/system';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import RestaurantForm from './forms/RestaurantForm';
import AreaForm from './forms/AreaForm';
import MenuForm from './forms/MenuForm';
import CategoryForm from './forms/CategoryForm';
import MenuItemForm from './forms/MenuItemForm';
import ComboForm from './forms/ComboForm';
import AssignmentForms from './forms/AssignmentForms';

const SidebarContainer = styled('div')({
  padding: '50px',
});

const SidebarWrapper = styled('div')({
  position: 'fixed',
  top: 0,
  right: '-100%', // Start off-screen
  width: '50%', // Adjust width as needed
  height: '100%',
  backgroundColor: 'white',
  boxShadow: '-2px 0px 5px rgba(0, 0, 0, 0.5)',
  transition: 'right 0.5s ease', // Smooth transition for sliding effect
  zIndex: 1000,
  overflowY: 'auto', // Enable vertical scrolling
});

const SidebarWidget = () => {
  const sidebarRef = useRef();
  const { showSidebar, sidebarContext, closeSidebar } = useRestaurant();

  return (
    <SidebarWrapper ref={sidebarRef} style={{ right: showSidebar ? '0' : '-100%' }}>
      <SidebarContainer>
        {sidebarContext === 'editRestaurant' && <RestaurantForm context={sidebarContext} onClose={closeSidebar} showSidebar={showSidebar} />}
        {(sidebarContext === 'createArea' || sidebarContext === 'editArea') && <AreaForm context={sidebarContext} onClose={closeSidebar} showSidebar={showSidebar} />}
        {(sidebarContext === 'createMenu' || sidebarContext === 'editMenu') && <MenuForm context={sidebarContext} onClose={closeSidebar} showSidebar={showSidebar} />}
        {['createCategory', 'editCategory'].includes(sidebarContext) && <CategoryForm context={sidebarContext} onClose={closeSidebar} showSidebar={showSidebar} />}
        {(sidebarContext === 'createMenuItem' || sidebarContext === 'editMenuItem') && <MenuItemForm context={sidebarContext} onClose={closeSidebar} showSidebar={showSidebar} />}
        {(sidebarContext === 'createCombo' || sidebarContext === 'editCombo') && <ComboForm context={sidebarContext} onClose={closeSidebar} showSidebar={showSidebar} />}
        {['assignCategory', 'assignMenuItem'].includes(sidebarContext) && <AssignmentForms context={sidebarContext} onClose={closeSidebar} showSidebar={showSidebar} />}
        <IconButton onClick={closeSidebar} style={{ position: 'absolute', top: 15, right: 15 }}>
          <CloseIcon />
        </IconButton>
      </SidebarContainer>
    </SidebarWrapper>
  );
};

export default SidebarWidget;
