import React, { useEffect } from 'react';
import { usePOS } from '../../contexts/POSContext';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'; // core Swiper
import 'swiper/css/pagination'; // Pagination module
import { Pagination } from 'swiper/modules';
import POSFloorMap from './POSFloorMap';
import POSSidebar from './POSSidebar';

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; // Center-align the items horizontally
  position: relative; // Ensure relative positioning for contained elements
  width: 100%;
  background-color: #121212; /* Dark mode background */
`;

const AreaName = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  color: #CBD5E1;
`;

const SwiperContainer = styled.div`
  width: 100%; // Full width
  overflow: hidden; // Prevents any overflow from the swiper from appearing outside
  position: relative; // Ensure relative positioning for navigation buttons
  font-weight: 600;
  background-color: #121212; /* Dark mode background */
// NAVIGATION
  --swiper-navigation-size: 25px;
  --swiper-navigation-top-offset: 234px;
  --swiper-navigation-sides-offset: 4px;
  --swiper-navigation-color: var(--color-secondary);
// PAGINATION
  --swiper-pagination-color: var(--color-secondary);
  --swiper-pagination-left: 50%;
  --swiper-pagination-right: auto;
  --swiper-pagination-bottom: auto;
  --swiper-pagination-top: 0px;
  --swiper-pagination-fraction-color: inherit;
  --swiper-pagination-progressbar-bg-color: rgba(0, 0, 0, 0.25);
  --swiper-pagination-progressbar-size: 4px;
  --swiper-pagination-bullet-size: 8px;
  --swiper-pagination-bullet-width: 8px;
  --swiper-pagination-bullet-height: 8px;
  --swiper-pagination-bullet-inactive-color: var(--color-secondary);
  --swiper-pagination-bullet-inactive-opacity: 0.25;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 4px;
  --swiper-pagination-bullet-vertical-gap: 6px;
`;

const POSHome = () => {
  const { restaurantId, areas, loadAreas, selectedTable, handleTableSelect, currentAreaName, setCurrentAreaName, error } = usePOS();

  useEffect(() => {
    if (!areas.length && restaurantId) {
      loadAreas();
    }
  }, [areas, restaurantId, loadAreas]);

  const onSlideChange = (swiper) => {
    const currentSlideIndex = swiper.activeIndex;
    const currentArea = areas[currentSlideIndex];
    setCurrentAreaName(currentArea ? currentArea.name : '');
  };

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <>
      <POSSidebar isOpen={Boolean(selectedTable)} tableId={selectedTable} onClose={() => handleTableSelect(null)} />
      <HomeContainer>
        <AreaName>{currentAreaName}</AreaName>
        <SwiperContainer>
          <Swiper
            spaceBetween={0}
            slidesPerView={'auto'}
            pagination={{ clickable: true }}
            onSlideChange={onSlideChange}
            modules={[Pagination]}
            allowTouchMove={true}
          >
            {areas.map((area) => (
              <SwiperSlide key={area.id}>
                <POSFloorMap areaId={area.id} onTableSelect={handleTableSelect} />
              </SwiperSlide>
            ))}
          </Swiper>
        </SwiperContainer>
      </HomeContainer>
    </>
  );
};

export default POSHome;
