import api from './apiInstance';

// Use environment variable for API base URL
const API_BASE_URL = process.env.REACT_APP_API_URL;

export const fetchAreasForRestaurant = async (restaurantId) => {
  try {
    const response = await api.get(`${API_BASE_URL}pos/areas/?restaurant_id=${restaurantId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching areas for restaurant:', error);
    throw error;
  }
};

// Function to fetch tables for a specific area
export const fetchTablesForArea = async (areaId) => {
  try {
    const response = await api.get(`${API_BASE_URL}pos/tables/?area=${areaId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching tables for area:', error);
    throw error;
  }
};

// Function to fetch tables for the entire restaurant
export const fetchTablesForRestaurant = async (restaurantId) => {
  try {
    const response = await api.get(`${API_BASE_URL}pos/tables/?restaurant=${restaurantId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching tables for restaurant:', error);
    throw error;
  }
};

export const fetchMenusForRestaurant = async (restaurantId) => {
  try {
    const response = await api.get(`${API_BASE_URL}pos/menus-for-restaurant/${restaurantId}/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching menus for restaurant:', error);
    throw error;
  }
};

export const fetchMenuCategories = async (menuId) => {
  try {
    const response = await api.get(`${API_BASE_URL}pos/menu-categories/${menuId}/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching menu categories:', error);
    throw error;
  }
};

export const fetchMenuItemsAndCombosByCategory = async (categoryId) => {
  try {
    const response = await api.get(`${API_BASE_URL}pos/menu-items-and-combos/${categoryId}/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching menu items and combos by category:', error);
    throw error;
  }
};

export const fetchAggregatedOrder = async (tableId) => {
  try {
    const response = await api.get(`${API_BASE_URL}pos/aggregated-order/${tableId}/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching aggregated order:', error);
    throw error;
  }
};

export const createOrderItems = async (orderId, items) => {
  try {
    const response = await api.post(`${API_BASE_URL}pos/order-items/`, 
      {
        order_id: orderId,
        items: items,
      }, 

    );
    return response.data;
  } catch (error) {
    console.error('Error creating order items:', error);
    throw error;
  }
};

export const createOrder = async (orderData) => {
  try {
    const response = await api.post(`${API_BASE_URL}pos/orders/`, orderData);
    return response.data;
  } catch (error) {
    console.error('Error creating order:', error);
    throw error;
  }
};

export const updateOrder = async (orderId, orderData) => {
  try {
    const response = await api.patch(`${API_BASE_URL}pos/orders/${orderId}/`, orderData);
    return response.data;
  } catch (error) {
    console.error('Error updating order:', error);
    throw error;
  }
};

export const updateCustomOrder = async (orderId, data) => {
  try {
    const payload = {
      order_id: orderId,
      updated_courses: data.updated_courses || {},
      new_items: data.new_items || [],
    };

    const response = await api.patch(`${API_BASE_URL}pos/custom-order-update/`, payload);
    return response.data;
  } catch (error) {
    console.error('Error updating custom order:', error);
    throw error;
  }
};

export const updateMealCourses = async (orderId, updatedCourses) => {
  try {
    const response = await api.patch(`${API_BASE_URL}pos/update-meal-courses/`, {
      order_id: orderId,
      updated_courses: updatedCourses,
    });
    return response.data;
  } catch (error) {
    console.error('Error updating meal courses:', error);
    throw error;
  }
};

export const updateOrderItemNote = async (itemId, payload) => {
  try {
    const response = await api.patch(`${API_BASE_URL}pos/order-items/${itemId}/`, payload);
    return response.data;
  } catch (error) {
    console.error('Error updating order item note:', error);
    throw error;
  }
};

export const deleteOrder = async (orderId) => {
  try {
    const response = await api.delete(`${API_BASE_URL}pos/orders/${orderId}/`);
    return response;
  } catch (error) {
    console.error('Error deleting order:', error);
    throw error;
  }
};

export const fetchAggregatedOrdersByTable = async () => {
  try {
    const response = await api.get(`${API_BASE_URL}pos/aggregated-orders-by-table/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching aggregated orders by table:', error);
    throw error;
  }
};

export const fetchAggregatedOrdersByCourse = async () => {
  try {
    const response = await api.get(`${API_BASE_URL}pos/aggregated-orders-by-course/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching aggregated orders by course:', error);
    throw error;
  }
};

export const updateOrderItemStatus = async (itemId, action) => {
  let endpoint = '';
  if (action === 'in_preparation') {
    endpoint = 'prepare';
  } else if (action === 'ready') {
    endpoint = 'ready';
  } else if (action === 'consumed') {
    endpoint = 'consume';
  } else {
    throw new Error('Invalid action for updating order item status');
  }

  try {
    const response = await api.patch(`${API_BASE_URL}pos/order-items/${itemId}/${endpoint}/`, {});
    return response.data;
  } catch (error) {
    console.error(`Error updating item status to ${action}:`, error);
    throw error;
  }
};

export const initiatePayment = async (orderId, paymentData) => {
  try {
    const response = await api.post(`${API_BASE_URL}pos/initiate-payment/${orderId}/`, paymentData);
    return response.data;
  } catch (error) {
    console.error('Error initiating payment:', error);
    throw error;
  }
};

export const fetchOrderPaymentDetails = async (orderId) => {
  try {
    const response = await api.get(`${API_BASE_URL}pos/get-order-payment-details/${orderId}/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching order payment details:', error);
    throw error;
  }
};

export const fetchReceipts = async (restaurantId) => {
  try {
    const response = await api.get(`pos/restaurant/${restaurantId}/receipts/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching receipts:', error);
    throw error;
  }
};

export const createReceipt = async (orderId) => {
  try {
    const response = await api.post(`pos/order/${orderId}/create-receipt/`, {});
    return response.data;
  } catch (error) {
    console.error('Error creating receipt:', error);
    throw error;
  }
};

// Fetch reservations
export const fetchReservations = async () => {
  try {
    const response = await api.get(`${API_BASE_URL}pos/reservations/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching reservations:', error);
    throw error;
  }
};

// Create a reservation
export const createReservation = async (reservationData) => {
  try {
    const response = await api.post(`${API_BASE_URL}pos/reservations/`, reservationData);
    return response.data;
  } catch (error) {
    console.error('Error creating reservation:', error);
    throw error;
  }
};

// Update a reservation
export const updateReservation = async (reservationId, reservationData) => {
  try {
    const response = await api.patch(`${API_BASE_URL}pos/reservations/${reservationId}/`, reservationData);
    return response.data;
  } catch (error) {
    console.error('Error updating reservation:', error);
    throw error;
  }
};

// Delete (cancel) a reservation
export const deleteReservation = async (reservationId) => {
  try {
    await api.delete(`${API_BASE_URL}pos/reservations/${reservationId}/`);
  } catch (error) {
    console.error('Error deleting reservation:', error);
    throw error;
  }
};

export const createWalkInReservation = async (guestCount, tableId) => {
  try {
    const response = await api.post(`${API_BASE_URL}pos/walk-in/`, {
      guest_count: guestCount,
      table_id: tableId,
    });
    return response.data;
  } catch (error) {
    console.error("Error creating walk-in reservation:", error);
    throw error;
  }
};

// Fetch available tables for walk-in
export const fetchAvailableTablesForWalkIn = async (restaurantId, guestCount) => {
  try {
    const response = await api.get(`${API_BASE_URL}pos/available-tables-for-walk-in/${restaurantId}/${guestCount}/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching available tables for walk-in:', error);
    throw error;
  }
};

