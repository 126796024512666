import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Typography, Button, Alert } from '@mui/material';
import { verifyEmail } from '../../services/authApiService';

// Styled components
const Container = styled.div`
  padding: 10% 10% 2% 10%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) { /* Tablets */
    padding: 10% 20% 2% 20%;
  }

  @media (min-width: 1024px) { /* Desktops */
    padding: 10% 35% 2% 35%;
  }
`;

const SuccessVerificationPage = () => {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const [verificationStatus, setVerificationStatus] = useState('');

  useEffect(() => {
    verifyEmail(uuid)
      .then(response => {
        const status = response.data.status;
        setVerificationStatus(status);
      })
      .catch(error => {
        if (error.response) {
          setVerificationStatus('error');
        }
      });
  }, [uuid]);

  const renderContent = () => {
    switch (verificationStatus) {
      case 'success':
        return (
          <>
            <Typography variant="h2" gutterBottom>You are all set!</Typography>
            <Typography variant="body1">
              You have successfully verified your email.<br/>
              You can now access your free trial.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate('/login')}
              style={{ marginTop: '20px' }}
            >
              Get started
            </Button>
          </>
        );
      case 'already-verified':
        return (
          <>
            <Typography variant="h2" gutterBottom>Already Verified</Typography>
            <Typography variant="body1">Your email has already been verified.</Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate('/login')}
              style={{ marginTop: '20px' }}
            >
              Login
            </Button>
          </>
        );
      case 'error':
        return (
          <>
            <Typography variant="h2" gutterBottom>Verification Failed</Typography>
            <Typography variant="body1">
              The verification link is invalid or has expired.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate('/register')}
              style={{ marginTop: '20px' }}
            >
              Register
            </Button>
          </>
        );
      default:
        return <Alert severity="info">Verifying your email...</Alert>;
    }
  };

  return (
    <Container>
      {renderContent()}
    </Container>
  );
};

export default SuccessVerificationPage;
