// CustomTimePicker.jsx
import React from 'react';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import AccessTimeRounded from '@mui/icons-material/AccessTimeRounded';

const generateTimeOptions = (interval) => {
  const options = [];
  for (let i = 0; i < 24 * 60; i += interval) {
    const hours = String(Math.floor(i / 60)).padStart(2, '0');
    const minutes = String(i % 60).padStart(2, '0');
    options.push(`${hours}:${minutes}`);
  }
  return options;
};

const CustomTimePicker = ({ label, value, onChange, timeInterval = 15 }) => {
  const timeOptions = generateTimeOptions(timeInterval); // Generate time options based on interval

  return (
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        value={value || ''}
        onChange={(e) => onChange(e.target.value)}
        label={label || ''}
        IconComponent={(props) => <AccessTimeRounded {...props} sx={{ fontSize: '19px' }} />}
  MenuProps={{
    PaperProps: {
      sx: {
        maxHeight: 200, // Limit height of dropdown to 200px (or desired height)
        width: 150,     // Set width (optional)
      },
    },
  }}
>
      >
        {timeOptions.map((time) => (
          <MenuItem key={time} value={time}>
            {time}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomTimePicker;