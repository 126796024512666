import { styled } from '@mui/system';
import { Button, Typography, Select, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';

// Notes:
// theme.palette.PROP.main
// import {  } from '../../Common/MUI.StyledComponents';

// Custom SELECT ICON with transitions for select
const CustomSelectIcon = styled(ExpandMoreIcon)(({ theme }) => ({
  transition: 'transform 0.3s ease-in-out',
}));

// Custom DROPDOWN ICON rounded with transitions
const UnderlinedButtonIcon = styled(ArrowDropDownRoundedIcon)(({ theme, $open }) => ({
  color: theme.palette.black.main,
  transition: 'transform 0.3s ease-in-out',
  transform: $open ? 'rotate(180deg)' : 'rotate(0deg)',
}));

// Custom SELECT component with full border
export const CustomSelect = styled((props) => (
  <Select
    {...props}
    IconComponent={CustomSelectIcon}
    MenuProps={{
      PaperProps: {
        sx: {
          '& .MuiMenuItem-root': {
            backgroundColor: 'white',
            '&:hover': {
              backgroundColor: 'lightgrey',
            },
          },
        },
      },
    }}
  />
))(({ theme }) => ({
  color: theme.palette.black.main,
  width: '275px',
  height: '55px',
  backgroundColor: 'white',
  borderRadius: '5px',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.grey.main,
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.dark.main,
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.primary.main,
  },
  '& .MuiSelect-icon': {
    transition: 'transform 0.3s ease-in-out',
  },
  '&.Mui-expanded .MuiSelect-icon': {
    transform: 'rotate(180deg)',
  },
}));

// Custom TEXT BUTTON with thick underline and dropdown icon
export const UnderlinedButtonWithIcon = styled(({ $open, ...props }) => (
  <Button {...props} endIcon={<UnderlinedButtonIcon $open={$open} />} />
))(({ theme }) => ({
  color: theme.palette.black.main,
  textTransform: 'none',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '&::after': { // Underline style
    content: '""',
    position: 'absolute',
    left: '50%',
    bottom: 4,
    width: '90%',
    height: 2.5,
    backgroundColor: theme.palette.primary.main,
    transition: 'background-color 0.3s',
    transform: 'translateX(-50%)',
  },
  '&:hover': {
    color: theme.palette.black.main,
    backgroundColor: 'transparent', // Remove greyish background on hover
  },
  '&:hover::after': {
    backgroundColor: theme.palette.primary.dark,
  },
  '& .MuiButton-endIcon': {
    color: theme.palette.black.main,
    transition: 'transform 0.3s ease-in-out',
  },
}));

// Custom TEXT BUTTON with thick underline
export const UnderlinedButton = styled(Button)(({ theme }) => ({
  color: theme.palette.black.main,
  textTransform: 'none',
  position: 'relative',
  '&::after': { // Undelrine style
    content: '""',
    position: 'absolute',
    left: '50%',
    bottom: 5,
    width: '92%',
    height: 2,
    backgroundColor: theme.palette.secondary.main,
    transition: 'background-color 0.3s',
    transform: 'translateX(-50%)',
  },
  '&:hover': {
    color: theme.palette.black.main,
    backgroundColor: 'transparent', // Remove greyish background on hover
  },
  '&:hover::after': {
    backgroundColor: theme.palette.secondary.dark,
  },
}));

// Custom TEXT with underline
export const UnderlinedText = styled(Typography)(({ theme }) => ({
  textTransform: 'none',
  position: 'relative',
  '&::after': { // Undelrine style
    content: '""',
    position: 'absolute',
    left: '50%',
    bottom: 1,
    width: '100%',
    height: 2.5,
    backgroundColor: theme.palette.primary.main,
    transition: 'background-color 0.3s',
    transform: 'translateX(-50%)',
  },
}));

// Custom label TEXT with click effects
export const ClickLabel = styled(Typography)(({ theme }) => ({
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

// Custom Dialog components
const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
}));

const CustomDialogTitle = styled(DialogTitle)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const CustomDialogContent = styled(DialogContent)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.black.main,
}));

const CustomDialogContentText = styled(DialogContentText)(({ theme }) => ({
  color: theme.palette.black.main,
}));

const CustomDialogActions = styled(DialogActions)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
}));

const CancelDialogButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const DeleteDialogButton = styled(Button)(({ theme }) => ({
  color: theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

// Standardized Delete Confirmation Dialog
export const DeleteConfirmationDialog = ({ open, onClose, onConfirm, instanceName }) => (
  <CustomDialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <CustomDialogTitle id="alert-dialog-title">{"Confirm Delete"}</CustomDialogTitle>
    <CustomDialogContent>
      <CustomDialogContentText id="alert-dialog-description">
        Are you sure you want to delete this {instanceName}? This action cannot be undone.
      </CustomDialogContentText>
    </CustomDialogContent>
    <CustomDialogActions>
      <CancelDialogButton onClick={onClose} color="primary">
        Cancel
      </CancelDialogButton>
      <DeleteDialogButton onClick={onConfirm} color="error">
        Delete
      </DeleteDialogButton>
    </CustomDialogActions>
  </CustomDialog>
);