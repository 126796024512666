import React, { useState, useEffect } from 'react';
import {
  Stepper, Step, StepLabel, Button, Typography, TextField, CircularProgress,
  FormControl, InputLabel, MenuItem, Select, Switch, FormControlLabel, Checkbox,
  Alert
} from '@mui/material';
import styled from 'styled-components';
import { useRestaurant } from '../../../contexts/RestaurantContext';
import { createMenu, handleMenuCategory, handleMenuItem, fetchAllergens, fetchDietGroups } from '../../../services/apiService';
import PictureUpload from '../../Common/PictureUpload';

const steps = ['Create Menu', 'Add Category', 'Add Menu Item', 'Additional Info'];

const courseTypeOptions = [
  { value: 'Drinks', label: 'Drinks' },
  { value: 'Starter', label: 'Starters' },
  { value: 'Pasta', label: 'Pasta & Noodles' },
  { value: 'Main Course', label: 'Main Courses' },
  { value: 'Side Dish', label: 'Side Dishes' },
  { value: 'Dessert', label: 'Desserts' }
];

const dayOptions = [
  { value: 'MO', label: 'Monday' },
  { value: 'TU', label: 'Tuesday' },
  { value: 'WE', label: 'Wednesday' },
  { value: 'TH', label: 'Thursday' },
  { value: 'FR', label: 'Friday' },
  { value: 'SA', label: 'Saturday' },
  { value: 'SU', label: 'Sunday' }
];

const WizardContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  justify-content: flex-start;
  padding: 2rem 0;
`;

const InputContainer = styled.div`
  width: 65%;
  margin-bottom: 1rem;
`;

const DescriptionText = styled(Typography)`
  color: darkgrey;
  margin-bottom: 0.5rem;
`;

const BottomButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
`;

const MenuWizard = ({ onComplete }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [menuName, setMenuName] = useState('');
  const [categoryName, setCategoryName] = useState('');
  const [categoryDescription, setCategoryDescription] = useState('');
  const [courseType, setCourseType] = useState('');
  const [showCategoryDaySelector, setShowCategoryDaySelector] = useState(false);
  const [categoryAvailableDays, setCategoryAvailableDays] = useState([]);
  const [menuItemName, setMenuItemName] = useState('');
  const [menuItemDescription, setMenuItemDescription] = useState('');
  const [menuItemPrice, setMenuItemPrice] = useState('');
  const [selectedAllergens, setSelectedAllergens] = useState([]);
  const [selectedDietGroups, setSelectedDietGroups] = useState([]);
  const [allergenOptions, setAllergenOptions] = useState([]);
  const [dietGroupOptions, setDietGroupOptions] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);

  const { loadMenuCategories, loadMenuItems } = useRestaurant();

  useEffect(() => {
    const loadOptions = async () => {
      try {
        const [allergens, dietGroups] = await Promise.all([
          fetchAllergens(),
          fetchDietGroups()
        ]);
        setAllergenOptions(allergens.map(allergen => ({ value: allergen.id, label: allergen.name })));
        setDietGroupOptions(dietGroups.map(dietGroup => ({ value: dietGroup.id, label: dietGroup.name })));
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };

    loadOptions();
  }, []);

  const handleFinish = async () => {
    setLoading(true);
    setAlertMessage(null);
    try {
      // Create Menu
      const newMenu = await createMenu({ name: menuName });
      const menuId = newMenu.id;

      // Create Category
      const newCategory = await handleMenuCategory({
        name: categoryName,
        description: categoryDescription,
        menus: [menuId],
        available_days: categoryAvailableDays.length > 0 ? categoryAvailableDays.join(',') : 'EVERYDAY',
        course_type: courseType,
      });
      const categoryId = newCategory.id;

      // Create Menu Item
      const menuItemData = new FormData();
      menuItemData.append('name', menuItemName);
      menuItemData.append('description', menuItemDescription);
      menuItemData.append('price', menuItemPrice);
      menuItemData.append('categories', categoryId);
      if (selectedFile) {
        menuItemData.append('picture', selectedFile);
      }
      if (selectedAllergens.length > 0) {
        selectedAllergens.forEach(allergen => {
          menuItemData.append('allergens', allergen);
        });
      }
      if (selectedDietGroups.length > 0) {
        selectedDietGroups.forEach(dietGroup => {
          menuItemData.append('diet_groups', dietGroup);
        });
      }

      await handleMenuItem(menuItemData);
      await loadMenuCategories(menuId);
      await loadMenuItems(categoryId);
      onComplete(); // Call onComplete to close the wizard
    } catch (error) {
      setAlertMessage({ type: 'error', text: 'Error completing the wizard. Please try again.' });
      console.error('Error completing the wizard:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleNext = () => {
    setAlertMessage(null);
    if (activeStep === 0 && !menuName.trim()) {
      setAlertMessage({ type: 'warning', text: 'Menu name is required' });
      return;
    }
    if (activeStep === 1 && !categoryName.trim()) {
      setAlertMessage({ type: 'warning', text: 'Category name is required' });
      return;
    }
    if (activeStep === 2 && (!menuItemName.trim() || !menuItemDescription.trim() || !menuItemPrice.trim())) {
      setAlertMessage({ type: 'warning', text: 'All menu item fields are required' });
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleDayChange = (event) => {
    const value = event.target.value;
    setCategoryAvailableDays(typeof value === 'string' ? value.split(',') : value);
  };

  const handleToggleDaySelector = (event) => {
    setShowCategoryDaySelector(event.target.checked);
    if (!event.target.checked) {
      setCategoryAvailableDays([]);
    }
  };

  const handleCourseTypeChange = (event) => {
    setCourseType(event.target.value);
  };

  const handleAllergenChange = (event) => {
    const { value } = event.target;
    setSelectedAllergens(value);
  };

  const handleDietGroupChange = (event) => {
    const { value } = event.target;
    setSelectedDietGroups(value);
  };

  const handleFileSelect = (file, previewUrl) => {
    setSelectedFile(file);
    setPreviewUrl(previewUrl);
  };

  const handleClearImage = () => {
    setSelectedFile(null);
    setPreviewUrl(null);
  };

  return (
    <WizardContainer>
      <Stepper activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel style={{ color: 'grey' }}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {loading ? (
        <StepContainer>
          <CircularProgress />
        </StepContainer>
      ) : (
        <StepContainer>
          {alertMessage && (
            <Alert severity={alertMessage.type} sx={{ width: '65%', marginBottom: '1rem' }}>
              {alertMessage.text}
            </Alert>
          )}
          {activeStep === steps.length ? (
            <Typography>All steps completed - you're finished</Typography>
          ) : (
            <>
              {activeStep === 0 && (
                <InputContainer>
                  <DescriptionText variant="body2">Add a name to your menu</DescriptionText>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Menu Name"
                    value={menuName}
                    onChange={(e) => setMenuName(e.target.value)}
                    margin="normal"
                  />
                </InputContainer>
              )}
              {activeStep === 1 && (
                <>
                  <InputContainer>
                    <DescriptionText variant="body2">Add a name to your category</DescriptionText>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Category Name"
                      value={categoryName}
                      onChange={(e) => setCategoryName(e.target.value)}
                      margin="normal"
                    />
                  </InputContainer>
                  <InputContainer>
                    <DescriptionText variant="body2">Add a description for your category (optional)</DescriptionText>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Description"
                      value={categoryDescription}
                      onChange={(e) => setCategoryDescription(e.target.value)}
                      margin="normal"
                    />
                  </InputContainer>
                  <InputContainer>
                    <FormControl fullWidth margin="normal">
                      <InputLabel>Course Type</InputLabel>
                      <Select
                        value={courseType}
                        onChange={handleCourseTypeChange}
                        label="Course Type"
                      >
                        {courseTypeOptions.map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </InputContainer>
                  <InputContainer>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={showCategoryDaySelector}
                          onChange={handleToggleDaySelector}
                          name="showCategoryDaySelector"
                          color="primary"
                        />
                      }
                      label="Select days for availability"
                    />
                    {showCategoryDaySelector && (
                      <FormControl fullWidth margin="normal">
                        <InputLabel>Available Days</InputLabel>
                        <Select
                          multiple
                          value={categoryAvailableDays}
                          onChange={handleDayChange}
                          label="Available Days"
                          renderValue={(selected) => selected.map(value => dayOptions.find(option => option.value === value)?.label).join(', ')}
                        >
                          {dayOptions.map(option => (
                            <MenuItem key={option.value} value={option.value} style={{ fontWeight: categoryAvailableDays.includes(option.value) ? 'bold' : 'normal' }}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </InputContainer>
                </>
              )}
              {activeStep === 2 && (
                <>
                  <InputContainer>
                    <DescriptionText variant="body2">Add a name to your menu item</DescriptionText>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Menu Item Name"
                      value={menuItemName}
                      onChange={(e) => setMenuItemName(e.target.value)}
                      margin="normal"
                    />
                  </InputContainer>
                  <InputContainer>
                    <DescriptionText variant="body2">Add a description for your menu item</DescriptionText>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Description"
                      value={menuItemDescription}
                      onChange={(e) => setMenuItemDescription(e.target.value)}
                      margin="normal"
                    />
                  </InputContainer>
                  <InputContainer>
                    <DescriptionText variant="body2">Set a price for your menu item</DescriptionText>
                    <TextField
                      fullWidth
                      variant="outlined"
                      label="Price"
                      type="number"
                      value={menuItemPrice}
                      onChange={(e) => setMenuItemPrice(e.target.value)}
                      margin="normal"
                    />
                  </InputContainer>
                </>
              )}
              {activeStep === 3 && (
                <>
                  <InputContainer>
                    <DescriptionText variant="body2">Select allergens (if any)</DescriptionText>
                    <FormControl fullWidth margin="normal">
                      <InputLabel>Allergens</InputLabel>
                      <Select
                        multiple
                        value={selectedAllergens}
                        onChange={handleAllergenChange}
                        renderValue={(selected) => selected.map((item) => allergenOptions.find(option => option.value === item)?.label).join(', ')}
                      >
                        {allergenOptions.map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            <FormControlLabel
                              control={<Checkbox checked={selectedAllergens.indexOf(option.value) > -1} />}
                              label={option.label}
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </InputContainer>
                  <InputContainer>
                    <DescriptionText variant="body2">Select diet groups (if any)</DescriptionText>
                    <FormControl fullWidth margin="normal">
                      <InputLabel>Diet Groups</InputLabel>
                      <Select
                        multiple
                        value={selectedDietGroups}
                        onChange={handleDietGroupChange}
                        renderValue={(selected) => selected.map((item) => dietGroupOptions.find(option => option.value === item)?.label).join(', ')}
                      >
                        {dietGroupOptions.map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            <FormControlLabel
                              control={<Checkbox checked={selectedDietGroups.indexOf(option.value) > -1} />}
                              label={option.label}
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </InputContainer>
                  <InputContainer>
                    <DescriptionText variant="body2">Upload a picture for your menu item (optional)</DescriptionText>
                    <PictureUpload
                      existingFile={null}
                      previewUrl={previewUrl}
                      onFileSelect={handleFileSelect}
                      onClearImage={handleClearImage}
                    />
                  </InputContainer>
                </>
              )}
            </>
          )}
        </StepContainer>
      )}
      <BottomButtonsContainer>
        <Button
          color="inherit"
          disabled={activeStep === 0}
          onClick={handleBack}
          variant="outlined"
        >
          Back
        </Button>
        <Button onClick={activeStep === steps.length - 1 ? handleFinish : handleNext} variant="contained" color="primary">
          {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
        </Button>
      </BottomButtonsContainer>
    </WizardContainer>
  );
};

export default MenuWizard;
