// DefaultMenu v1.00
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes for prop validation
import { fetchDefaultMenuDetails } from '../../services/usersApiService';
import AllergenDialog from './AllergenDialog';
import styled from 'styled-components';
import { CircularProgress, Typography, Alert } from '@mui/material';

const MenuCategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
  padding-left: 10px;
  padding-right: 10px;
`;

const MenuItemContainer = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
`;

const LeftColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 10px 10px 0;
`;

const RightColumn = styled.div`
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const PictureContainer = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid darkgrey;
`;

const MenuItemImage = styled.img`
  width: auto;
  height: 100%;
  object-fit: cover;
`;

const PriceContainer = styled.div`
  margin-top: auto; // Pushes the price to the bottom
  display: flex;
  align-items: flex-end;
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const DefaultMenu = ({ restaurantId }) => {
  const [menuData, setMenuData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [allergenDialogOpen, setAllergenDialogOpen] = useState(false);
  const [currentAllergens, setCurrentAllergens] = useState([]);

  useEffect(() => {
    const fetchMenuDetails = async () => {
      setLoading(true);
      try {
        // Use the new service function
        const data = await fetchDefaultMenuDetails(restaurantId);
        setMenuData(data);
      } catch (error) {
        console.error('Failed to fetch default menu details:', error);
      } finally {
        setLoading(false);
      }
    };
    if (restaurantId) fetchMenuDetails();
  }, [restaurantId]);

  // Get categories from the menuData
  const categories = menuData?.categories || [];
  const hasMenuItems = categories.length > 0;

  const openAllergenDialog = (allergens) => {
    setCurrentAllergens(allergens);
    setAllergenDialogOpen(true);
  };

  const closeAllergenDialog = () => {
    setAllergenDialogOpen(false);
  };

  if (loading) {
    return (
      <SpinnerContainer>
        <CircularProgress size={100} color="secondary" />
      </SpinnerContainer>
    );
  }

  if (!hasMenuItems) {
    return (
      <div style={{ paddingTop: '50px', paddingLeft: '25px', paddingRight: '25px' }}>
        <Alert severity="warning">
          No menus are available at the moment. Please check again later.
        </Alert>
      </div>
    );
  }

  return (
    <div>
      <MenuCategoryContainer>
        {categories.map(({ categoryName, items, combos }) => (
          <div key={categoryName}>
            <Typography variant="h4" style={{ marginTop: '30px', marginBottom: '20px' }}>
              {categoryName}
            </Typography>
            {items.map((item) => (
              <MenuItemContainer key={item.id}>
                <LeftColumn>
                  <div style={{ marginBottom: '20px' }}>
                    <Typography style={{ fontWeight: 'bold' }}>{item.name}</Typography>
                    <Typography>{item.description}</Typography>
                    {item.allergens.length > 0 && (
                      <Typography
                        color="secondary"
                        style={{
                          cursor: 'pointer',
                          textDecoration: 'underline',
                          marginTop: '10px',
                        }}
                        onClick={() => openAllergenDialog(item.allergens)}
                      >
                        Allergens
                      </Typography>
                    )}
                  </div>
                  <PriceContainer>
                    <Typography color="primary" fontWeight="bold">
                      €{item.price}
                    </Typography>
                  </PriceContainer>
                </LeftColumn>
                <RightColumn>
                  {item.picture && (
                    <PictureContainer>
                      <MenuItemImage src={item.picture} alt={item.name} />
                    </PictureContainer>
                  )}
                </RightColumn>
              </MenuItemContainer>
            ))}
            {combos.map((combo) => (
              <MenuItemContainer key={combo.id}>
                <LeftColumn>
                  <div style={{ marginBottom: '20px' }}>
                    <Typography style={{ fontWeight: 'bold' }}>{combo.name}</Typography>
                    <Typography>{combo.description}</Typography>
                    {combo.allergens.length > 0 && (
                      <Typography
                        color="secondary"
                        style={{
                          cursor: 'pointer',
                          textDecoration: 'underline',
                          marginTop: '10px',
                        }}
                        onClick={() => openAllergenDialog(combo.allergens)}
                      >
                        Allergens
                      </Typography>
                    )}
                  </div>
                  <PriceContainer>
                    <Typography color="primary" fontWeight="bold">
                      €{combo.price}
                    </Typography>
                  </PriceContainer>
                </LeftColumn>
                <RightColumn>
                  {combo.picture && (
                    <PictureContainer>
                      <MenuItemImage src={combo.picture} alt={combo.name} />
                    </PictureContainer>
                  )}
                </RightColumn>
              </MenuItemContainer>
            ))}
          </div>
        ))}
      </MenuCategoryContainer>

      <AllergenDialog
        open={allergenDialogOpen}
        onClose={closeAllergenDialog}
        allergens={currentAllergens}
      />
    </div>
  );
};

// Add propTypes validation
DefaultMenu.propTypes = {
  restaurantId: PropTypes.string.isRequired,
};

export default DefaultMenu;
