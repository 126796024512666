import React, { useState, useRef } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Slider, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';


const PictureUpload = ({ existingFile, previewUrl, onFileSelect, onClearImage }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [showEditor, setShowEditor] = useState(false);
  const [scale, setScale] = useState(1);
  const fileInputRef = useRef(null);
  const editorRef = useRef(null);
  const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2 MB in bytes

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
    if (!validTypes.includes(file.type)) {
      alert("Please select an image file (jpeg, png, or gif).");
      return;
    }

    if (file.size > MAX_FILE_SIZE) {
      alert("File size should not exceed 2 MB.");
      return;
    }

    setSelectedFile(file);
    setShowEditor(true); // Show the editor modal

    // Clear the file input value to allow re-uploading the same file
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleCrop = () => {
    if (editorRef.current) {
      const canvas = editorRef.current.getImage().toDataURL("image/jpeg");
      const blob = dataURLToBlob(canvas);
      const file = new File([blob], selectedFile.name, { type: blob.type }); // Create a new file with the original name and type
      onFileSelect(file, URL.createObjectURL(blob)); // Pass the preview URL
      setShowEditor(false);
    }
  };

  const handleScaleChange = (event, newValue) => {
    setScale(newValue);
  };

  const dataURLToBlob = (dataURL) => {
    const byteString = atob(dataURL.split(',')[1]);
    const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  return (
    <Box mt={2} mb={2}>
      <Button
        variant="outlined"
        component="label"
        margin="normal"
        startIcon={<CloudUploadIcon />}
      >
        Upload Picture
        <input
          type="file"
          hidden
          onChange={handleFileChange}
          ref={fileInputRef}
        />
      </Button>
      {previewUrl && (
        <Box mt={2} display="flex" flexDirection="column">
          <img src={previewUrl} alt="Preview" style={{ width: '300px', height: '240px', border: '1px solid grey', borderRadius: '20px' }} />
          <Button onClick={onClearImage} color="secondary" style={{justifyContent: 'left'}}>Clear Image</Button>
        </Box>
      )}
      <Dialog open={showEditor} onClose={() => setShowEditor(false)} maxWidth="md" fullWidth>
        <DialogTitle>Crop and Resize Image</DialogTitle>
        <DialogContent>
          <AvatarEditor
            ref={editorRef}
            image={selectedFile}
            width={250}
            height={250}
            border={50}
            color={[255, 255, 255, 0.6]} // RGBA
            scale={scale}
            rotate={0}
          />
          <Box mt={2}>
            <Typography variant="body2">Zoom</Typography>
            <Slider
              value={scale}
              onChange={handleScaleChange}
              min={1}
              max={2}
              step={0.1}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCrop} color="primary">Save</Button>
          <Button onClick={() => setShowEditor(false)} color="secondary">Cancel</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PictureUpload;
