import React from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios';
import './index.css';
import './App.css';
import App from './App';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './themes/MUI.theme';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './contexts/AuthContext';
import { RestaurantProvider } from './contexts/RestaurantContext';
import { POSProvider } from './contexts/POSContext';
import { BrowserRouter as Router } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import api from './services/apiInstance';

// Use environment variable for API base URL
const API_BASE_URL = process.env.REACT_APP_API_URL;

// Configure Axios for CSRF tokens
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

// CSRF token setup
api.get(`${API_BASE_URL}auth/csrf/`)
  .then(response => {
    console.log('CSRF cookie set');
  })
  .catch(error => {
    console.error('Error setting CSRF cookie:', error);
  });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <AuthProvider>
          <RestaurantProvider>
            <POSProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <App />
              </LocalizationProvider>
            </POSProvider>
          </RestaurantProvider>
        </AuthProvider>
      </Router>
    </ThemeProvider>
  </React.StrictMode>,
);

reportWebVitals();