import React, { useState } from 'react';
import { MenuItem, Menu, FormControl } from '@mui/material';
import styled from 'styled-components';
import { ClickLabel, UnderlinedButton } from '../../Common/MUI.StyledComponents';

const CategoryActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
`;

const ExpandCollapseContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 165px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const SelectButtonContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const CategoryActions = ({
  addCategoryOptions,
  handleAddCategorySelection,
  setCategoryActionSelection,
  categoryActionSelection,
  toggleGlobalExpansion,
  hasCategories,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (option) => {
    setCategoryActionSelection(option);
    handleAddCategorySelection(option);
    handleClose();
  };

  return (
    <CategoryActionsContainer>
      {hasCategories && (
        <ExpandCollapseContainer>
          <ClickLabel color="primary" onClick={() => toggleGlobalExpansion(true)}>
            Expand all
          </ClickLabel>
          <ClickLabel color="primary" onClick={() => toggleGlobalExpansion(false)}>
            Collapse all
          </ClickLabel>
        </ExpandCollapseContainer>
      )}
      <SelectButtonContainer>
        <FormControl>
          <UnderlinedButton onClick={handleClick} open={open}>
            Add Category
          </UnderlinedButton>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {addCategoryOptions.map((option) => (
              <MenuItem
                key={option.value}
                onClick={() => handleMenuItemClick(option)}
              >
                {option.label}
              </MenuItem>
            ))}
          </Menu>
        </FormControl>
      </SelectButtonContainer>
    </CategoryActionsContainer>
  );
};

export default CategoryActions;
