// AnalyticsHome.jsx
import React, { useState, useEffect } from 'react';
import { Typography, Grid, Card, CardContent, Box, useTheme, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { fetchAnalyticsData, getAIInsights } from '../../services/apiService';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'; // Correct adapter
import dayjs from 'dayjs';

const AnalyticsHome = () => {
  const [analyticsData, setAnalyticsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(dayjs().subtract(7, 'day')); // Default to last 7 days
  const [endDate, setEndDate] = useState(dayjs());
  const theme = useTheme();
  const [insightsOpen, setInsightsOpen] = useState(false);
  const [insights, setInsights] = useState('');
  const [loadingInsights, setLoadingInsights] = useState(false);

  useEffect(() => {
    const loadAnalyticsData = async () => {
      try {
        const data = await fetchAnalyticsData(startDate, endDate);
        setAnalyticsData(data);
      } catch (error) {
        console.error('Failed to load analytics data:', error);
      } finally {
        setLoading(false);
      }
    };

    loadAnalyticsData();
  }, [startDate, endDate]);

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (!analyticsData) {
    return <Typography>Error loading data.</Typography>;
  }

  const {
    total_sales,
    number_of_orders,
    average_order_value,
    top_selling_items,
  } = analyticsData;

  // Prepare data for the chart
  const chartData = top_selling_items.map((item) => ({
    name: item.menu_item__name,
    quantity: parseInt(item.total_quantity, 10),
  }));

  const handleGetInsights = async () => {
    setLoadingInsights(true);
    try {
      const insightsResponse = await getAIInsights(analyticsData);
      setInsights(insightsResponse.insights);
      setInsightsOpen(true);
    } catch (error) {
      console.error('Failed to get AI insights:', error);
    } finally {
      setLoadingInsights(false);
    }
  };

  const handleCloseInsights = () => {
    setInsightsOpen(false);
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        Dashboard
      </Typography>

      {/* Date Range Pickers */}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
          <Grid item>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
              slotProps={{ textField: { sx: { width: 150, marginRight: 2 } } }}
            />
          </Grid>
          <Grid item>
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
              slotProps={{ textField: { sx: { width: 150 } } }}
            />
          </Grid>
        </Grid>
      </LocalizationProvider>

      {/* AI Insights Button */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 2, marginTop: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleGetInsights}
          disabled={loadingInsights}
        >
          {loadingInsights ? 'Generating Insights...' : 'Get AI Insights'}
        </Button>
      </Box>

      <Grid container spacing={3} sx={{ marginTop: 2 }}>
        {/* KPI Cards */}
        <Grid item xs={12} md={4}>
          <Card elevation={3}>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Total Sales
              </Typography>
              <Typography variant="h5">
                €{parseFloat(total_sales).toFixed(2)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card elevation={3}>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Number of Orders
              </Typography>
              <Typography variant="h5">{number_of_orders}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card elevation={3}>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Average Order Value
              </Typography>
              <Typography variant="h5">
                €{parseFloat(average_order_value).toFixed(2)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Top-Selling Items Chart */}
        <Grid item xs={12}>
          <Card elevation={3}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Top-Selling Items
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={chartData}>
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Bar
                    dataKey="quantity"
                    fill={theme.palette.primary.main}
                  />
                </BarChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* AI Insights Dialog */}
      <Dialog open={insightsOpen} onClose={handleCloseInsights} fullWidth maxWidth="sm">
        <DialogTitle>AI-Generated Insights</DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
            {insights}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseInsights} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AnalyticsHome;
