// POSTransactions
import React, { useEffect, useState, useCallback } from 'react';
import { usePOS } from '../../contexts/POSContext';
import { Typography, CircularProgress, List, ListItem, ListItemText, Divider, Button } from '@mui/material';
import styled from 'styled-components';

const TransactionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: #121212;
  color: #ffffff;
  height: 100vh;
`;

const ListContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  margin-right: 20px;
  background-color: var(--color-dark);
  border-radius: 10px;
  padding: 10px;
  height: 100vh;
`;

const ViewContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 3;
  background-color: var(--color-darker);
  border-radius: 10px;
  height: 100vh;
`;

const DetailsContainer = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  background-color: var(--color-dark);
  border-top-left-radius: 10px;
  padding: 10px;
`;

const ActionsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-left: 1px solid #333;
  border-top-right-radius: 10px;
  background-color: #1e1e1e;
`;

const StyledListItem = styled(ListItem)`
  border: 1px solid #444;
  margin-bottom: 10px;
  &:hover {
    background-color: #333;
  }
  &.Mui-selected {
    background-color: #ffffff;
    color: #000;
  }
`;

const StyledDivider = styled(Divider)`
  background-color: #444;
`;

const ReceiptContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin-left: 50px;
  margin-right: 50px;
  background-color: white;
  border-radius: 8px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
`;

const Column = styled.div`
  flex: 1;
  text-align: ${(props) => props.align || 'left'};
`;

const POSTransactions = () => {
  const { restaurantId, receipts, loadReceipts, loadPaymentDetails, paymentDetails, loading, error } = usePOS();
  const [selectedReceipt, setSelectedReceipt] = useState(null);

  useEffect(() => {
    if (restaurantId) {
      loadReceipts(restaurantId);
    }
  }, [restaurantId, loadReceipts]);

  useEffect(() => {
    if (receipts.length > 0) {
      const mostRecentReceipt = receipts[receipts.length - 1]; // Select the most recent receipt
      setSelectedReceipt(mostRecentReceipt);
      loadPaymentDetails(mostRecentReceipt.order);
    }
  }, [receipts, loadPaymentDetails]);

  const handleReceiptClick = useCallback((receipt) => {
    setSelectedReceipt(receipt);
    loadPaymentDetails(receipt.order); // Fetch payment details for the selected receipt
  }, [loadPaymentDetails]);

  const renderReceipt = (receipt) => (
    <ReceiptContainer>
      <Typography style={{ marginBottom: '5px' }} variant="h3" align="center">{receipt.restaurant_name}</Typography>
      <Typography variant="body1" align="center">{receipt.restaurant_address}</Typography>
      <Typography style={{ marginBottom: '15px' }} variant="body1" align="center">VAT number {receipt.restaurant_vat_number}</Typography>
      <Row>
        <Column align="left"><Typography variant="body1"><b>Description</b></Typography></Column>
        <Column align="center"><Typography variant="body1"><b>VAT</b></Typography></Column>
        <Column align="right"><Typography variant="body1"><b>Price (€)</b></Typography></Column>
      </Row>
      {paymentDetails && paymentDetails.items && paymentDetails.items.map((item, index) => (
        <Row key={index}>
          <Column align="left"><Typography variant="body1">{item.menu_item || item.combo} x{item.quantity}</Typography></Column>
          <Column align="center"><Typography variant="body1">{item.vat_percentage}%</Typography></Column>
          <Column align="right"><Typography variant="body1">€{item.price.toFixed(2)}</Typography></Column>
        </Row>
      ))}
      <Row>
        <Column style={{ marginTop: '15px' }} align="left"><Typography variant="h5"><b>Grand Total</b></Typography></Column>
        <Column style={{ marginTop: '15px' }} align="right"><Typography variant="h5">€{parseFloat(receipt.total_amount).toFixed(2)}</Typography></Column>
      </Row>
      <Row>
        <Column style={{ marginBottom: '15px' }} align="left"><Typography variant="h6">of which VAT</Typography></Column>
        <Column style={{ marginBottom: '15px' }} align="right"><Typography variant="h6">€{parseFloat(receipt.total_vat).toFixed(2)}</Typography></Column>
      </Row>
      {parseFloat(receipt.digital_paid_amount) > 0 && (
        <Row>
          <Column align="left"><Typography variant="body1">Digital Paid Amount</Typography></Column>
          <Column align="right"><Typography variant="body1">€{parseFloat(receipt.digital_paid_amount).toFixed(2)}</Typography></Column>
        </Row>
      )}
      {parseFloat(receipt.cash_paid_amount) > 0 && (
        <Row>
          <Column align="left"><Typography variant="body1">Cash Paid Amount</Typography></Column>
          <Column align="right"><Typography variant="body1">€{parseFloat(receipt.cash_paid_amount).toFixed(2)}</Typography></Column>
        </Row>
      )}
      {parseFloat(receipt.not_collected_amount) > 0 && (
        <Row>
          <Column align="left"><Typography variant="body1">Not Collected Amount</Typography></Column>
          <Column align="right"><Typography variant="body1">€{parseFloat(receipt.not_collected_amount).toFixed(2)}</Typography></Column>
        </Row>
      )}
      {parseFloat(receipt.change) > 0 && (
        <Row>
          <Column align="left"><Typography variant="body1">Change</Typography></Column>
          <Column align="right"><Typography variant="body1">€{parseFloat(receipt.change).toFixed(2)}</Typography></Column>
        </Row>
      )}
      {parseFloat(receipt.tip) > 0 && (
        <Row>
          <Column align="left"><Typography variant="body1">Tip</Typography></Column>
          <Column align="right"><Typography variant="body1">€{parseFloat(receipt.tip).toFixed(2)}</Typography></Column>
        </Row>
      )}
      {parseFloat(receipt.rounding) > 0 && (
        <Row>
          <Column align="left"><Typography variant="body1">Rounding</Typography></Column>
          <Column align="right"><Typography variant="body1">€{parseFloat(receipt.rounding).toFixed(2)}</Typography></Column>
        </Row>
      )}
    </ReceiptContainer>
  );

  return (
    <TransactionsContainer>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <ListContainer>
          {loading ? (
            <CircularProgress />
          ) : error ? (
            <Typography color="error">{error}</Typography>
          ) : (
            <List>
              {receipts.slice().reverse().map(receipt => (
                <React.Fragment key={receipt.id}>
                  <StyledListItem 
                    button 
                    onClick={() => handleReceiptClick(receipt)}
                    selected={selectedReceipt && selectedReceipt.id === receipt.id}
                  >
                    <ListItemText
                      primary={`Receipt ID: ${receipt.id}`}
                      secondary={`Amount: €${parseFloat(receipt.total_amount).toFixed(2)}`}
                      primaryTypographyProps={{ style: { color: selectedReceipt && selectedReceipt.id === receipt.id ? 'white' : '#ffffff' } }}
                      secondaryTypographyProps={{ style: { color: selectedReceipt && selectedReceipt.id === receipt.id ? 'white' : '#aaaaaa' } }}
                    />
                  </StyledListItem>
                  <StyledDivider />
                </React.Fragment>
              ))}
            </List>
          )}
        </ListContainer>
        <ViewContainer>
          <DetailsContainer>
            {selectedReceipt && renderReceipt(selectedReceipt)}
          </DetailsContainer>
          <ActionsContainer>
            <Button variant="contained" color="primary" style={{ marginBottom: '10px' }}>
              Print
            </Button>
            <Button variant="contained" color="secondary" style={{ marginBottom: '10px' }}>
              Send
            </Button>
            <Button variant="contained" style={{ marginBottom: '10px' }}>
              Void
            </Button>
          </ActionsContainer>
        </ViewContainer>
      </div>
    </TransactionsContainer>
  );
};

export default POSTransactions;
