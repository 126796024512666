// RestaurantOverview v1.10
import React, { useState, useEffect } from 'react';
import { useRestaurant } from '../../contexts/RestaurantContext';
import { Dialog, DialogContent, CircularProgress, Box, Button, Typography } from '@mui/material';
import { deleteRestaurant } from '../../services/apiService';
import { updateUserDefaultRestaurant } from '../../services/authApiService';
import SidebarWidget from './SidebarWidget';
import ConfirmationModal from './ConfirmationModal';
import RestaurantWizard from './restaurant/RestaurantWizard';
import RestaurantHeader from './restaurant/RestaurantHeader';
import AreaSwiper from './restaurant/AreaSwiper';

const RestaurantOverview = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [restaurantToDelete, setRestaurantToDelete] = useState(null);
  const [showSetupWizard, setShowSetupWizard] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [sidebarContext, setSidebarContext] = useState('');
  const [currentAreaName, setCurrentAreaName] = useState('');
  const [loading, setLoading] = useState(true);

  const {
    userRestaurants, checkForFloorMap, loadUserRestaurants,
    selectedRestaurant, setSelectedRestaurant, areas
  } = useRestaurant();

  const restaurantOptions = userRestaurants.map(restaurant => ({
    value: restaurant.id,
    label: restaurant.name
  }));

  useEffect(() => {
    const loadRestaurantsAndCheckAreas = async () => {
      setLoading(true);
      await loadUserRestaurants();
      setLoading(false);
      if (selectedRestaurant) {
        await checkForFloorMap(selectedRestaurant);
      }
    };
    loadRestaurantsAndCheckAreas();
  }, [loadUserRestaurants, selectedRestaurant, checkForFloorMap]);

  const handleRestaurantChange = (selectedOption) => {
    setSelectedRestaurant(selectedOption.value);
  };

  const handleToggleDefault = async () => {
    if (selectedRestaurant) {
      await updateUserDefaultRestaurant(selectedRestaurant);
    }
  };

  const handleNewRestaurantCreated = async (newRestaurantId) => {
    setSelectedRestaurant(newRestaurantId);
    setShowSetupWizard(false);
    // Reload restaurants and check areas for the new restaurant
    await loadUserRestaurants();
    await checkForFloorMap(newRestaurantId);
  };

  const openSidebarForEditing = (restaurantId) => {
    setSidebarContext('editRestaurant');
    setShowSidebar(true);
  };

  const openSidebarForAreaCreation = () => {
    setSidebarContext('createArea');
    setShowSidebar(true);
  };

  const toggleDeleteModal = (restaurantId) => {
    setRestaurantToDelete(restaurantId);
    setShowDeleteModal(!showDeleteModal);
  };

  const handleDeleteRestaurant = async () => {
    if (!restaurantToDelete) return;

    try {
      await deleteRestaurant(restaurantToDelete);
      setRestaurantToDelete(null);
      setShowDeleteModal(false);
      loadUserRestaurants();
    } catch (error) {
      console.error('Error deleting restaurant:', error);
    }
  };

  useEffect(() => {
    if (selectedRestaurant) {
      const checkFloorMap = async () => {
        try {
          await checkForFloorMap(selectedRestaurant);
        } catch (error) {
          console.error('Error fetching areas for restaurant:', error);
        }
      };

      checkFloorMap();
    }
  }, [selectedRestaurant, checkForFloorMap]);

  useEffect(() => {
    if (areas.length > 0) {
      setCurrentAreaName(areas[0].name);
    }
  }, [areas]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress size={60} color="secondary" />
      </Box>
    );
  }

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress size={60} color="secondary" />
      </Box>
    );
  }

  if (userRestaurants.length === 0) {
    // Display "Get Started" UI if no restaurants are available
    return (
      <Box display="flex" flexDirection="column" alignItems="center" p={3} textAlign="center">
        <Typography variant="h4" gutterBottom>Let's get started setting up your restaurant!</Typography>
        <Typography variant="subtitle1">Click the button below. It should take about 2 minutes.</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setShowSetupWizard(true)}
          sx={{ mt: 2 }}
        >
          Get Started
        </Button>

        {/* Setup Wizard Dialog */}
        <Dialog
          open={showSetupWizard}
          onClose={() => setShowSetupWizard(false)}
          fullWidth
          maxWidth="md"
          PaperProps={{
            style: { height: '85%', display: 'flex', flexDirection: 'column', justifyContent: 'center' },
          }}
        >
          <DialogContent>
            <RestaurantWizard onComplete={handleNewRestaurantCreated} />
          </DialogContent>
        </Dialog>
      </Box>
    );
  }

  return (
    <>
      <SidebarWidget
        showSidebar={showSidebar}
        closeSidebar={() => setShowSidebar(false)}
        sidebarContext={sidebarContext}
      />

      <RestaurantHeader
        restaurantOptions={restaurantOptions}
        handleRestaurantChange={handleRestaurantChange}
        setShowSetupWizard={setShowSetupWizard}
        openSidebarForAreaCreation={openSidebarForAreaCreation}
        openSidebarForEditing={openSidebarForEditing}
        toggleDeleteModal={toggleDeleteModal}
        handleToggleDefault={handleToggleDefault}
      />

      {showDeleteModal && (
        <ConfirmationModal
          onClose={() => toggleDeleteModal(null)}
          onConfirm={handleDeleteRestaurant}
        />
      )}

      <Box padding={3}>
        {areas.length === 0 && (
          <Box textAlign="center" py={2}>
            <Typography variant="h5" gutterBottom>Create a floor map for your restaurant</Typography>
            <Typography variant="body1">The floor map will include areas (indoor, outdoor, etc) and tables</Typography>
            <Button
              variant="outlined"
              color="primary"
              onClick={openSidebarForAreaCreation}
              sx={{ mt: 2 }}
            >
              Add an area with tables
            </Button>
          </Box>
        )}

        {selectedRestaurant && areas.length > 0 && (
          <AreaSwiper
            currentAreaName={currentAreaName}
            setCurrentAreaName={setCurrentAreaName}
          />
        )}
      </Box>
    </>
  );
};

export default RestaurantOverview;