// POSReservations v1.35
import React, { useEffect, useState, useCallback } from 'react';
import { usePOS } from '../../contexts/POSContext';
import styled from 'styled-components';
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  Paper,
  TextField,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
} from '@mui/material';
import {
  fetchTablesForRestaurant, fetchReservations, createReservation, updateReservation, deleteReservation
} from '../../services/posApiService';
import { format, parse, isAfter } from 'date-fns';
import ReservationDialog from './reservation/ReservationDialog';
import GuestWalkIn from './reservation/GuestWalkIn';
import dayjs from 'dayjs';

const ReservationsContainer = styled.div`
  padding: 10px;
  background-color: #121212;
  color: #ffffff;
  min-height: 100vh;
`;

const HeaderContainer = styled.div`
  margin-bottom: 20px;
`;

const StyledPaper = styled(Paper)`
  padding: 20px;
  margin-bottom: 20px;
`;

// Helper function to get the appropriate color for each status
const getStatusColor = (status) => {
  switch (status) {
    case 'pending':
      return 'warning';
    case 'confirmed':
      return 'primary';
    case 'seated':
      return 'success';
    case 'cancelled':
      return 'error';
    case 'completed':
      return 'default';
    default:
      return 'default';
  }
};

const POSReservations = () => {
  const { restaurantId } = usePOS();
  const [reservations, setReservations] = useState([]);
  const [allReservations, setAllReservations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedReservation, setSelectedReservation] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [reservationToDelete, setReservationToDelete] = useState(null);
  const [walkInOpen, setWalkInOpen] = useState(false); // Control Walk-in Dialog
  const [walkInGuestCount, setWalkInGuestCount] = useState(null); // Track guest count
  const [tables, setTables] = useState([]);
  const [formValues, setFormValues] = useState({
    customer_name: '',
    customer_email: '',
    customer_phone: '',
    number_of_guests: '',
    reservation_date: '',
    reservation_time: '',
    special_requests: '',
    table: '',
  });
  const [searchQuery, setSearchQuery] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [formErrors, setFormErrors] = useState({});
  const [showAll, setShowAll] = useState(false);

  // Fetch reservations
  const loadReservations = useCallback(async () => {
    setLoading(true);
    try {
      const data = await fetchReservations();
      setAllReservations(data); // Store all reservations

      const now = new Date();
      const filteredData = data.filter((reservation) => {
        const reservationDateTime = new Date(`${reservation.reservation_date}T${reservation.reservation_time}`);
        const isFutureReservation = isAfter(reservationDateTime, now);
        if (!isFutureReservation) return false;

        if (showAll) {
          return true;
        } else {
          const excludedStatuses = ['seated', 'cancelled'];
          return !excludedStatuses.includes(reservation.status);
        }
      });

      setReservations(filteredData);
    } catch (error) {
      console.error('Error fetching reservations:', error);
    } finally {
      setLoading(false);
    }
  }, [showAll]);

  // Fetch tables
  const loadTables = useCallback(async () => {
    try {
      const tablesData = await fetchTablesForRestaurant(restaurantId);
      setTables(tablesData);
    } catch (error) {
      console.error('Error fetching tables:', error);
    }
  }, [restaurantId]);

  useEffect(() => {
    loadReservations();
    loadTables();
  }, [loadReservations, loadTables]);

  const handleOpenDialog = (reservation = null) => {
    if (reservation) {
      setSelectedReservation(reservation);
      setFormValues(reservation);
    } else {
      setSelectedReservation(null);
      setFormValues({
        customer_name: '',
        customer_email: '',
        customer_phone: '',
        number_of_guests: '',
        reservation_date: '',
        reservation_time: '',
        special_requests: '',
        table: '',
      });
    }
    setFormErrors({});
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedReservation(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDateChange = (newDate) => {
    setFormValues((prevState) => ({
      ...prevState,
      reservation_date: dayjs(newDate).format('YYYY-MM-DD'),
    }));
  };

  const handleTimeChange = (newTime) => {
    setFormValues((prevState) => ({
      ...prevState,
      reservation_time: dayjs(newTime).format('HH:mm'),
    }));
  };

  const validateForm = () => {
    const errors = {};
    if (!formValues.customer_name) errors.customer_name = 'Customer name is required';
    if (!formValues.reservation_date) errors.reservation_date = 'Reservation date is required';
    if (!formValues.reservation_time) errors.reservation_time = 'Reservation time is required';
    if (!formValues.number_of_guests) errors.number_of_guests = 'Number of guests is required';
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSaveReservation = async () => {
    if (!validateForm()) {
      setAlertMessage('Please fix the errors in the form.');
      setAlertSeverity('error');
      setAlertOpen(true);
      return;
    }
    try {
      if (selectedReservation) {
        // Update reservation
        await updateReservation(selectedReservation.id, formValues);
        setAlertMessage('Reservation updated successfully.');
      } else {
        // Create reservation
        await createReservation({ ...formValues, restaurant: restaurantId, status: 'confirmed' });
        setAlertMessage('Reservation created successfully.');
      }
      setAlertSeverity('success');
      setAlertOpen(true);
      loadReservations();
      handleCloseDialog();
    } catch (error) {
      console.error('Error saving reservation:', error);
      setAlertMessage('Error saving reservation. Please try again.');
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleWalkInOpen = () => {
    setWalkInGuestCount(2); // Default to 2 guests; could be modified to prompt user
    setWalkInOpen(true);
  };

  const handleWalkInClose = () => {
    setWalkInOpen(false);
    setWalkInGuestCount(null);
  };

  const handleWalkInConfirm = () => {
    loadReservations(); // Reload reservations on confirmation
    setAlertMessage("Walk-in reservation confirmed.");
    setAlertSeverity("success");
    setAlertOpen(true);
    handleWalkInClose();
  };

  const handleDeleteReservation = (reservationId) => {
    setReservationToDelete(reservationId);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteReservation(reservationToDelete);
      setAlertMessage('Reservation cancelled successfully.');
      setAlertSeverity('success');
      setAlertOpen(true);
      loadReservations();
      setOpenConfirmDialog(false);
      setReservationToDelete(null);
    } catch (error) {
      console.error('Error deleting reservation:', error);
      setAlertMessage('Error cancelling reservation. Please try again.');
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
    setReservationToDelete(null);
  };

  const handleCheckIn = async (reservationId) => {
    try {
      await updateReservation(reservationId, { status: 'seated' });
      setAlertMessage('Guest checked in successfully.');
      setAlertSeverity('success');
      setAlertOpen(true);
      loadReservations();
    } catch (error) {
      console.error('Error checking in reservation:', error);
      setAlertMessage('Error checking in. Please try again.');
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = allReservations.filter((reservation) => {
      const matchesQuery =
        reservation.customer_name.toLowerCase().includes(query) ||
        reservation.table.toString().includes(query) ||
        reservation.reservation_date.includes(query);

      const isFutureReservation = isAfter(new Date(reservation.reservation_date), new Date());

      if (!isFutureReservation) return false;

      if (showAll) {
        return matchesQuery;
      } else {
        const excludedStatuses = ['seated', 'cancelled'];
        return matchesQuery && !excludedStatuses.includes(reservation.status);
      }
    });

    setReservations(filtered);
  };

  // Function to get styles based on reservation status
  const getReservationStyles = (status) => {
    switch (status) {
      case 'cancelled':
        return { textDecoration: 'line-through' };
      default:
        return {};
    }
  };

  // Group reservations by date
  const reservationsByDate = reservations.reduce((acc, reservation) => {
    const date = reservation.reservation_date;
    if (!acc[date]) acc[date] = [];
    acc[date].push(reservation);
    return acc;
  }, {});

  return (
    <ReservationsContainer>
      <HeaderContainer>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: '10px',
            flexDirection: { xs: 'column', sm: 'row' }, // Responsive
          }}
        >
          {/* Search box and Show All toggle */}
          <Box display="flex" alignItems="center" sx={{ flexGrow: 1 }}>
            <TextField
              label="Search reservation..."
              variant="filled"
              color="primary"
              value={searchQuery}
              onChange={handleSearch}
              size="small"
              sx={{ marginLeft: '10px', backgroundColor: '#fff', borderRadius: '10px', width: { xs: '100%', sm: 'auto' } }} // Responsive width
            />
            <Button
              variant="text"
              color="secondary"
              onClick={() => setShowAll(!showAll)}
              sx={{ marginTop: { xs: '10px', sm: '0' }, marginLeft: '20px' }} // Margin for mobile
            >
              {showAll ? 'Show less' : 'Show all'}
            </Button>
          </Box>

          {/* Walk-in and Add Reservation Buttons */}
          <Button variant="contained" color="primary" sx={{ marginRight: 2 }} onClick={handleWalkInOpen}>
            Walk-in
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => handleOpenDialog()}
            sx={{ marginTop: { xs: '10px', sm: '0' }, marginRight: '10px', fontWeight: '600' }}
          >
            Add Reservation
          </Button>
        </Box>
      </HeaderContainer>

      {/* GuestWalkIn Dialog */}
      <GuestWalkIn
        open={walkInOpen}
        onClose={handleWalkInClose}
        onConfirmWalkIn={handleWalkInConfirm}
        guestCount={walkInGuestCount}
        restaurantId={restaurantId}
      />

      {/* Reservations List */}
      {loading ? (
        <CircularProgress />
      ) : reservations.length === 0 ? (
        <Typography variant="h4" sx={{ color: 'var(--color-light)', textAlign: 'center', mt: '200px' }}>
          You currently have no reservations.<br/>
        </Typography>
      ) : (
        Object.keys(reservationsByDate)
          .sort()
          .map((date) => (
            <StyledPaper key={date}>
              <Typography variant="h6">{format(new Date(date), 'dd MMMM yyyy')}</Typography>
              {reservationsByDate[date].map((reservation) => (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  p={2}
                  m={2}
                  key={reservation.id}
                  sx={{
                    border: '1px solid var(--color-lightgrey)',
                    borderRadius: '5px',
                    flexDirection: { xs: 'column', sm: 'row' }, // Responsive
                  }}
                >
                  <Box display="flex" flexDirection="column">
                    <Typography variant="body1" style={getReservationStyles(reservation.status)}>
                      {`Table ${reservation.table} - ${format(parse(reservation.reservation_time, 'HH:mm:ss', new Date()), 'HH:mm')}`}
                    </Typography>
                    <Typography variant="body2" style={getReservationStyles(reservation.status)} sx={{ fontWeight: 'bold' }}>
                      {`Name: ${reservation.customer_name}`}
                    </Typography>
                    <Typography variant="body2" style={getReservationStyles(reservation.status)}>
                      {`Guests: ${reservation.number_of_guests}, Special Requests: ${reservation.special_requests}`}
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', mt: { xs: 2, sm: 0 } }}> {/* Responsive button spacing */}
                    <Chip
                      label={reservation.status}                 // Display reservation status
                      color={getStatusColor(reservation.status)}  // Color based on status
                      sx={{ marginRight: 3 }}
                    />    
                    {/* Buttons for check-in, edit, delete */}
                    {!['cancelled', 'seated'].includes(reservation.status) && (
                      <>
                        <Button
                          variant="outlined"
                          color="success"
                          onClick={() => handleCheckIn(reservation.id)}
                          size="small"
                          sx={{ marginRight: '10px' }}
                        >
                          Check-in
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => handleOpenDialog(reservation)}
                          size="small"
                          sx={{ marginRight: '10px' }}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => handleDeleteReservation(reservation.id)}
                          size="small"
                        >
                          Cancel
                        </Button>
                      </>
                    )}
                  </Box>
                </Box>
              ))}
            </StyledPaper>
          ))
      )}

      {/* Reservation Dialog */}
      <ReservationDialog
        open={openDialog}
        onClose={handleCloseDialog}
        onSave={handleSaveReservation}
        formValues={formValues}
        handleInputChange={handleInputChange}
        handleDateChange={handleDateChange}
        handleTimeChange={handleTimeChange}
        selectedReservation={selectedReservation}
        formErrors={formErrors}
        tables={tables}
      />

      {/* Confirmation Dialog */}
      <Dialog open={openConfirmDialog} onClose={handleCancelDelete}>
        <DialogTitle>Confirm Cancellation</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to cancel this reservation?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete}>No</Button>
          <Button onClick={handleConfirmDelete} color="secondary">
            Yes, Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for Alerts */}
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={() => setAlertOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setAlertOpen(false)} severity={alertSeverity} sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </ReservationsContainer>
  );
};

export default POSReservations;
