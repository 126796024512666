import React, { createContext, useContext, useState, useCallback } from 'react';
import {
  fetchAreasForRestaurant, fetchMenusForRestaurant, fetchMenuCategories, fetchMenuItemsAndCombosByCategory,
  fetchTablesForRestaurant, fetchOrderPaymentDetails, fetchReceipts, createReceipt } from '../services/posApiService';

const POSContext = createContext();

export const usePOS = () => {
  return useContext(POSContext);
};

export const POSProvider = ({ children }) => {
  const [restaurantId, setRestaurantId] = useState(() => localStorage.getItem('restaurantId')); // lazy initialization
  const [areas, setAreas] = useState([]);
  const [currentAreaName, setCurrentAreaName] = useState('');
  const [tables, setTables] = useState([]);
  const [selectedTable, setSelectedTable] = useState(null);
  const [menus, setMenus] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [menuItems, setMenuItems] = useState([]);
  const [combos, setCombos] = useState([]);
  const [orderItems, setOrderItems] = useState({ orderId: null, items: [], customOrder: false, });
  const [currentItem, setCurrentItem] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [orderEmpty, setOrderEmpty] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState('digital');
  const [receipts, setReceipts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [alert, setAlert] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  const updateRestaurantId = useCallback((id) => {
    setRestaurantId(id);
    // Save the restaurantId to localStorage
    localStorage.setItem('restaurantId', id);
  }, []);

  const loadAreas = useCallback(async () => {
    if (!restaurantId) return;
    setError('');
    setLoading(true);
    try {
      const fetchedAreas = await fetchAreasForRestaurant(restaurantId);
      setAreas(fetchedAreas);
      // Set the name of the first area as the current area name initially
      if (fetchedAreas.length > 0) {
        setCurrentAreaName(fetchedAreas[0].name);
      }
    } catch (err) {
      console.error('Error fetching areas:', err);
      setError('Failed to load areas.');
    } finally {
      setLoading(false);
    }
  }, [restaurantId]);

  const loadTables = useCallback(async () => {
    if (!restaurantId) return;
    setLoading(true);
    try {
      const tablesData = await fetchTablesForRestaurant(restaurantId);
      setTables(tablesData);
    } catch (error) {
      console.error('Error fetching tables', error);
    } finally {
      setLoading(false);
    }
  }, [restaurantId]);

  const loadMenus = useCallback(async () => {
    if (!restaurantId) return;

    setLoading(true);
    try {
      const menusData = await fetchMenusForRestaurant(restaurantId);
      setMenus(menusData);
      if (menusData.length > 0) {
        setSelectedMenu(menusData[0].id); // Select the first menu by default
      }
    } catch (error) {
      console.error('Error fetching menus:', error);
      setMenus([]);
    } finally {
      setLoading(false);
    }
  }, [restaurantId]);

  const loadCategories = useCallback(async (menuId) => {
    if (!menuId) return;

    setLoading(true);
    try {
      const categoriesData = await fetchMenuCategories(menuId);
      setCategories(categoriesData);
    } catch (error) {
      console.error('Error fetching categories:', error);
      setCategories([]);
    } finally {
      setLoading(false);
    }
  }, []);

  const loadItemsAndCombos = useCallback(async (categoryId) => {
    if (!categoryId) return;
    setLoading(true);
    try {
      const data = await fetchMenuItemsAndCombosByCategory(categoryId);
      setMenuItems(data.menu_items);
      setCombos(data.combos);
    } catch (error) {
      console.error('Error fetching items and combos:', error);
      setMenuItems([]);
      setCombos([]);
    } finally {
      setLoading(false);
    }
  }, []);

  const loadPaymentDetails = useCallback(async (orderId) => {
    if (!orderId) return;
    setLoading(true);
    try {
      const details = await fetchOrderPaymentDetails(orderId);
      setPaymentDetails(details);
    } catch (error) {
      console.error('Error fetching payment details:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const loadReceipts = useCallback(async (restaurantId) => {
    if (!restaurantId) return;
    setLoading(true);
    try {
      const data = await fetchReceipts(restaurantId);
      setReceipts(data);
    } catch (err) {
      console.error('Error fetching receipts:', err);
      setError('Failed to load receipts.');
    } finally {
      setLoading(false);
    }
  }, []);

  const createNewReceipt = useCallback(async (orderId) => {
    setLoading(true);
    try {
      const receipt = await createReceipt(orderId);
      loadReceipts(); // Refresh receipts after creating a new one
      return receipt;
    } catch (err) {
      console.error('Error creating receipt:', err);
      setError('Failed to create receipt.');
    } finally {
      setLoading(false);
    }
  }, [loadReceipts]);

  const handleTableSelect = (tableId) => {
    setSelectedTable(tableId);
  };

  const handleOrderSelect = (order) => {
    setSelectedOrder(order);
    setSelectedTable(order.table); // Ensure table is set based on the order
  };

  const showAlert = useCallback((message, type = '', field = null) => {
    setAlert({ message, type, field });
    setTimeout(() => setAlert({ message: '', type: '' }), 5000); // Clear alert after 5 seconds
  }, []);

  const clearAlert = useCallback(() => {
    setAlert({ message: '', type: '' });
  }, []);

  const openModal = (item) => {
    setCurrentItem(item);
    setModalOpen(true);
  };

  const closeModal = () => {
    setCurrentItem(null);
    setModalOpen(false);
  };

  const value = {
    restaurantId, setRestaurantId,
    areas, setAreas,
    currentAreaName, setCurrentAreaName,
    tables, setTables,
    selectedTable, setSelectedTable,
    menus, setMenus,
    selectedMenu, setSelectedMenu,
    categories, setCategories,
    selectedCategory, setSelectedCategory,
    menuItems, setMenuItems,
    combos, setCombos,
    orderItems, setOrderItems,
    currentItem, setCurrentItem,
    selectedOrder, setSelectedOrder,
    orderEmpty, setOrderEmpty,
    paymentDetails, setPaymentDetails,
    paymentMethod, setPaymentMethod,
    receipts, setReceipts,
    loading, setLoading,
    error, setError,
    alert, setAlert,
    modalOpen, setModalOpen,
    updateRestaurantId,
    loadAreas,
    loadTables,
    loadMenus,
    loadCategories,
    loadItemsAndCombos,
    loadPaymentDetails,
    loadReceipts,
    createNewReceipt,
    handleTableSelect,
    handleOrderSelect,
    showAlert,
    clearAlert,
    openModal,
    closeModal
  };

  return <POSContext.Provider value={value}>{children}</POSContext.Provider>;
};