import React from 'react';
import PaymentWidget from './PaymentWidget';

const FullBillPayment = ({ amountDue, orderId, onPaymentSuccess }) => {
  // Ensure amountDue is always a number and format it to 2 decimal places
  const formattedAmountDue = parseFloat(amountDue).toFixed(2);

  return (
    <PaymentWidget
      amountDue={formattedAmountDue}
      orderId={orderId}
      onPaymentSuccess={onPaymentSuccess}
      paymentType="pay_full_bill"
    />
  );
};

export default FullBillPayment;
