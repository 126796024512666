// Payments.jsx v1.85
import React, { useState, useEffect } from 'react';
import { loadConnectAndInitialize } from '@stripe/connect-js';
import {
  ConnectComponentsProvider,
  ConnectAccountOnboarding,
  ConnectPayments,
  ConnectPayouts,
  ConnectAccountManagement,
} from '@stripe/react-connect-js';
import { useRestaurant } from '../../contexts/RestaurantContext';
import { createConnectedAccount, createAccountSession } from '../../services/authApiService';
import {
  Box, Typography, CircularProgress, Dialog, Select, DialogTitle, DialogActions, DialogContent,
  MenuItem, Tabs, Tab, Button, FormControl, InputLabel 
} from '@mui/material';

const Payment = () => {
  const { userRestaurants, selectedRestaurant, setSelectedRestaurant, loadUserRestaurants } = useRestaurant();
  const [connectedAccountId, setConnectedAccountId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [stripeConnectInstance, setStripeConnectInstance] = useState(null);
  const [componentType, setComponentType] = useState('payments');
  const [error, setError] = useState(null);
  const [restaurantsLoaded, setRestaurantsLoaded] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [accountStatus, setAccountStatus] = useState(null);
  const [onboardingDialogOpen, setOnboardingDialogOpen] = useState(false);

  // Load user restaurants on component mount
  useEffect(() => {
    const fetchRestaurants = async () => {
      setLoading(true);
      await loadUserRestaurants();
      setRestaurantsLoaded(true);
      setLoading(false);
    };
    fetchRestaurants();
  }, [loadUserRestaurants]);

  // Ensure a default restaurant is selected when restaurants are loaded
  useEffect(() => {
    if (restaurantsLoaded && userRestaurants.length > 0 && !selectedRestaurant) {
      setSelectedRestaurant(userRestaurants[0].id);
    }
  }, [restaurantsLoaded, userRestaurants, selectedRestaurant, setSelectedRestaurant]);

  // Fetch or create the connected account and get account status once the selected restaurant is set
  useEffect(() => {
    if (restaurantsLoaded && selectedRestaurant) {
      const initializeAccount = async () => {
        try {
          setLoading(true);
          const accountResponse = await createConnectedAccount(selectedRestaurant);
          if (accountResponse.error) {
            console.error('Error creating connected account:', accountResponse.error);
            setError('Error creating connected account');
          } else {
            setConnectedAccountId(accountResponse.account_id);
          }

          // Fetch and set account status in the same useEffect
          const sessionResponse = await createAccountSession(selectedRestaurant);
          setAccountStatus(sessionResponse.account_status);

          setLoading(false);
        } catch (err) {
          console.error('Error initializing account or fetching status:', err);
          setError('Failed to initialize account or fetch status');
          setLoading(false);
        }
      };
      initializeAccount();
    }
  }, [selectedRestaurant, restaurantsLoaded]);

  // Initialize the Stripe Connect instance when all necessary data is available
  useEffect(() => {
    if (!connectedAccountId || !selectedRestaurant || stripeConnectInstance || !accountStatus) return;

    const initializeStripeConnect = async () => {
      try {
        const stripeInstance = await loadConnectAndInitialize({
          publishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
          fetchClientSecret: async () => {
            const sessionResponse = await createAccountSession(selectedRestaurant);
            return sessionResponse.client_secret;
          },
          appearance: {
            overlays: 'dialog',
            variables: {
              fontFamily: 'Source Sans Pro, sans-serif',
              colorPrimary: '#3e3eff',
              colorBackground: '#FFFFFF',
              buttonPrimaryColorBackground: '#3e3eff',
              buttonPrimaryColorText: '#FFFFFF',
              buttonPrimaryColorBorder: '#3e3eff',
              buttonSecondaryColorBackground: '#EBEEF1',
              buttonSecondaryColorText: '#3e3eff',
              buttonSecondaryColorBorder: '#EBEEF1',
              buttonBorderRadius: '5px',
            },
          },
        });

        setStripeConnectInstance(stripeInstance);

        // Automatically open the onboarding dialog if the account is not fully enabled
        if (!accountStatus.charges_enabled || !accountStatus.payouts_enabled) {
          setOnboardingDialogOpen(true);
        }
      } catch (error) {
        setError('Failed to initialize Stripe Connect');
      }
    };

    initializeStripeConnect();
  }, [connectedAccountId, selectedRestaurant, stripeConnectInstance, accountStatus]);

  // Handle restaurant selection change
  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleRestaurantChange = (event) => {
    const selectedId = event.target.value;
    setSelectedRestaurant(selectedId);
    setStripeConnectInstance(null); // Reset instance when changing restaurant
    setAccountStatus(null);
    setDialogOpen(false);
  };

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setComponentType(newValue);
  };

  if (loading || !accountStatus || !stripeConnectInstance) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh" flexDirection="column">
        <CircularProgress />
        <Typography variant="h6" style={{ marginTop: '20px' }}>
          Setting up your Payments account...
        </Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box p={3}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  const isAccountEnabled = accountStatus.charges_enabled && accountStatus.payouts_enabled;

  return (
    <Box>
      {/* Payments Header */}
      <Box p={2} mb={4} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Box display="flex" justifyContent="center" mb={1}>
          {isAccountEnabled ? (
            <Tabs value={componentType} onChange={handleTabChange} aria-label="Stripe Components Tabs">
              <Tab label="Payments" value="payments" sx={{ fontSize: '16px' }} />
              <Tab label="Payouts" value="payouts" sx={{ fontSize: '16px' }} />
              <Tab label="Account" value="account_management" sx={{ fontSize: '16px' }} />
            </Tabs>
          ) : (
            <Box /> // Placeholder when tabs are hidden
          )}
        </Box>
        <Box display="flex" alignItems="center" justifyContent="right" mr={2} flexDirection="column">
          <Typography variant="h6">
            {userRestaurants.find((restaurant) => restaurant.id === selectedRestaurant)?.name || 'No restaurant selected'}
          </Typography>
          <Typography
            variant="body2"
            color="primary"
            sx={{ textAlign: 'right', width: '100%', textDecoration: 'underline', cursor: 'pointer' }}
            onClick={handleDialogOpen}
          >
            Change
          </Typography>
        </Box>
      </Box>

      {/* Dialog for selecting a restaurant */}
      <Dialog open={dialogOpen} onClose={handleDialogClose} fullWidth maxWidth="sm">
        <DialogTitle>Select a Restaurant</DialogTitle>
        <DialogContent>
          <FormControl sx={{ marginTop: '5px' }} variant="outlined" fullWidth>
            <InputLabel id="restaurant-select-label">Restaurant</InputLabel>
            <Select labelId="restaurant-select-label" value={selectedRestaurant || ''} onChange={handleRestaurantChange} label="Restaurant">
              {userRestaurants.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Render the appropriate Stripe component */}
      <Box flexGrow={1} display="flex" justifyContent="center" alignItems="center" overflow="hidden" m={'0 50px 50px 50px'}>
        <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
          {isAccountEnabled && componentType === 'payments' && <ConnectPayments />}
          {isAccountEnabled && componentType === 'payouts' && <ConnectPayouts />}
          {isAccountEnabled && componentType === 'account_management' && <ConnectAccountManagement />}
        </ConnectComponentsProvider>
      </Box>

      {/* Onboarding Dialog */}
      <Dialog open={onboardingDialogOpen} onClose={() => setOnboardingDialogOpen(false)} fullWidth maxWidth="md">
        <DialogContent>
          <Box height="100vh">
            <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
              <ConnectAccountOnboarding />
            </ConnectComponentsProvider>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOnboardingDialogOpen(false)} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Payment;
