import React, { useState } from 'react';
import DashboardHeader from './DashboardHeader';
import SideNavBar from './SideNavBar';
import MainContent from './MainContent';
import { Drawer } from '@mui/material';
import styled from 'styled-components';
import useMediaQuery from '@mui/material/useMediaQuery';

const PageContainer = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-grow: 1;
  overflow: hidden;
`;

const SidebarWrapper = styled.div`
  height: 100vh;
  position: sticky;
  top: 0;
  flex-shrink: 0;
`;

const MainContentWrapper = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`;

const Dashboard = () => {
  const [activeNav, setActiveNav] = useState('');
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [isSettingsDrawerOpen, setSettingsDrawerOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width: 960px)');

  const toggleDrawer = () => setDrawerOpen(!isDrawerOpen);
  const closeDrawer = () => setDrawerOpen(false); // Add a close drawer function
  const toggleSettingsDrawer = () => setSettingsDrawerOpen(!isSettingsDrawerOpen);

  return (
    <PageContainer>
      <DashboardHeader toggleDrawer={toggleDrawer} toggleSettingsDrawer={toggleSettingsDrawer} />
      <ContentContainer>
        {!isMobile && (
          <SidebarWrapper>
            <SideNavBar setActiveNav={setActiveNav} activeNav={activeNav} />
          </SidebarWrapper>
        )}
        <MainContentWrapper>
          <MainContent />
        </MainContentWrapper>
      </ContentContainer>
      {/* Mobile Drawer for SideNavBar */}
      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={closeDrawer}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <SideNavBar setActiveNav={setActiveNav} activeNav={activeNav} closeDrawer={closeDrawer} /> {/* Pass closeDrawer as a prop */}
      </Drawer>
      <Drawer
        anchor="right"
        open={isSettingsDrawerOpen}
        onClose={toggleSettingsDrawer}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        PaperProps={{
          style: { width: '50%' },
        }}
      >
        <div style={{ width: '100%', height: '100%' }}>
          {/* Settings component will go here */}
        </div>
      </Drawer>
    </PageContainer>
  );
};

export default Dashboard;
