import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import {
  FoodBankRounded, RamenDiningRounded, AppShortcutRounded, Groups2Rounded,
  Insights, AddCard, ReceiptLong, LoyaltyRounded, ThumbsUpDownRounded,
} from '@mui/icons-material';
import { Typography, useTheme } from '@mui/material';

const SideNavBarContainer = styled.nav`
  display: flex;
  flex-direction: column;
  width: 220px;
  height: 100vh;           /* Ensure it takes the full height of the viewport */
  position: sticky;        /* Make it sticky */
  top: 0;                  /* Stick to the top of the viewport */
  border-right: 1px solid #f0f0f0;
  padding-top: 35px;
  color: var(--color-black);
  z-index: 999;
  background-color: white; /* Ensure the background color is set */
  overflow-y: auto;        /* Add scroll for long sidebars */
`;

const NavList = styled.ul`
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const NavItem = styled.li`
  margin-left: 5%; // icons margin
  width: 95%; // compensate width
`;

const NavLinkStyled = styled(NavLink)`
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 10px;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const NavItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 20px;
  color: inherit;
`;

const SideNavBar = ({ setActiveNav, activeNav, closeDrawer }) => {
  const theme = useTheme();
  const location = useLocation();

  const isActive = (path) => location.pathname === path;

  const handleNavClick = (navName) => {
    setActiveNav(navName);
    if (closeDrawer) closeDrawer(); // Only call closeDrawer if it exists
  };

  return (
    <SideNavBarContainer>
      <NavList>
        <NavItem onClick={() => handleNavClick('my-restaurants')}>
          <NavLinkStyled to="/dashboard/my-restaurants" className="nav-link">
            <NavItemContainer className="nav-item-container">
              <FoodBankRounded
                className="icon"
                style={{ color: isActive('/dashboard/my-restaurants') || activeNav === 'my-restaurants' ? theme.palette.secondary.main : '#333' }}
              />
              <Typography
                variant="body1"
                style={{
                  marginLeft: '20px',
                  color: isActive('/dashboard/my-restaurants') || activeNav === 'my-restaurants' ? theme.palette.secondary.main : '#333',
                  fontWeight: isActive('/dashboard/my-restaurants') || activeNav === 'my-restaurants' ? 'bold' : 'normal'
                }}
              >
                My Restaurant
              </Typography>
            </NavItemContainer>
          </NavLinkStyled>
        </NavItem>
        <NavItem onClick={() => handleNavClick('menu-overview')}>
          <NavLinkStyled to="/dashboard/menu-overview" className="nav-link">
            <NavItemContainer className="nav-item-container">
              <RamenDiningRounded
                className="icon"
                style={{ color: isActive('/dashboard/menu-overview') || activeNav === 'menu-overview' ? theme.palette.secondary.main : '#333' }}
              />
              <Typography
                variant="body1"
                style={{
                  marginLeft: '20px',
                  color: isActive('/dashboard/menu-overview') || activeNav === 'menu-overview' ? theme.palette.secondary.main : '#333',
                  fontWeight: isActive('/dashboard/menu-overview') || activeNav === 'menu-overview' ? 'bold' : 'normal'
                }}
              >
                My Menu
              </Typography>
            </NavItemContainer>
          </NavLinkStyled>
        </NavItem>
        <NavItem onClick={() => handleNavClick('qr-codes')}>
          <NavLinkStyled to="/dashboard/qr-codes" className="nav-link">
            <NavItemContainer className="nav-item-container">
              <AppShortcutRounded
                className="icon"
                style={{ color: isActive('/dashboard/qr-codes') || activeNav === 'qr-codes' ? theme.palette.secondary.main : '#333' }}
              />
              <Typography
                variant="body1"
                style={{
                  marginLeft: '20px',
                  color: isActive('/dashboard/qr-codes') || activeNav === 'qr-codes' ? theme.palette.secondary.main : '#333',
                  fontWeight: isActive('/dashboard/qr-codes') || activeNav === 'qr-codes' ? 'bold' : 'normal'
                }}
              >
                Mobile Orders
              </Typography>
            </NavItemContainer>
          </NavLinkStyled>
        </NavItem>
        <NavItem onClick={() => handleNavClick('my-team')}>
          <NavLinkStyled to="/dashboard/my-team" className="nav-link">
            <NavItemContainer className="nav-item-container">
              <Groups2Rounded
                className="icon"
                style={{ color: isActive('/dashboard/my-team') || activeNav === 'my-team' ? theme.palette.secondary.main : '#333' }}
              />
              <Typography
                variant="body1"
                style={{
                  marginLeft: '20px',
                  color: isActive('/dashboard/my-team') || activeNav === 'my-team' ? theme.palette.secondary.main : '#333',
                  fontWeight: isActive('/dashboard/my-team') || activeNav === 'my-team' ? 'bold' : 'normal'
                }}
              >
                My Team
              </Typography>
            </NavItemContainer>
          </NavLinkStyled>
        </NavItem>
        <NavItem onClick={() => handleNavClick('analytics')}>
          <NavLinkStyled to="/dashboard/analytics" className="nav-link">
            <NavItemContainer className="nav-item-container">
              <Insights
                className="icon"
                style={{ color: isActive('/dashboard/analytics') || activeNav === 'analytics' ? theme.palette.secondary.main : '#333' }}
              />
              <Typography
                variant="body1"
                style={{
                  marginLeft: '20px',
                  color: isActive('/dashboard/analytics') || activeNav === 'analytics' ? theme.palette.secondary.main : '#333',
                  fontWeight: isActive('/dashboard/analytics') || activeNav === 'analytics' ? 'bold' : 'normal'
                }}
              >
                Analytics
              </Typography>
            </NavItemContainer>
          </NavLinkStyled>
        </NavItem>
        <NavItem onClick={() => handleNavClick('payments')}>
          <NavLinkStyled to="/dashboard/payments" className="nav-link">
            <NavItemContainer className="nav-item-container">
              <AddCard
                className="icon"
                style={{ color: isActive('/dashboard/payments') || activeNav === 'payments' ? theme.palette.secondary.main : '#333' }}
              />
              <Typography
                variant="body1"
                style={{
                  marginLeft: '20px',
                  color: isActive('/dashboard/payments') || activeNav === 'payments' ? theme.palette.secondary.main : '#333',
                  fontWeight: isActive('/dashboard/payments') || activeNav === 'payments' ? 'bold' : 'normal'
                }}
              >
                Payments
              </Typography>
            </NavItemContainer>
          </NavLinkStyled>
        </NavItem>
        <NavItem onClick={() => handleNavClick('receipts')}>
          <NavLinkStyled to="/dashboard/receipts" className="nav-link">
            <NavItemContainer className="nav-item-container">
              <ReceiptLong
                className="icon"
                style={{ color: isActive('/dashboard/receipts') || activeNav === 'receipts' ? theme.palette.secondary.main : '#333' }}
              />
              <Typography
                variant="body1"
                style={{
                  marginLeft: '20px',
                  color: isActive('/dashboard/receipts') || activeNav === 'receipts' ? theme.palette.secondary.main : '#333',
                  fontWeight: isActive('/dashboard/receipts') || activeNav === 'receipts' ? 'bold' : 'normal'
                }}
              >
                Receipts
              </Typography>
            </NavItemContainer>
          </NavLinkStyled>
        </NavItem>
        <NavItem onClick={() => handleNavClick('fidelity-programs')}>
          <NavLinkStyled to="/dashboard/fidelity-programs" className="nav-link">
            <NavItemContainer className="nav-item-container">
              <LoyaltyRounded
                className="icon"
                style={{ color: isActive('/dashboard/fidelity-programs') || activeNav === 'fidelity-programs' ? theme.palette.secondary.main : '#333' }}
              />
              <Typography
                variant="body1"
                style={{
                  marginLeft: '20px',
                  color: isActive('/dashboard/fidelity-programs') || activeNav === 'fidelity-programs' ? theme.palette.secondary.main : '#333',
                  fontWeight: isActive('/dashboard/fidelity-programs') || activeNav === 'fidelity-programs' ? 'bold' : 'normal'
                }}
              >
                Fidelity
              </Typography>
            </NavItemContainer>
          </NavLinkStyled>
        </NavItem>
        <NavItem onClick={() => handleNavClick('feedback')}>
          <NavLinkStyled to="/dashboard/feedback" className="nav-link">
            <NavItemContainer className="nav-item-container">
              <ThumbsUpDownRounded
                className="icon"
                style={{ color: isActive('/dashboard/feedback') || activeNav === 'feedback' ? theme.palette.secondary.main : '#333' }}
              />
              <Typography
                variant="body1"
                style={{
                  marginLeft: '20px',
                  color: isActive('/dashboard/feedback') || activeNav === 'feedback' ? theme.palette.secondary.main : '#333',
                  fontWeight: isActive('/dashboard/feedback') || activeNav === 'feedback' ? 'bold' : 'normal'
                }}
              >
                Feedback
              </Typography>
            </NavItemContainer>
          </NavLinkStyled>
        </NavItem>
      </NavList>
    </SideNavBarContainer>
  );
};

export default SideNavBar;
