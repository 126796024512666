// GuestWalkIn v2.6 with Loading Spinner
import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Grid,
  Button,
  CircularProgress,
} from '@mui/material';
import { fetchAvailableTablesForWalkIn, createWalkInReservation } from '../../../services/posApiService';

const GuestWalkIn = ({ open, onClose, onConfirmWalkIn, restaurantId }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [availableTables, setAvailableTables] = useState([]);
  const [selectedTable, setSelectedTable] = useState(null);
  const [guestCount, setGuestCount] = useState(null); // Initially no guest count selected
  const [loading, setLoading] = useState(false); // Loading state for the spinner

  const loadAvailableTables = useCallback(async () => {
    setLoading(true); // Start loading
    try {
      const tables = await fetchAvailableTablesForWalkIn(restaurantId, guestCount);
      setAvailableTables(tables);
    } catch (error) {
      console.error("Error fetching available tables:", error);
    } finally {
      setLoading(false); // Stop loading once the request completes
    }
  }, [guestCount, restaurantId]);

  useEffect(() => {
    if (activeStep === 1) {
      loadAvailableTables();
    }
  }, [activeStep, loadAvailableTables]);

  const handleGuestCountSelection = (count) => {
    setGuestCount(count);
    handleNextStep(); // Move to the next step
  };

  const handleTableSelection = (table) => {
    setSelectedTable(table);
    handleNextStep(); // Move to the next step
  };

  const handleNextStep = () => {
    setActiveStep((prev) => prev + 1);
  };

  const handleBackStep = (step) => {
    setActiveStep(step);
  };

  const confirmWalkIn = async () => {
    try {
      await createWalkInReservation(guestCount, selectedTable.id); // Simplified payload
      onConfirmWalkIn(); // Notify parent of successful walk-in reservation
    } catch (error) {
      console.error("Error confirming walk-in:", error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogContent>
        <Stepper activeStep={activeStep} alternativeLabel>
          {['Guest count', 'Select table', 'Confirm table'].map((label, index) => (
            <Step key={label}>
              <StepLabel
                onClick={() => handleBackStep(index)}
                sx={{
                  cursor: index < activeStep ? 'pointer' : 'default', // Pointer only for previous steps
                  color: index < activeStep ? 'primary.main' : 'text.secondary',
                }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>

        {activeStep === 0 && (
          <Box mt={4}>
            <Typography variant="h6" gutterBottom align="center">Number of guests</Typography>
            <Grid container spacing={2}>
              {Array.from({ length: 12 }, (_, i) => i + 1).map((count) => (
                <Grid item xs={4} sm={3} key={count}>
                  <Paper
                    elevation={1}
                    sx={{
                      padding: 2,
                      textAlign: 'center',
                      cursor: 'pointer',
                      border: guestCount === count ? '2px solid blue' : 'none',
                    }}
                    onClick={() => handleGuestCountSelection(count)}
                  >
                    <Typography variant="body1">{count}</Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}

        {activeStep === 1 && (
          <Box mt={4} display="flex" flexDirection="column" alignItems="center">
            <Typography variant="h6" gutterBottom align="center">Available Tables</Typography>
            {loading ? (
              <CircularProgress />
            ) : (
              <Grid container spacing={2}>
                {availableTables.map((table) => (
                  <Grid item xs={6} sm={4} key={table.id}>
                    <Paper
                      elevation={1}
                      sx={{
                        padding: 2,
                        textAlign: 'center',
                        cursor: 'pointer',
                        border: selectedTable?.id === table.id ? '2px solid blue' : 'none',
                      }}
                      onClick={() => handleTableSelection(table)}
                    >
                      <Typography variant="body1">Table {table.number}</Typography>
                      <Typography variant="body2">{table.seats} seats</Typography>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            )}
          </Box>
        )}

        {activeStep === 2 && selectedTable && (
          <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
            <Typography variant="h1" color="secondary" textAlign="center">Table #{selectedTable.number}</Typography>
            <Typography variant="subtitle1" color="textSecondary" gutterBottom>
              Seats: {selectedTable.seats}
            </Typography>
          </Box>
        )}
      </DialogContent>
      
      <DialogActions sx={{ justifyContent: 'center' }}>
        {activeStep === 2 && (
          <Button variant="contained" color="primary" onClick={confirmWalkIn}>
            Seat Guests
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default GuestWalkIn;
