import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import { TextField, Button, Typography, LinearProgress, Alert } from '@mui/material';

// Styled components
const Container = styled.div`
  padding: 4% 10% 2% 10%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) { /* Tablets */
    padding: 4% 20% 2% 20%;
  }

  @media (min-width: 1024px) { /* Desktops */
    padding: 4% 35% 2% 35%;
  }
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PasswordResetForm = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [formErrors, setFormErrors] = useState({});
  const { uidb64, token } = useParams();
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Function to calculate password strength
  const getPasswordStrength = (password) => {
    if (password.length === 0) return 0; // No feedback if nothing is typed.

    const lengthRequirement = password.length >= 8;
    const digitRequirement = /[0-9]/.test(password);
    const uppercaseRequirement = /[A-Z]/.test(password);
    const lowercaseRequirement = /[a-z]/.test(password);
    const specialCharRequirement = /[^A-Za-z0-9]/.test(password);

    let strength = 0;
    if (password.length > 0) strength = 1;
    if (lengthRequirement && digitRequirement && lowercaseRequirement) strength += 2;
    if (uppercaseRequirement && lowercaseRequirement && lengthRequirement) strength += 1;
    if (specialCharRequirement) strength += 1;

    return strength; // Returns a value between 0 and 5
  };

  // Function to get password strength text
  const getPasswordStrengthText = (strength) => {
    switch (strength) {
      case 0:
        return "Password must contain at least 8 characters, a digit and a letter."
      case 1:
      case 2:
        return "Your password is too weak";
      case 3:
        return "Your password looks good, but could be stronger.";
      case 4:
        return "Good! Your password is strong.";
      case 5:
        return "Excellent! Your password is very strong.";
      default:
        return "Password must contain at least 8 characters, a digit and a letter.";
    }
  };

  const handleNewPasswordChange = (event) => {
    const newPasswordValue = event.target.value;
    setNewPassword(newPasswordValue);
    const newStrength = getPasswordStrength(newPasswordValue);
    setPasswordStrength(newStrength);
  };

  const handleConfirmNewPasswordChange = (event) => {
    setConfirmNewPassword(event.target.value);
  };

  // Form Validation
  const validateForm = () => {
    const errors = {};
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d).{8,}$/;

    if (!newPassword || !passwordRegex.test(newPassword)) {
      errors.password = 'Password must contain at least 8 characters, a digit and a letter.';
    }

    if (newPassword !== confirmNewPassword) {
      errors.confirmPassword = 'Passwords do not match.';
    }

    setFormErrors(errors);

    // The form is valid if the errors object has no properties
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSuccessMessage('');
    setErrorMessage('');

    if (validateForm()) {
      try {
        // Construct the endpoint dynamically
        const baseUrl = process.env.REACT_APP_API_URL;
        const resetPasswordEndpoint = `${baseUrl}auth/reset/${uidb64}/${token}/`;
        await axios.post(resetPasswordEndpoint, {
          new_password: newPassword,
          confirm_new_password: confirmNewPassword,
        });

        setSuccessMessage('Password has been changed. You are being redirected to login');
        setTimeout(() => navigate('/login'), 3000); // Redirect after 3 seconds

      } catch (error) {
        if (error.response && error.response.status === 400) {
          setErrorMessage(error.response.data.error); // Use the error message from Django
        } else {
          setErrorMessage('An error occurred. Please try again.');
        }
      }
    }
  };

  // Determine color based on password strength
  const getPasswordStrengthColor = (strength) => {
    if (strength < 1) return 'primary';
    if (strength <= 2) return 'error';
    if (strength <= 3) return 'secondary';
    if (strength >= 4) return 'success';
    return 'success';
  };

  return (
    <Container>
      <Typography variant="h2" gutterBottom>Update your password</Typography>
      <Typography variant="body1">Please enter your new password below:</Typography>
      <Form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          type="password"
          label="New Password"
          value={newPassword}
          onChange={handleNewPasswordChange}
          margin="normal"
          variant="outlined"
          error={!!formErrors.password}
          helperText={formErrors.password}
          autoComplete="new-password"
        />
        <LinearProgress
          variant="determinate"
          value={(passwordStrength / 5) * 100}
          color={getPasswordStrengthColor(passwordStrength)}
          style={{ width: '100%', marginTop: '10px' }}
        />
        <Typography variant="body2" style={{ marginTop: '10px' }}>{getPasswordStrengthText(passwordStrength)}</Typography>
        <TextField
          fullWidth
          type="password"
          label="Confirm New Password"
          value={confirmNewPassword}
          onChange={handleConfirmNewPasswordChange}
          margin="normal"
          variant="outlined"
          error={!!formErrors.confirmPassword}
          helperText={formErrors.confirmPassword}
          autoComplete="new-password"
        />
        {successMessage && <Alert severity="success" style={{ width: '100%', marginTop: '10px' }}>{successMessage}</Alert>}
        {errorMessage && <Alert severity="error" style={{ width: '100%', marginTop: '10px' }}>{errorMessage}</Alert>}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          style={{ marginTop: '20px' }}
        >
          Update Password
        </Button>
      </Form>
    </Container>
  );
};

export default PasswordResetForm;
