import React, { useState, useEffect } from 'react';
import { useRestaurant } from '../../contexts/RestaurantContext';
import { Select, MenuItem, FormControl, InputLabel, Button, Alert, Box } from '@mui/material';
import styled from 'styled-components';
import { assignMenuToRestaurantAndArea, fetchUserRestaurants, fetchAreasForRestaurant } from '../../services/apiService'; // Adjust the import path as necessary

const Container = styled(Box)`
  position: absolute;
  right: 10px;
  top: 60px;
  width: 350px;
  z-index: 10;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  display: ${props => (props.display ? 'block' : 'none')};
  border: 1px solid var(--color-secondary);
`;

const MenuAreaAssignmentWidget = ({ isVisible, onClose }) => {
  const [restaurants, setRestaurants] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState('');
  const [areas, setAreas] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState([]);
  // Shared states
  const { error, setError, selectedMenu } = useRestaurant();

  useEffect(() => {
    const fetchRestaurantsAndAreas = async () => {
      try {
        const fetchedRestaurants = await fetchUserRestaurants();
        setRestaurants(fetchedRestaurants);
        if (fetchedRestaurants.length > 0) {
          setSelectedRestaurant(fetchedRestaurants[0].id);
          const fetchedAreas = await fetchAreasForRestaurant(fetchedRestaurants[0].id);
          setAreas(fetchedAreas);
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (isVisible) {
      fetchRestaurantsAndAreas();
    }
  }, [isVisible]);

  useEffect(() => {
    const fetchAreas = async () => {
      if (selectedRestaurant) {
        try {
          const fetchedAreas = await fetchAreasForRestaurant(selectedRestaurant);
          setAreas(fetchedAreas);
        } catch (error) {
          console.error(error);
        }
      }
    };

    fetchAreas();
  }, [selectedRestaurant]);

  const handleAssignMenu = async () => {
    setError(''); // Clear previous error message
    try {
      const response = await assignMenuToRestaurantAndArea(selectedMenu, selectedAreas);
      if (response && response.status === 200) {
        alert('Menu successfully assigned to selected areas.');
        onClose(); // Close the widget upon successful assignment
      } else {
        setError('An unexpected error occurred.');
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setError(error.response.data.error);
      } else {
        console.error('Error assigning menu to areas:', error);
        setError('An unexpected error occurred.');
      }
    }
  };

  return (
    <Container display={isVisible ? 1 : 0}>
      <FormControl fullWidth variant="outlined">
        <InputLabel id="restaurant-select-label">Select a restaurant</InputLabel>
        <Select
          labelId="restaurant-select-label"
          value={selectedRestaurant}
          onChange={e => setSelectedRestaurant(e.target.value)}
          label="Select a restaurant" // Associate the label with the Select
        >
          {restaurants.map(restaurant => (
            <MenuItem key={restaurant.id} value={restaurant.id}>
              {restaurant.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth variant="outlined" style={{ marginTop: '20px' }}>
        <InputLabel id="area-select-label">Select areas</InputLabel>
        <Select
          labelId="area-select-label"
          multiple
          value={selectedAreas}
          onChange={e => setSelectedAreas(e.target.value)}
          label="Select an area"
        >
          {areas.map(area => (
            <MenuItem key={area.id} value={area.id}>
              {area.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button variant="contained" color="primary" onClick={handleAssignMenu} style={{ marginTop: '20px' }}>
        Assign Menu
      </Button>
      <Button variant="outlined" color="primary" onClick={onClose} style={{ marginLeft: '10px', marginTop: '20px' }}>
        Cancel
      </Button>
      {error && <Alert severity="error" style={{ marginTop: '20px' }}>{error}</Alert>}
    </Container>
  );
};

export default MenuAreaAssignmentWidget;
