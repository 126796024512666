// Order Summary v1.85
import React from 'react';
import { usePOS } from '../../../contexts/POSContext';
import styled from 'styled-components';
import {
  FormControl,
  MenuItem,
  Select,
  Typography,
  IconButton,
  Collapse,
  Alert,
  Divider,
  Button as MuiButton,
} from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import EditNoteRounded from '@mui/icons-material/EditNoteRounded';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import ItemDetails from './ItemDetails';

const RightContainer = styled.div`
  width: 40%;
  background-color: #1f1f1f;
  overflow-y: auto;
  border-radius: 10px;
  padding: 10px;
  max-height: calc(100vh - 130px);
  @media (max-width: 600px) {
    display: none;
  }
`;

const ActionMessage = styled.div`
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  background-color: #1f1f1f;
  padding-left: 10px;
  width: 60%;
`;

const TableSelectContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  background-color: #1f1f1f;
  padding-right: 10px;
  width: 40%;
`;

const OrderItemRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 5px;
  border: 1px solid var(--color-lightgrey);
  cursor: ${({ $isDragDisabled, $isDrinks }) => ($isDrinks ? 'default' : ($isDragDisabled ? 'default' : 'grab'))};
`;

const OrderItemContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  color: white;
`;

const PriceItemContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  color: white;
`;

const TotalContainer = styled.div`
  display: flex;
  margin-top: 16px;
  font-weight: bold;
  justify-content: flex-end;
  padding-right: 20px;
  color: #ff6a3ee3;
`;

const ButtonWrapper = styled.div`
  position: sticky;
  bottom: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  background-color: #1f1f1f;
  z-index: 1000;
`;

const StyledButton = styled(MuiButton)`
  && {
    margin: 10px;
    width: 100%;
  }
`;

const AlertContainer = styled.div`
  position: relative;
  width: auto;
`;

const OrderSummary = ({
  drawerOpen,
  setDrawerOpen,
  orderChanged,
  setOrderChanged,
  courseChanged,
  setCourseChanged,
  isMobile,
  isTabletOrLarger,
  fetchAggregatedOrder,
  removeItemFromOrder,
  handleItemDetailsClick,
  onDragEnd,
  updateOrderChanges,
  placeOrder,
  deletePlacedOrder
}) => {
  const {
    tables,
    selectedTable,
    setSelectedTable,
    orderItems,
    setOrderItems,
    modalOpen,
    closeModal,
    alert,
    showAlert,
    clearAlert,
    orderEmpty,
  } = usePOS();

const handleTableChange = async (event) => {
  const tableId = event.target.value;
  setSelectedTable(tableId);

  try {
    const orderData = await fetchAggregatedOrder(tableId);

    if (orderData.items && Object.keys(orderData.items).length > 0) {
      setOrderItems({
        orderId: orderData.orderId,
        items: orderData.items,  // Use the nested structure provided by the backend
        customOrder: orderData.custom_order,
      });

    } else {
      setOrderItems({
        orderId: null,
        items: {},  // Ensure it's an empty object for consistency
        customOrder: false,
      });
    }
  } catch (error) {
    console.error('Error fetching order for the selected table:', error);
    showAlert('Error fetching order for the selected table', 'error', 'order-fetch');
  } finally {
    setOrderChanged(false); // Ensure that orderChanged is reset after the fetch
  }
};

const renderItemsByCategory = () => {
  const { items } = orderItems;

  // Ensure items is defined and is an object
  if (!items || typeof items !== 'object') {
    return (
      <Typography variant="h6" style={{ color: '#ff6a3ee3', textAlign: 'center', marginTop: '50px' }}>
        Select a table and pick items from the left
      </Typography>
    );
  }

  // Filter out empty meal courses and sort them numerically
  const nonEmptyCourses = Object.entries(items)
    .filter(([courseName, courseItems]) => Object.keys(courseItems).length > 0)
    .sort(([courseNameA], [courseNameB]) => {
      const courseNumberA = parseInt(courseNameA.split(' ').pop(), 10);
      const courseNumberB = parseInt(courseNameB.split(' ').pop(), 10);
      return courseNumberA - courseNumberB;
    });

  if (nonEmptyCourses.length === 0) {
    return (
      <Typography variant="h6" style={{ color: '#ff6a3ee3', textAlign: 'center', marginTop: '50px' }}>
        Select a table and pick items from the left
      </Typography>
    );
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {nonEmptyCourses.map(([courseName, courseItems]) => (
        <div key={courseName}>
          <Typography variant="body1" style={{ color: '#ff6a3ee3', marginLeft: '10px', paddingTop: '5px' }}>
            {courseName}
          </Typography>
          <Droppable droppableId={courseName}>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {Object.entries(courseItems).map(([itemName, item], itemIndex) => {
                  // Safeguard to ensure item exists
                  if (!item) return null;

                  const uniqueId = item.id
                    ? item.id.toString()
                    : (item.menu_item_id ? `menu_${item.menu_item_id}` : `combo_${item.combo_id}`);

                  const isDraggable = item.status === 'received' && item.course_type !== 'Drinks';

                  return (
                    <Draggable
                      key={uniqueId}
                      draggableId={uniqueId}
                      index={itemIndex}
                      isDragDisabled={!isDraggable}
                    >
                      {(provided) => (
                        <OrderItemRow
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          $isDrinks={item.course_type === 'Drinks'}
                          $isDragDisabled={!isDraggable}
                        >
                          <OrderItemContainer>
                            <IconButton
                              onClick={() => removeItemFromOrder(uniqueId, item)}
                              aria-label="remove"
                              disabled={item.status && item.status !== 'received'}
                            >
                              <RemoveCircleIcon sx={{ color: item.status && item.status !== 'received' ? 'grey' : '#ff6a3ee3' }} />
                            </IconButton>
                            <Typography style={{ color: item.status && item.status !== 'received' ? 'grey' : 'white' }}>
                              {item.name} x{item.quantity}
                            </Typography>
                            <IconButton
                              onClick={() => handleItemDetailsClick(item)}
                              aria-label="edit-note"
                              disabled={item.status && item.status !== 'received'}
                            >
                              <EditNoteRounded sx={{ color: item.status && item.status !== 'received' ? 'grey' : '#ff6a3ee3' }} />
                            </IconButton>
                          </OrderItemContainer>
                          <PriceItemContainer style={{ marginRight: '10px' }}>
                            <Typography color="white">
                              €{(item.price * item.quantity).toFixed(2)}
                            </Typography>
                            {/* Conditionally render DragIndicatorIcon based on isDraggable */}
                            {isDraggable && <DragIndicatorIcon sx={{ marginLeft: '5px' }} />}
                          </PriceItemContainer>
                          <Divider variant="fullWidth" />
                        </OrderItemRow>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <Divider variant="middle" sx={{ backgroundColor: 'grey', marginTop: '10px' }} />
        </div>
      ))}
    </DragDropContext>
  );
};

const calculateTotal = () => {
  const { items } = orderItems;

  if (!items || typeof items !== 'object') {
    return '0.00'; // Return a total of 0 if items is not an object
  }

  // Iterate through each course and calculate the total
  const total = Object.values(items).reduce((courseSum, courseItems) => {
    return courseSum + Object.values(courseItems).reduce((itemSum, item) => {
      const price = item.price || 0;
      return itemSum + price * item.quantity;
    }, 0);
  }, 0);

  return total.toFixed(2);
};

const renderOrderButton = () => {
  const hasItems = orderItems && typeof orderItems.items === 'object' && Object.keys(orderItems.items).length > 0;
  const hasCourseChanged = courseChanged && typeof courseChanged === 'object' && Object.keys(courseChanged).length > 0;

    if (hasCourseChanged) {
      return (
        <ButtonWrapper>
          <StyledButton variant="contained" color="primary" onClick={updateOrderChanges}>
            Update Course Order
          </StyledButton>
        </ButtonWrapper>
      );
    } else if (orderChanged && hasItems) {
      return (
        <ButtonWrapper>
          <StyledButton variant="contained" color="primary" onClick={placeOrder}>
            Place Order
          </StyledButton>
        </ButtonWrapper>
      );
    } else if (orderEmpty) {
      return (
        <ButtonWrapper>
          <StyledButton variant="contained" color="secondary" onClick={deletePlacedOrder}>
            Delete Order
          </StyledButton>
        </ButtonWrapper>
      );
    }

    return null;
  };

  const total = calculateTotal();
  const hasNoItems = total === '0.00';

  const dragMessage = hasNoItems ? 'Select a table and pick items from the left' : 'Drag & Drop to adjust meal courses';

  return (
    <RightContainer>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <ActionMessage>
          {!hasNoItems && (
            <Typography variant="body1" style={{ color: 'var(--color-light)', width: '100%' }}>
              {dragMessage}
            </Typography>
          )}
        </ActionMessage>
        <TableSelectContainer>
          <FormControl>
            <Select
              variant="standard"
              sx={{
                ':before': { borderBottomColor: '#ff6a3ee3', icon: '#ff6a3ee3' },
                ':after': { borderBottomColor: '#ff6a3ee3' },
                '& .MuiSelect-icon': { color: 'white' },
              }}
              value={selectedTable || ''}
              onChange={handleTableChange}
              displayEmpty
              style={{ borderRadius: '0', color: 'white', border: 'none' }}
            >
              <MenuItem value="" disabled>
                Select a table number
              </MenuItem>
              {tables.sort((a, b) => a.number - b.number).map((table) => (
                <MenuItem key={table.id} value={table.id}>
                  {`Table ${table.number}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </TableSelectContainer>
      </div>
      <Collapse in={Boolean(alert.message)}>
        <AlertContainer>
          <Alert severity={alert.type} onClose={clearAlert}>
            {alert.message}
          </Alert>
        </AlertContainer>
      </Collapse>
      {renderItemsByCategory()}
      <ItemDetails open={modalOpen} handleClose={closeModal} />
      {!hasNoItems && (
        <TotalContainer>
          Total: €{total}
        </TotalContainer>
      )}
      {renderOrderButton()}
    </RightContainer>
  );
};

export default OrderSummary;
