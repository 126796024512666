import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const RoleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #121212; /* Dark mode background */
  min-height: 100vh;
  button {
    margin: 10px;
    padding: 20px;
    font-size: 1.2em;
    width: 50%;
    cursor: pointer;
    background-color: #3e3effe6;
    border: none;
    color: white;
    &:hover {
      background-color: #ff6a3ee3;
    }
    transition: background-color 0.3s ease; // smooth transition for background color
  }
`;

const Title = styled.h3`
  color: white;
`;

const RoleSelection = () => {
  const navigate = useNavigate();

  const handleSelectRole = (role) => {
    navigate(`/pos/${role.toLowerCase()}`);
  };

  return (
    <RoleContainer>
      <Title>Select Your Area</Title>
      <button onClick={() => handleSelectRole('dashboard/home')}>Tables</button>
      <button onClick={() => handleSelectRole('Bar')}>Bar</button>
      <button onClick={() => handleSelectRole('Kitchen')}>Kitchen</button>
    </RoleContainer>
  );
};

export default RoleSelection;