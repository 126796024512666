import React, { useState, useEffect, useCallback } from 'react';
import { useRestaurant } from '../../contexts/RestaurantContext';
import styled from 'styled-components';
import { Dialog, DialogContent, DialogTitle, CircularProgress } from '@mui/material';
import { DragDropContext } from '@hello-pangea/dnd';
import MenuAreaAssignmentWidget from './MenuAreaAssignmentWidget';
import SidebarWidget from './SidebarWidget';
import ConfirmationModal from './ConfirmationModal';
import OnlineMenuModal from './OnlineMenuModal';
import { fetchMenus, fetchMenuCategories, deleteOrDissociateCombo, fetchMenuItemsByCategory, deleteOrDissociateMenu,
         deleteOrDissociateMenuItem, updateMenuOrder, deleteOrDissociateCategory } from '../../services/apiService';
import { fetchLiveMenuDetails } from '../../services/usersApiService';
import MenuWizard from './menu/MenuWizard';
import MenuHeader from './menu/MenuHeader';
import CategoryList from './menu/CategoryList';
import CategoryActions from './menu/CategoryActions';

const Container = styled.div`
  padding: 20px;
`;

// Main menu overview container
const MenuOverviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 65%;
  border: 1px solid var(--color-light);
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 20px;
  padding-bottom: 10px;
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; // Take up the full viewport height
`;

const MenuOverview = () => {
  // State declarations
  const [showMenuWizard, setShowMenuWizard] = useState(false);
  const [showMenuAreaAssignment, setShowMenuAreaAssignment] = useState(false);
  const [expandedCategories, setExpandedCategories] = useState({});
  const [expandedItems, setExpandedItems] = useState({});
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [categoryActionSelection, setCategoryActionSelection] = useState(null);
  const [menuItemActionSelection, setMenuItemActionSelection] = useState(null);
  const [isWizardOpen, setIsWizardOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  // Shared states
  const {
    setShowSidebar, menus, setMenus, selectedMenu, setSelectedMenu, loadMenuCategories, loadAndSelectLatestMenu,
    setEditingMenu, setSelectedCategory, menuCategories, setMenuCategories, loadMenuItems, refreshCombos,
    menuItems, setMenuItems, setSidebarContext, setEditingCategory, setEditingMenuItem, combos, setCombos, setEditingCombo,
    allergenOptions, setAllergenOptions, setDietGroupOptions, dietGroupOptions, setSelectedAllergens, setSelectedDietGroups,
    showPreview, setShowPreview, setPreviewData, loadCombos, allergens, dietGroups, fetchAllergensAndDietGroups
  } = useRestaurant();

  // Options for Menu dropdown
  const selectOptions = menus.map(menu => ({ value: menu.id, label: menu.name }));

  // Options for Category dropdown
  const addCategoryOptions = [
    { value: 'newCategory', label: 'New Category' },
    { value: 'existingCategory', label: 'Existing Category' }
  ];

  // Options for Menu Item dropdown
  const addMenuItemOptions = [
    { value: 'newMenuItem', label: 'New Item' },
    { value: 'existingMenuItem', label: 'Existing Item' }
  ];

  const openWizard = useCallback(() => {
    setIsWizardOpen(true);
  }, []);

  const closeWizard = () => {
    setIsWizardOpen(false);
  };

  // Load menus and sort them by created_at (latest first)
  useEffect(() => {
    const loadData = async () => {
      await loadAndSelectLatestMenu(); // Call the function from context
      setLoading(false); // Set loading to false after menus are fetched
    };

    loadData();
  }, [loadAndSelectLatestMenu]);

  // Function to handle newly created menu (either manually or via AI)
  const handleNewMenuCreated = (newMenu) => {
    setMenus((prevMenus) => [newMenu, ...prevMenus]);  // Add the new menu to the list
    setSelectedMenu(newMenu.id.toString());  // Select the newly created menu
  };

  // useEffect to load categories for the selected menu
  useEffect(() => {
    if (selectedMenu) {
      loadMenuCategories(selectedMenu);
    }
  }, [selectedMenu, loadMenuCategories]);

  // Function to fetch items for the selected menu
  useEffect(() => {
    if (selectedMenu) {
      loadMenuItems(selectedMenu);
    }
  }, [selectedMenu, loadMenuItems]);

  // Function to fetch combos for the selected menu
  useEffect(() => {
    if (selectedMenu) {
      loadCombos(selectedMenu);
    }
  }, [selectedMenu, loadCombos, refreshCombos]);

  // useEffect to fetch allergens and diet groups
  useEffect(() => {
    fetchAllergensAndDietGroups();
  }, [fetchAllergensAndDietGroups]);  

  // Transforming allergens and diet groups to select options
  useEffect(() => {
    const allergenOptions = allergens.map(allergen => ({
      value: allergen.id, 
      label: allergen.name
    }));

    const dietGroupOptions = dietGroups.map(dietGroup => ({
      value: dietGroup.id, 
      label: dietGroup.name
    }));

    setAllergenOptions(allergenOptions);
    setDietGroupOptions(dietGroupOptions);
  }, [allergens, dietGroups, setAllergenOptions, setDietGroupOptions]);

  // Function to close the menu wizard
  const closeMenuWizard = async () => {
    setShowMenuWizard(false);
    setLoading(true);

    // Refetch menus, categories, and items
    const response = await fetchMenus();
    const sortedMenus = response.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    setMenus(sortedMenus);

    if (sortedMenus.length > 0) {
      setSelectedMenu(sortedMenus[0].id.toString());
      const categories = await fetchMenuCategories(sortedMenus[0].id);
      setMenuCategories(categories);

      // Expand all categories directly
      const expandedCategoriesState = {};
      categories.forEach(category => {
        expandedCategoriesState[category.id] = true;
      });
      setExpandedCategories(expandedCategoriesState);

      // Fetch items for each category and merge them
      let allItems = [];
      for (let category of categories) {
        const items = await fetchMenuItemsByCategory(category.id);
        allItems = [...allItems, ...items];
      }
      setMenuItems(allItems);
    }

    setLoading(false);
    closeWizard();
  };

  // Functions for toggle actions
  const toggleCategory = categoryId => setExpandedCategories(prev => ({ ...prev, [categoryId]: !prev[categoryId] }));
  const toggleItem = itemId => setExpandedItems(prev => ({ ...prev, [itemId]: !prev[itemId] }));

  // Function for expanding/collapsing all categories and items
  const toggleGlobalExpansion = expand => {
    setExpandedCategories(expand ? Object.fromEntries(menuCategories.map(cat => [cat.id, true])) : {});
    setExpandedItems(expand ? Object.fromEntries([
      ...menuItems.map(item => [item.id, true]),
      ...combos.map(combo => [combo.id, true]),
    ]) : {});
  };

  // Logic for handling drag and drop actions
  const onDragEnd = async (result) => {
    const { source, destination, type } = result;

    if (!destination) {
      return; // Dropped outside the list
    }

    if (type === 'CATEGORY') {
      const newCategories = Array.from(menuCategories);
      const [reorderedCategory] = newCategories.splice(source.index, 1);
      newCategories.splice(destination.index, 0, reorderedCategory);
      setMenuCategories(newCategories);

      // Prepare data for API call
      const categoryOrder = newCategories.map(category => category.id);
      await updateMenuOrder({ menu_id: selectedMenu, categoryOrder });
    } else if (type === 'ITEM') {
      if (source.droppableId === destination.droppableId) {
        const categoryId = parseInt(source.droppableId.split('-')[1]);
        const itemsInCategory = menuItems.filter(item => item.categories.includes(categoryId));

        // Find the indices of items in the menuItems array
        const sourceIndexInMenuItems = menuItems.findIndex(item => item.id === itemsInCategory[source.index].id);
        const destinationIndexInMenuItems = menuItems.findIndex(item => item.id === itemsInCategory[destination.index].id);

        const newItems = Array.from(menuItems);
        const [reorderedItem] = newItems.splice(sourceIndexInMenuItems, 1);
        newItems.splice(destinationIndexInMenuItems, 0, reorderedItem);

        setMenuItems(newItems);

        // Prepare data for API call
        const itemOrder = newItems
          .filter(item => item.categories.includes(categoryId))
          .map((item, index) => ({ id: item.id, order: index, category_id: categoryId }));

        await updateMenuOrder({ menu_id: selectedMenu, itemOrder });
      }

      // Add logic here if you want to handle moving items between different categories
    } else if (type === 'COMBOS') {
      if (source.droppableId === destination.droppableId) {
        const categoryId = parseInt(source.droppableId.split('-')[1]);
        const combosInCategory = combos.filter(combo => combo.categories.includes(categoryId));

        // Reordering logic
        const sourceIndexInCombos = combos.findIndex(combo => combo.id === combosInCategory[source.index].id);
        const destinationIndexInCombos = combos.findIndex(combo => combo.id === combosInCategory[destination.index].id);

        const newCombos = Array.from(combos);
        const [reorderedCombo] = newCombos.splice(sourceIndexInCombos, 1);
        newCombos.splice(destinationIndexInCombos, 0, reorderedCombo);

        setCombos(newCombos); // Update the state to reflect the new order

        // Prepare data for API call, including category_id for each combo
        const comboOrder = newCombos
          .filter(combo => combo.categories.includes(categoryId))
          .map((combo, index) => ({
            id: combo.id,
            order: index,
            category_id: categoryId // Ensure to include category_id
          }));

        await updateMenuOrder({ menu_id: selectedMenu, comboOrder });
      }
    }
  };

  // Function to open sidebar for creating a new menu  
  const openMenuCreationSidebar = () => {
    setSidebarContext('createMenu');
    setShowSidebar(true);
  };

  // Function to open sidebar for editing a menu
  const openEditMenuSidebar = (menuId) => {
    const menuToEdit = menus.find(menu => menu.id === parseInt(menuId));
    if (menuToEdit) {
      setSidebarContext('editMenu');
      setEditingMenu(menuToEdit);
      setShowSidebar(true);
    } else {
      console.error("Menu to edit not found for ID:", menuId);
    }
  };

  // Function to open sidebar for creating or assigning a new category
  const handleAddCategorySelection = (selection) => {
    if (selection.value === 'newCategory') {
      setSidebarContext('createCategory');
    } else if (selection.value === 'existingCategory') {
      setSidebarContext('assignCategory');
    }
    setShowSidebar(true);
    setEditingCategory(null); // Reset state.
    setCategoryActionSelection(null);
  };

  // Function to open sidebar for editing a category
  const openEditCategorySidebar = (category) => {
    setSidebarContext('editCategory');
    setEditingCategory(category);
    setShowSidebar(true);
  };

  // Function to open sidebar for adding a menu item or combo within a category
  const handleAddMenuItemSelection = (selection, categoryId) => {
    if (selection.value === 'newMenuItem') {
      setSidebarContext('createMenuItem');
      setSelectedCategory([categoryId]);
    } else if (selection.value === 'existingMenuItem') {
      setSidebarContext('assignMenuItem');
      setSelectedCategory(categoryId); 
    }
    console.log("Selected Category:", categoryId);  // Debug log
    setShowSidebar(true);
    setEditingMenuItem(null); // Reset state if needed
    setMenuItemActionSelection(null);
  };

  // Function to open sidebar for editing a menu item
  const openEditMenuItemSidebar = (menuItem) => {
    setSidebarContext('editMenuItem');
    setEditingMenuItem(menuItem);
    setSelectedCategory(menuItem.categories);
    setShowSidebar(true);

    const preSelectedAllergens = menuItem.allergens.map(allergenId => {
      const foundAllergen = allergenOptions.find(option => option.value === allergenId);
      return foundAllergen || null;
    }).filter(Boolean);

    const preSelectedDietGroups = menuItem.diet_groups.map(dietGroupId => {
      const foundDietGroup = dietGroupOptions.find(option => option.value === dietGroupId);
      return foundDietGroup || null;
    }).filter(Boolean);

    setSelectedAllergens(preSelectedAllergens);
    setSelectedDietGroups(preSelectedDietGroups);
  };

  // Function to open sidebar for editing a combo
  const openEditComboSidebar = (combo) => {
    setSidebarContext('editCombo');
    setEditingCombo(combo);
    setSelectedCategory(combo.categories);
    setShowSidebar(true);
  };

  // Function to show the delete confirmation modal
  const openDeleteConfirmation = (id, type, currentMenuId = null, currentCategoryId = null) => {
    setItemToDelete({ id, type, currentMenuId, currentCategoryId });
    setShowConfirmationModal(true);
  };

  // Function to handle the delete operation when confirmed
  const handleDeleteConfirmed = async () => {
    if (!itemToDelete) return;

    try {
      switch (itemToDelete.type) {
        case 'menu':
          const menuDeleteResult = await deleteOrDissociateMenu(itemToDelete.id);
          if (menuDeleteResult.action_taken === 'deleted') {
            setMenus(prevMenus => prevMenus.filter(menu => menu.id !== itemToDelete.id));
            // Select another menu if available
            if (menus.length > 0) {
              const nextAvailableMenu = menus.find(menu => menu.id !== itemToDelete.id);
              if (nextAvailableMenu) {
                setSelectedMenu(nextAvailableMenu.id.toString());
              }
            } else {
              // If no menus are left, open the wizard
              openWizard();
            }
          }
          break;
        case 'category':
          const result = await deleteOrDissociateCategory(itemToDelete.id, selectedMenu);
          if (result.action_taken === 'deleted') {
            setMenuCategories(prevCategories => prevCategories.filter(cat => cat.id !== itemToDelete.id));
          } else if (result.action_taken === 'dissociated') {
            await loadMenuCategories(selectedMenu);
          }
          break;
        case 'menuItem':
          await deleteOrDissociateMenuItem(itemToDelete.id, itemToDelete.currentCategoryId);
          setMenuItems(prevItems => prevItems.filter(item => item.id !== itemToDelete.id));
          break;
        case 'combo':
          await deleteOrDissociateCombo(itemToDelete.id, itemToDelete.currentMenuId, itemToDelete.currentCategoryId);
          setCombos(prevCombos => prevCombos.filter(combo => combo.id !== itemToDelete.id));
          break;
        default:
          break;
      }
      setShowConfirmationModal(false);
      await loadAndSelectLatestMenu();
    } catch (error) {
      console.error(`Error occurred while deleting ${itemToDelete.type}:`, error);
    }
  };

  // Function to preview menus
  const handlePreview = async () => {
    try {
      const menuDetails = await fetchLiveMenuDetails(selectedMenu);
      setPreviewData(menuDetails);
      setShowPreview(true);
    } catch (error) {
      console.error('Error during preview:', error);
    }
  };

  // Function to close the menu area assignment widget
  const closeMenuAreaAssignment = () => {
    setShowMenuAreaAssignment(false);
  };

  if (loading) {
    return (
      <SpinnerContainer>
        <CircularProgress size={60} color="secondary" />
      </SpinnerContainer>
    );
  }

  return (
    <>
      <SidebarWidget />
      <MenuHeader
        handlePreview={handlePreview}
        selectOptions={selectOptions}
        openMenuCreationSidebar={openMenuCreationSidebar}
        openEditMenuSidebar={openEditMenuSidebar}
        openDeleteConfirmation={openDeleteConfirmation}
        toggleMenuAreaAssignment={setShowMenuAreaAssignment}
        showMenuAreaAssignment={showMenuAreaAssignment}
        closeMenuAreaAssignment={closeMenuAreaAssignment}
        MenuAreaAssignmentWidget={MenuAreaAssignmentWidget}
        handleNewMenuCreated={handleNewMenuCreated}
      />

      {showPreview && <OnlineMenuModal />}

      {menus.length === 0 || showMenuWizard ? (
        <Dialog
          open={isWizardOpen}
          onClose={closeWizard}
          fullWidth
          maxWidth="md"
          PaperProps={{
            style: { height: '95%', display: 'flex', flexDirection: 'column', justifyContent: 'center' },
          }}
        >
          <DialogTitle>Create Your Menu</DialogTitle>
          <DialogContent>
            <MenuWizard onComplete={closeMenuWizard} />
          </DialogContent>
        </Dialog>
      ) : (
        <DragDropContext onDragEnd={onDragEnd}>
          <Container>
            <MenuOverviewContainer>
              <CategoryActions
                addCategoryOptions={addCategoryOptions}
                handleAddCategorySelection={handleAddCategorySelection}
                setCategoryActionSelection={setCategoryActionSelection}
                categoryActionSelection={categoryActionSelection}
                toggleGlobalExpansion={toggleGlobalExpansion}
                hasCategories={menuCategories.length > 0}
              />                
              <CategoryList
                expandedCategories={expandedCategories}
                expandedItems={expandedItems}
                toggleCategory={toggleCategory}
                toggleItem={toggleItem}
                handleAddCategorySelection={handleAddCategorySelection}
                handleAddMenuItemSelection={handleAddMenuItemSelection}
                openEditCategorySidebar={openEditCategorySidebar}
                openEditMenuItemSidebar={openEditMenuItemSidebar}
                openEditComboSidebar={openEditComboSidebar}
                openDeleteConfirmation={openDeleteConfirmation}
                setCategoryActionSelection={setCategoryActionSelection}
                categoryActionSelection={categoryActionSelection}
                setMenuItemActionSelection={setMenuItemActionSelection}
                menuItemActionSelection={menuItemActionSelection}
                addCategoryOptions={addCategoryOptions}
                addMenuItemOptions={addMenuItemOptions}
              />
            </MenuOverviewContainer>
          </Container>
        </DragDropContext>
      )}

      {showConfirmationModal && (
        <ConfirmationModal
          onClose={() => setShowConfirmationModal(false)}
          onConfirm={handleDeleteConfirmed}
        />
      )}
    </>
  );
};

export default MenuOverview;
