import React from 'react';
import styled from 'styled-components';
import { Typography } from '@mui/material';

// Styled components
const Container = styled.div`
  padding: 10% 10% 2% 10%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) { /* Tablets */
    padding: 10% 20% 2% 20%;
  }

  @media (min-width: 1024px) { /* Desktops */
    padding: 10% 35% 2% 35%;
  }
`;

const SuccessPage = () => {
  return (
    <Container>
      <Typography variant="h2" gutterBottom>Thank you!</Typography>
      <Typography variant="body1">
        Your registration is almost complete.<br/>
        Please check your inbox to verify your email<br/>
        and start your free trial.
      </Typography>
    </Container>
  );
};

export default SuccessPage;
