// Google Validate Address
export const validateAddress = async (address) => {
    const url = `https://addressvalidation.googleapis.com/v1:validateAddress?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
    const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ address }),
    });

    if (!response.ok) {
        throw new Error("Address validation failed.");
    }

    return response.json();
};