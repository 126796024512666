import React, { useState, useEffect, useCallback } from 'react';
import { useRestaurant } from '../../../contexts/RestaurantContext';
import { Button, Typography, Box, FormControl, InputLabel, MenuItem, Select, Alert } from '@mui/material';
import {
  fetchCategoriesAvailableForAssociation,
  fetchMenuItemsAvailableForAssociation,
  fetchCombosAvailableForAssociation,
  fetchCategoryDetails,
  updateCategoryMenus,
  fetchMenuItemDetails,
  updateMenuItemCategories,
  fetchComboDetails,
  updateComboCategories
} from '../../../services/apiService';

const AssignmentForms = ({ context, onClose, showSidebar }) => {
  const [availableCategories, setAvailableCategories] = useState([]);
  const [availableMenuItems, setAvailableMenuItems] = useState([]);
  const {
    selectedMenu, selectedCategory, setSelectedCategory, loadMenuCategories, loadMenuItems,
    loadCombos, selectedMenuItem, setSelectedMenuItem } = useRestaurant();

  const resetForm = useCallback(() => {
    setSelectedCategory('');
    setSelectedMenuItem('');
  }, [setSelectedCategory, setSelectedMenuItem]);

  useEffect(() => {
    const fetchCategories = async () => {
      const categories = await fetchCategoriesAvailableForAssociation(selectedMenu);
      setAvailableCategories(categories.map(category => ({ value: category.id, label: category.name })));
    };

    const fetchItemsAndCombos = async () => {
      const menuItems = await fetchMenuItemsAvailableForAssociation(selectedMenu, selectedCategory);
      const combos = await fetchCombosAvailableForAssociation(selectedMenu, selectedCategory);
      const combinedOptions = [
        ...menuItems.map(item => ({ value: item.id, label: item.name, type: 'item' })),
        ...combos.map(combo => ({ value: combo.id, label: combo.name, type: 'combo' }))
      ];
      setAvailableMenuItems(combinedOptions);
    };

    if (context === 'assignCategory') {
      fetchCategories();
    } else if (context === 'assignMenuItem') {
      fetchItemsAndCombos();
    }
  }, [context, selectedMenu, selectedCategory, showSidebar]);

  useEffect(() => {
    if (!showSidebar) {
      resetForm();
    }
  }, [showSidebar, resetForm]);

  const assignCategoryToMenu = async (event) => {
    event.preventDefault();

    if (!selectedCategory || !selectedMenu) {
      console.error("No category or menu selected");
      return;
    }

    try {
      const currentCategoryDetails = await fetchCategoryDetails(selectedCategory);
      const existingMenuIds = currentCategoryDetails.menus;
      const updatedMenuIds = Array.from(new Set([...existingMenuIds, parseInt(selectedMenu)])).filter(Boolean);

      await updateCategoryMenus(selectedCategory, { menus: updatedMenuIds });
      console.log("Category assigned to menu successfully");

      onClose();
      loadMenuCategories(selectedMenu);
      loadMenuItems(selectedMenu);
    } catch (error) {
      console.error('Error assigning category to menu:', error);
    }
  };

  const assignItemsToCategoryAndMenu = async (event) => {
    event.preventDefault();

    if (!selectedMenuItem || !selectedCategory) {
      console.error("No menu item or category selected");
      return;
    }

    try {
      let updatedCategoryIds = [];

      if (selectedMenuItem.type === 'item') {
        const currentItemDetails = await fetchMenuItemDetails(selectedMenuItem.value);
        const existingCategoryIds = currentItemDetails.categories;
        const newCategoryId = parseInt(selectedCategory);

        updatedCategoryIds = Array.from(new Set([...existingCategoryIds, newCategoryId])).filter(Boolean);
        await updateMenuItemCategories(selectedMenuItem.value, updatedCategoryIds);
        console.log("Menu item assigned to category successfully");
      } else if (selectedMenuItem.type === 'combo') {
        const currentComboDetails = await fetchComboDetails(selectedMenuItem.value);
        const existingCategoryIds = currentComboDetails.categories;
        const newCategoryId = parseInt(selectedCategory);

        updatedCategoryIds = Array.from(new Set([...existingCategoryIds, newCategoryId])).filter(Boolean);
        await updateComboCategories(selectedMenuItem.value, updatedCategoryIds);
        console.log("Combo assigned to category successfully");
      }

      onClose();
      loadMenuCategories(selectedMenu);
      loadMenuItems(selectedMenu);
      loadCombos(selectedMenu);
    } catch (error) {
      console.error('Error assigning item or combo:', error);
    }
  };

  return (
    <Box component="form" onSubmit={context === 'assignCategory' ? assignCategoryToMenu : assignItemsToCategoryAndMenu}>
      <Typography variant="h6">{context === 'assignCategory' ? 'Assign an Existing Category' : 'Assign an Existing Menu Item or Combo'}</Typography>
      {context === 'assignCategory' ? (
        <>
          {availableCategories.length === 0 ? (
            <Alert severity="warning" style={{ marginTop: '20px', marginBottom: '20px' }}>
              No categories available for association. You can only assign new categories from other menus.
            </Alert>
          ) : (
            <FormControl fullWidth margin="normal">
              <InputLabel>Select a Category</InputLabel>
              <Select
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
                label="Select a Category"
              >
                {availableCategories.map(category => (
                  <MenuItem key={category.value} value={category.value}>
                    {category.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </>
      ) : (
        <>
          {availableMenuItems.length === 0 ? (
            <Alert severity="warning" style={{ marginTop: '20px', marginBottom: '20px' }}>
              No menu items or combos available for association. You can only assign new items from different menus.
            </Alert>
          ) : (
            <FormControl fullWidth margin="normal">
              <InputLabel>Select a Menu Item or Combo</InputLabel>
              <Select
                value={selectedMenuItem}
                onChange={(e) => setSelectedMenuItem(e.target.value)}
                label="Select a Menu Item or Combo"
              >
                {availableMenuItems.map(item => (
                  <MenuItem key={item.value} value={item}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </>
      )}
      <Button
        variant="contained"
        color="primary"
        type="submit"
        disabled={(context === 'assignCategory' && availableCategories.length === 0) || (context !== 'assignCategory' && availableMenuItems.length === 0)}
      >
        {context === 'assignCategory' ? 'Assign Category' : 'Assign'}
      </Button>
    </Box>
  );
};

export default AssignmentForms;
