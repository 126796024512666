import React, { useState, useEffect } from 'react';
import { usePOS } from '../../../contexts/POSContext'; // Import the context
import { initiatePayment } from '../../../services/posApiService';
import { Typography, Tabs, Tab, TextField, Button, Snackbar, Alert, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import styled from 'styled-components';

const PaymentDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const ChangeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
`;

const MixedPaymentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  gap: 20px;
`;

const roundToNearest5Cents = (amount) => {
  return (Math.round(amount * 20) / 20).toFixed(2);
};

const PaymentWidget = ({ amountDue, orderId, onPaymentSuccess, paymentType, selectedItems, numOfSplits }) => {
  const { paymentMethod, setPaymentMethod } = usePOS(); // Access paymentMethod and setter from context
  const [amountPaid, setAmountPaid] = useState(amountDue);
  const [cardAmount, setCardAmount] = useState((amountDue / 2).toFixed(2));
  const [cashAmount, setCashAmount] = useState((amountDue / 2).toFixed(2));
  const [tip, setTip] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [tipDialogOpen, setTipDialogOpen] = useState(false);
  const [tipAmount, setTipAmount] = useState(0);

  useEffect(() => {
    if (paymentMethod === 'mixed') {
      const total = parseFloat(amountDue);
      setCardAmount((total / 2).toFixed(2));
      setCashAmount((total / 2).toFixed(2));
    }
  }, [paymentMethod, amountDue]);

  useEffect(() => {
    setAmountPaid(amountDue);
  }, [amountDue]);

  const handleAmountChange = (setter, round = false) => (e) => {
    let value = parseFloat(e.target.value);
    if (round) {
      value = parseFloat(roundToNearest5Cents(value));
    } else {
      value = value.toFixed(2);
    }
    setter(isNaN(value) ? 0 : value);
  };

  const handleTipDialogOpen = () => setTipDialogOpen(true);
  const handleTipDialogClose = () => setTipDialogOpen(false);

  const handleTipChange = (e) => {
    let value = parseFloat(e.target.value);
    value = parseFloat(roundToNearest5Cents(value));
    setTipAmount(isNaN(value) ? 0 : value);
  };

  const handleTipConfirm = () => {
    setTip(parseFloat(tipAmount).toFixed(2));
    handleTipDialogClose();
  };

  const calculateExcessAmount = (paid) => {
    const total = parseFloat(amountDue);
    const paidAmount = parseFloat(paid);
    return (paidAmount - total).toFixed(2);
  };

  const handlePayment = async () => {
    try {
      const totalPaid = paymentMethod === 'mixed' ? parseFloat(cardAmount) + parseFloat(cashAmount) : parseFloat(amountPaid);
      const excessAmount = calculateExcessAmount(totalPaid);

      let change = 0;
      let tipToSend = parseFloat(tip).toFixed(2);

      if (paymentMethod === 'digital') {
        tipToSend = parseFloat(excessAmount) > 0 ? parseFloat(excessAmount).toFixed(2) : '0.00';
      } else if (paymentMethod === 'cash' || paymentMethod === 'mixed') {
        change = parseFloat(excessAmount) > 0 ? (parseFloat(excessAmount) - parseFloat(tipToSend)).toFixed(2) : '0.00';
      }

      const paymentData = {
        payment_option: paymentType,
        payment_method: paymentMethod,
        amount_paid: totalPaid.toFixed(2),
        change: change,
        tip: tipToSend,
        card_amount: paymentMethod === 'mixed' ? parseFloat(cardAmount).toFixed(2) : (paymentMethod === 'digital' ? totalPaid.toFixed(2) : '0.00'),
        cash_amount: paymentMethod === 'mixed' ? parseFloat(cashAmount).toFixed(2) : '0.00',
        number_of_splits: numOfSplits,
        selected_items: selectedItems
      };

      const response = await initiatePayment(orderId, paymentData);
      console.log('Payment successful:', response);
      onPaymentSuccess(response); // Callback function to handle successful payment
    } catch (error) {
      console.error('Error initiating payment:', error);
      setSnackbarMessage('Error initiating payment. Please try again.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => setSnackbarOpen(false);

  return (
    <PaymentDetailsContainer>
      <Typography style={{ margin: '20px' }} variant="h3">Amount Due: €{amountDue}</Typography>
      <Typography style={{ margin: '20px' }} variant="h6">Payment Method</Typography>
      <Tabs
        value={paymentMethod}
        onChange={(event, newValue) => setPaymentMethod(newValue.toLowerCase())}
        indicatorColor="primary"
        textColor="primary"
        aria-label="payment method tabs"
      >
        <Tab label="Card" value="digital" />
        <Tab label="Cash" value="cash" />
        <Tab label="Mixed" value="mixed" />
      </Tabs>
      <Typography variant="h6" style={{ margin: '20px' }}>Amount Paid</Typography>
      {paymentMethod === 'mixed' ? (
        <MixedPaymentContainer>
          <TextField
            label="Card Amount"
            type="number"
            fullWidth
            value={cardAmount}
            onChange={handleAmountChange(setCardAmount)}
            InputProps={{ inputProps: { min: 1 }, onFocus: (e) => e.target.select() }}
            autoFocus
          />
          <TextField
            label="Cash Amount"
            type="number"
            fullWidth
            value={cashAmount}
            onChange={handleAmountChange(setCashAmount, true)} // Round to nearest 5 cents
            InputProps={{ inputProps: { min: 1 }, onFocus: (e) => e.target.select() }}
            autoFocus
          />
        </MixedPaymentContainer>
      ) : (
        <TextField
          label="Amount"
          type="number"
          fullWidth
          value={amountPaid}
          onChange={handleAmountChange(setAmountPaid, paymentMethod === 'cash')} // Round to nearest 5 cents if cash
          InputProps={{ inputProps: { min: 0 }, onFocus: (e) => e.target.select() }}
          autoFocus
        />
      )}
      {paymentMethod === 'digital' && (
        <ChangeContainer>
          {parseFloat(calculateExcessAmount(amountPaid)) > 0 && (
            <Typography color="secondary" style={{ fontWeight: 'bold' }}>
              Tip: €{calculateExcessAmount(amountPaid)}
            </Typography>
          )}
        </ChangeContainer>
      )}
      {(paymentMethod === 'cash' || paymentMethod === 'mixed') && (
        <ChangeContainer>
          {parseFloat(calculateExcessAmount(paymentMethod === 'mixed' ? parseFloat(cardAmount) + parseFloat(cashAmount) : amountPaid)) > 0 && (
            <Typography color="secondary" style={{ fontWeight: 'bold' }}>
              Change: €{(parseFloat(calculateExcessAmount(paymentMethod === 'mixed' ? parseFloat(cardAmount) + parseFloat(cashAmount) : amountPaid)) - tip).toFixed(2)}
            </Typography>
          )}
          {parseFloat(calculateExcessAmount(paymentMethod === 'mixed' ? parseFloat(cardAmount) + parseFloat(cashAmount) : amountPaid)) > 0 && (
            <Typography
              color="primary"
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={handleTipDialogOpen}
            >
              {tip > 0 ? `Tip: €${parseFloat(tip).toFixed(2)}` : 'Add tip'}
            </Typography>
          )}
        </ChangeContainer>
      )}
      <Button
        variant="contained"
        color="primary"
        fullWidth
        style={{ marginTop: '20px' }}
        onClick={handlePayment}
      >
        Confirm Payment
      </Button>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Dialog open={tipDialogOpen} onClose={handleTipDialogClose}>
        <DialogTitle>Add Tip</DialogTitle>
        <DialogContent style={{ paddingLeft: '60px', paddingRight: '60px' }}>
          <TextField
            label="Tip Amount"
            type="number"
            style={{ marginTop: '10px' }}
            fullWidth
            value={tipAmount}
            onChange={handleTipChange}
            InputProps={{ inputProps: { min: 0, step: 0.05, max: parseFloat(calculateExcessAmount(paymentMethod === 'mixed' ? parseFloat(cardAmount) + parseFloat(cashAmount) : amountPaid)) }, onFocus: (e) => e.target.select() }}
          />
        </DialogContent>
        <DialogActions style={{ marginLeft: '50px', marginRight: '50px' }}>
          <Button onClick={handleTipDialogClose}>Cancel</Button>
          <Button onClick={handleTipConfirm} variant="contained" color="primary">Confirm</Button>
        </DialogActions>
      </Dialog>
    </PaymentDetailsContainer>
  );
};

export default PaymentWidget;
