import React, { useState, useEffect } from 'react';
import { useRestaurant } from '../../../contexts/RestaurantContext';
import { updateMenu, createMenu } from '../../../services/apiService';
import { TextField, Button, Typography, Box } from '@mui/material';

const MenuForm = ({ context, onClose }) => {
  const [newMenuName, setNewMenuName] = useState('');
  const { showSidebar, selectedRestaurant, selectedArea, editingMenu, addNewMenu, updateEditedMenu, reloadMenus } = useRestaurant();

  const resetForm = () => {
    setNewMenuName('');
  };

  useEffect(() => {
    if (context === 'editMenu' && editingMenu) {
      setNewMenuName(editingMenu.name);
    } else {
      resetForm();
    }
  }, [context, editingMenu, showSidebar]);

  useEffect(() => {
    if (!showSidebar) {
      resetForm();
    }
  }, [showSidebar]);

  const handleCreateOrUpdateMenu = async (event) => {
    event.preventDefault();
    if (!newMenuName.trim()) {
      console.error("Menu name cannot be empty");
      return;
    }

    let response;

    const menuData = {
      name: newMenuName,
      restaurant: selectedRestaurant,
      area: selectedArea,
    };

    try {
      if (context === 'editMenu' && editingMenu) {
        response = await updateMenu(editingMenu.id, menuData, selectedArea);
        updateEditedMenu(response);
      } else {
        response = await createMenu(menuData);
        addNewMenu(response);
      }
    } catch (error) {
      console.error('Error creating/updating menu:', error);
    }

    resetForm();
    onClose();
    reloadMenus();
  };

  return (
    <Box component="form" onSubmit={handleCreateOrUpdateMenu}>
      <Typography variant="h6">{context === 'editMenu' ? 'Edit Menu' : 'Add a New Menu'}</Typography>
      <TextField
        fullWidth
        variant="outlined"
        label="Menu Name"
        placeholder="e.g. Lunch Menu"
        value={newMenuName}
        onChange={(e) => setNewMenuName(e.target.value)}
        margin="normal"
      />
      <Button variant="contained" color="primary" type="submit">
        {context === 'editMenu' ? 'Update Menu' : 'Create Menu'}
      </Button>
    </Box>
  );
};

export default MenuForm;
