// authApiService.js
import api from './apiInstance'; // Import the Axios instance with interceptors

const API_BASE_URL = process.env.REACT_APP_API_URL;

// Function to register user
export const registerUser = (userData) => {
  return api.post(`${API_BASE_URL}auth/register/`, userData)
    .then(response => response.data);
};

// Function to get an email to reset password
export const resetPasswordEmail = async (email) => {
  const url = `${API_BASE_URL}auth/password_reset/`;
  return api.post(url, { email }).then(response => response.data);
};

// Function to verify email
export const verifyEmail = (uuid) => {
  return api.get(`${API_BASE_URL}auth/verify_email/${uuid}/`);
};

// Function to reset password
export const resetPassword = (resetPasswordEndpoint, newPasswordData) => {
  return api.post(resetPasswordEndpoint, newPasswordData);
};

// Function to update Staff Passcode
export const updateStaffPasscode = async (newPasscode) => {
  const url = `${API_BASE_URL}auth/update-passcode/`;
  const payload = {
    new_passcode: newPasscode
  };

  try {
    const response = await api.post(url, payload);
    return response.data;
  } catch (error) {
    console.error('Error updating passcode:', error.response?.data);
    throw error;
  }
};

// Function to create a Stripe connected account
export const createConnectedAccount = async (restaurantId) => {
  const url = `${API_BASE_URL}auth/create-connected-account/`;

  try {
    const response = await api.post(url, { restaurant_id: restaurantId });
    return response.data;
  } catch (error) {
    console.error('Error creating connected account:', error);
    throw error;
  }
};

// Function to create a Stripe account session
export const createAccountSession = async (restaurantId) => {
  const url = `${API_BASE_URL}auth/create-account-session/`;

  try {
    const response = await api.post(url, { restaurant_id: restaurantId });
    return response.data;
  } catch (error) {
    console.error('Error creating account session:', error);
    throw error;
  }
};

// Function to fetch user profile
export const fetchUserProfile = async () => {
  const url = `${API_BASE_URL}auth/user/profile/`;

  try {
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching user profile:', error);
    throw error;
  }
};

// Function to update user's default restaurant
export const updateUserDefaultRestaurant = async (restaurantId) => {
  const url = `${API_BASE_URL}auth/user/profile/`;

  try {
    await api.patch(url, {
      default_restaurant: restaurantId
    });
  } catch (error) {
    console.error('Error updating default restaurant:', error);
    throw error; // Re-throw the error for handling in the component
  }
};
