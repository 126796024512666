import api from './apiInstance'; // Import the Axios instance with interceptors

// Use environment variable for API base URL
const API_BASE_URL = process.env.REACT_APP_API_URL;

// Function to fetch restaurants
export const fetchUserRestaurants = async () => {
  try {
    const response = await api.get(`${API_BASE_URL}api/restaurants/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching user restaurants:', error);
    throw error;
  }
};

// Function to fetch menus for a specific restaurant
export const fetchMenusForRestaurant = async (restaurantId) => {
  const response = await api.get(`${API_BASE_URL}api/menu/?restaurant_id=${restaurantId}`);
  return response.data;
};

// Function to fetch menus
export const fetchMenus = async () => {
  const url = `${API_BASE_URL}api/menu/`;
  try {
    const response = await api.get(url, );
    return response.data;
  } catch (error) {
    // handle error
    console.error('Error fetching menus:', error);
    throw error; // Re-throw the error for handling in the component
  }
};

// Function to fetch menu items by category
export const fetchMenuItemsByCategory = async (categoryId) => {
  const url = `${API_BASE_URL}api/menu-items/?category_id=${categoryId}`;

  try {
    const response = await api.get(url, );
    return response.data;
  } catch (error) {
    console.error('Error fetching menu items by category:', error);
    throw error;
  }
};

// Function to fetch menu items and combos by category
export const fetchMenuItemsAndCombosByCategory = async (categoryId) => {
  const url = `${API_BASE_URL}api/menu-items-and-combos/${categoryId}/`;

  try {
    const response = await api.get(url, );
    return response.data;
  } catch (error) {
    console.error('Error fetching menu items and combos by category:', error);
    throw error;
  }
};

// Function to fetch details for a specific category, including its associated menus
export const fetchCategoryDetails = async (categoryId) => {
  try {
    const url = `${API_BASE_URL}api/menu-categories/${categoryId}/`; // Note the specific category ID in the path

    const response = await api.get(url);

    return response.data; // This should include the category details along with associated menus
  } catch (error) {
    console.error(`Error fetching details for category ${categoryId}:`, error);
    throw error;
  }
};

// Function to fetch categories for a specific restaurant, optionally filtering out categories already assigned to a selected menu
export const fetchCategoriesForRestaurant = async (restaurantId, selectedMenuId = null) => {
  try {
    // Construct the base URL with required restaurant_id query parameter
    let url = `${API_BASE_URL}api/menu-categories/?restaurant_id=${restaurantId}`;

    // Append selectedMenuId as a query parameter if provided
    if (selectedMenuId !== null) {
      url += `&selectedMenuId=${selectedMenuId}`;
    }

    const response = await api.get(url);

    return response.data;
  } catch (error) {
    console.error('Error fetching categories for restaurant:', error);
    throw error;
  }
};

// Function to fetch categories available for association
export const fetchCategoriesAvailableForAssociation = async (selectedMenu) => {
  const url = `${API_BASE_URL}api/menu-categories/available_for_association/?selectedMenu=${selectedMenu}`;

  try {
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching categories available for association:', error);
    throw error;
  }
};

// Function to fetch menu items for a specific restaurant, optionally filtered by category
export const fetchMenuItemsForRestaurant = async (restaurantId, selectedCategoryId = null) => {
  let url = `${API_BASE_URL}api/menu-items/?restaurant_id=${restaurantId}`;

  if (selectedCategoryId !== null) {
    url += `&category_id=${selectedCategoryId}`;
  }

  try {
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching menu items for restaurant:', error);
    throw error;
  }
};

// Function to fetch menu items available for association
export const fetchMenuItemsAvailableForAssociation = async (selectedMenu, selectedCategory) => {
  let url = `${API_BASE_URL}api/menu-items/available_for_association/?selectedMenu=${selectedMenu}`;

  if (selectedCategory) {
    url += `&selectedCategory=${selectedCategory}`;
  }

  try {
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching menu items available for association:', error);
    throw error;
  }
};

// Function to dissociate a category from a menu
export const dissociateCategoryFromMenu = async (category_id, menu_id) => {
    try {
        const response = await api.post(`${API_BASE_URL}api/menu-category/${category_id}/dissociate-menu/`, { menu_id }, );
        return response.data;
    } catch (error) {
        console.error('Error dissociating category from menu:', error);
        throw error;
    }
};

// Function to fetch a single category by ID including its menu associations
export const fetchCategoryById = async (categoryId) => {
  const url = `${API_BASE_URL}api/menu-categories/${categoryId}/`;
  
  try {
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    console.error(`Error fetching category by ID: ${categoryId}`, error);
    throw error;
  }
};

// Function to fetch Combo details
export const fetchComboDetails = async (comboId) => {
  const url = `${API_BASE_URL}api/combos/${comboId}/get_combo_by_id/`;

  try {
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching combo details:', error);
    throw error;
  }
};

// Function to create a restaurant
export const createRestaurant = async (restaurantData) => {
  try {
    const response = await api.post(`${API_BASE_URL}api/restaurants/`, restaurantData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Necessary for handling files and nested JSON
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error creating restaurant:', error);
    throw error;
  }
};

// Function to update an existing restaurant
export const updateRestaurant = async (restaurantId, restaurantData) => {
  try {
    const response = await api.put(`${API_BASE_URL}api/restaurants/${restaurantId}/`, restaurantData);
    return response.data;
  } catch (error) {
    console.error('Error updating restaurant:', error);
    throw error;
  }
};

// Function to delete an existing restaurant
export const deleteRestaurant = async (restaurantId) => {
  const url = `${API_BASE_URL}api/restaurants/${restaurantId}/`;

  try {
    await api.delete(url);
  } catch (error) {
    console.error('Error deleting restaurant:', error);
    throw error;
  }
};

// Function to fetch areas for a specific restaurant
export const fetchAreasForRestaurant = async (restaurantId) => {
  const response = await api.get(`${API_BASE_URL}api/areas/?restaurant_id=${restaurantId}`);
  return response.data;
};

// Function to fetch the details of an existing restaurant area
export const fetchArea = async (areaId) => {
  const url = `${API_BASE_URL}api/areas/${areaId}/`;

  try {
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching area:', error);
    throw error;
  }
};

// Function to create a new restaurant area
export const createArea = async (areaData, restaurantId) => {
  const url = `${API_BASE_URL}api/areas/`; // Adjust the endpoint as per your actual API URL
  const areaDataWithRestaurant = { ...areaData, restaurant: restaurantId };

  try {
    const response = await api.post(url, areaDataWithRestaurant);
    return response.data;
  } catch (error) {
    console.error('Error creating area:', error);
    throw error;
  }
};

// Function to update an existing restaurant area
export const updateArea = async (areaId, updatedAreaData) => {
  const url = `${API_BASE_URL}api/areas/${areaId}/`;

  try {
    const response = await api.put(url, updatedAreaData);
    return response.data;
  } catch (error) {
    console.error('Error updating area:', error);
    throw error;
  }
};

// Function to delete an existing restaurant area
export const deleteArea = async (areaId) => {
  const url = `${API_BASE_URL}api/areas/${areaId}/`;

  try {
    const response = await api.delete(url);
    return response.data;
  } catch (error) {
    console.error('Error deleting area:', error);
    throw error;
  }
};

// Function to fetch tables for a specific area, including QR code data
export const fetchTablesForArea = async (areaId) => {
  const response = await api.get(`${API_BASE_URL}api/tables/?area=${areaId}`);
  return response.data;
};

// Function to fetch tables for a specific restaurant, including QR code data
export const fetchAllTablesForRestaurant = async (restaurantId) => {
  const response = await api.get(`${API_BASE_URL}api/tables/?restaurant_id=${restaurantId}`);
  return response.data; // Include unique identifiers for each table
};

// Function to create a new table for a given area
export const addTableToArea = async (areaId) => {
  const url = `${API_BASE_URL}api/areas/${areaId}/add-table/`;

  try {
    const response = await api.post(url, {});
    return response.data.table;
  } catch (error) {
    console.error('Error adding table to area:', error);
    throw error;
  }
};

// Function to create multiple tables within an area
export const createMultipleTables = async (tables, areaId) => {
  const url = `${API_BASE_URL}api/areas/${areaId}/create-tables/`;
  try {
    const response = await api.post(url, { tables });
    return response.data;
  } catch (error) {
    console.error('Error creating multiple tables:', error);
    throw error;
  }
};

// Function to update an existing table
export const updateTable = async (tableId, updatedTableData) => {
  const url = `${API_BASE_URL}api/tables/${tableId}/`;

  try {
    const response = await api.put(url, updatedTableData);
    return response.data;
  } catch (error) {
    console.error('Error updating table:', error);
    throw error;
  }
};

// Function to update multiple tables' positions in bulk
export const updateTablePositions = async (tables) => {
    const url = `${API_BASE_URL}api/tables/update-positions/`;
    const payload = {
        tables: tables.map(table => ({
            id: table.id,
            position_x: table.position_x,
            position_y: table.position_y
        }))
    };

    try {
        const response = await api.post(url, payload);
        return response.data;
    } catch (error) {
        console.error('Error updating table positions:', error.response?.data);
        throw error;
    }
};

// Function to delete a table
export const deleteTable = async (tableId) => {
  const url = `${API_BASE_URL}api/tables/${tableId}/`;

  try {
    await api.delete(url);
  } catch (error) {
    console.error('Error deleting table:', error);
    throw error;
  }
};

// Function to create a menu with AI
export const createMenuWithAI = async (prompt) => {
  const url = `${API_BASE_URL}api/menus/create-with-ai/`;  // Adjust if necessary

  try {
    const response = await api.post(url, { prompt });
    return response.data;
  } catch (error) {
    console.error('Error creating menu with AI:', error);
    throw error;
  }
};

// Function to create a new menu
export const createMenu = async (newMenuData) => {
  const url = `${API_BASE_URL}api/menu/`;

  try {
    const response = await api.post(url, newMenuData);
    return response.data;
  } catch (error) {
    console.error('Error creating menu:', error);
    throw error;
  }
};

// Function to update an existing menu with area and active timeframe
export const updateMenu = async (menuId, updatedMenuData, areaId) => {
  const url = `${API_BASE_URL}api/menu/${menuId}/`;
  if (areaId) updatedMenuData.area = areaId; // Update area association if provided

  try {
    const response = await api.patch(url, updatedMenuData);
    return response.data;
  } catch (error) {
    console.error('Error updating menu:', error.response ? error.response.data : error);
    throw error;
  }
};

// Function to delete or dissociate a menu based on its categories and items/combos associations
export const deleteOrDissociateMenu = async (menuId) => {
  try {
    const response = await api.delete(`${API_BASE_URL}api/menu/${menuId}/delete-or-dissociate/`);
    return response.data; // This will include the action taken ('deleted' or 'dissociated')
  } catch (error) {
    console.error('Error deleting or dissociating menu:', error);
    throw error;
  }
};

// Function to create and update a menu category
export const handleMenuCategory = async (categoryData, categoryId = null) => {


  try {
    let response;

    if (categoryId) {
      // If updating an existing category
      response = await api.patch(`${API_BASE_URL}api/menu-categories/${categoryId}/`, categoryData);
    } else {
      // For creating a new category, ensuring categoryData.menus is handled
      response = await api.post(`${API_BASE_URL}api/menu-categories/`, categoryData, );
    }

    return response.data;
  } catch (error) {
    console.error(`Error ${categoryId ? 'updating' : 'creating'} category:`, error);
    throw error;
  }
};

// Function to get category details
export const getCategoryDetails = async (categoryId) => {
  try {
    const response = await api.get(`${API_BASE_URL}api/menu-categories/${categoryId}/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching category details:', error);
    throw error;
  }
};

// Function to fetch menu categories based on menu
export const fetchMenuCategories = async (menuId) => {
  const url = `${API_BASE_URL}api/menu-categories/?menu_id=${menuId}`;
  
  
  try {
    const response = await api.get(url, );
    return response.data;
  } catch (error) {
    console.error('Error fetching menu categories:', error);
    throw error;
  }
};

// Function to delete a menu category
export const deleteMenuCategory = async (categoryId) => {
  const url = `${API_BASE_URL}api/menu-categories/${categoryId}/`;

  try {
    await api.delete(url);
  } catch (error) {
    console.error('Error deleting menu category:', error);
    throw error;
  }
};

// Function to delete or dissociate a menu category based on its associations
export const deleteOrDissociateCategory = async (categoryId, currentMenuId) => {
  try {
    const response = await api.delete(`${API_BASE_URL}api/menu-categories/${categoryId}/delete-or-dissociate/`, {
      data: { current_menu_id: currentMenuId }, // Include current menu ID in the request body for dissociation logic
    });
    return response.data; // This will include the action taken ('deleted' or 'dissociated')
  } catch (error) {
    console.error('Error deleting or dissociating menu category:', error);
    throw error;
  }
};

// Function to update the category with the new menu
export const updateCategoryMenus = async (categoryId, categoryData) => {


  try {
    const response = await api.patch(`${API_BASE_URL}api/menu-categories/${categoryId}/`, categoryData, );
    return response.data;
  } catch (error) {
    console.error(`Error updating category with new menu:`, error);
    throw error;
  }
};

// Function to fetch details for a specific menu item, including its associated categories
export const fetchMenuItemDetails = async (itemId) => {
  try {
    const url = `${API_BASE_URL}api/menu-items/${itemId}/get_item_by_id/`;

    const response = await api.get(url);

    return response.data; // This should include the menu item details along with associated categories
  } catch (error) {
    console.error(`Error fetching details for menu item ${itemId}:`, error);
    throw error;
  }
};

// Function to create and update a menu item
export const handleMenuItem = async (menuItemData, itemId = null) => {
  let url = `${API_BASE_URL}api/menu-items/`;

  try {
    let response;
    if (itemId) {
      // Update existing menu item
      url += `${itemId}/`;
      response = await api.patch(url, menuItemData);
    } else {
      // Create new menu item
      response = await api.post(url, menuItemData);
    }
    return response.data;
  } catch (error) {
    console.error(`Error ${itemId ? 'updating' : 'creating'} menu item:`, error);
    throw error;
  }
};

// Function to delete or dissociate a menu item based on its associations
export const deleteOrDissociateMenuItem = async (itemId, currentCategoryId) => {
  const url = `${API_BASE_URL}api/menu-items/${itemId}/delete-or-dissociate/`;

  try {
    const response = await api.delete(url, {
      data: {
        current_category_id: currentCategoryId,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting or dissociating menu item:', error);
    throw error;
  }
};

// Function to update the menu item with new categories
export const updateMenuItemCategories = async (itemId, updatedCategoryIds) => {

  try {
    const response = await api.patch(`${API_BASE_URL}api/menu-items/${itemId}/update_categories/`, 
      { categories: updatedCategoryIds });
    return response.data;
  } catch (error) {
    console.error(`Error updating menu item with new categories:`, error);
    throw error;
  }
};

// Function to update the combo with new categories
export const updateComboCategories = async (comboId, updatedCategoryIds) => {

  try {
    const response = await api.patch(`${API_BASE_URL}api/combos/${comboId}/update_combo_categories/`, 
      { categories: updatedCategoryIds });
    return response.data;
  } catch (error) {
    console.error(`Error updating combo with new categories:`, error);
    throw error;
  }
};

// Function to fetch allergens
export const fetchAllergens = async () => {
  try {
    const response = await api.get(`${API_BASE_URL}api/allergens/`, );
    return response.data;
  } catch (error) {
    console.error('Error fetching allergens:', error);
    throw error;
  }
};

// Function to fetch diet groups
export const fetchDietGroups = async () => {
  try {
    const response = await api.get(`${API_BASE_URL}api/diet-groups/`, );
    return response.data;
  } catch (error) {
    console.error('Error fetching diet groups:', error);
    throw error;
  }
};

// Function to update the order of menu categories and items
export const updateMenuOrder = async (orderData) => {
  const url = `${API_BASE_URL}api/update-menu-order/`;

  try {
    const response = await api.post(url, orderData);
    return response.data;
  } catch (error) {
    console.error('Error updating menu order:', error);
    throw error;
  }
};

// Function to assign menus to restaurant's areas
export const assignMenuToRestaurantAndArea = async (selectedMenu, areaIds) => {
  const promises = areaIds.map(async (areaId) => {
    const url = `${API_BASE_URL}api/areas/${areaId}/add_menu/`; // URL pattern defined for adding a menu to an area
    const data = { menu_id: selectedMenu };
    return api.post(url, data);
  });

  try {
    // Await all promises and return a success response if all succeed
    await Promise.all(promises);
    return { status: 200 };
  } catch (error) {
    console.error('Error assigning menu to areas:', error);
    throw error;
  }
};

// Function to fetch combos by category
export const fetchCombosByCategory = async (categoryId) => {
  const url = `${API_BASE_URL}api/combos/?category_id=${categoryId}`;
  
  
  try {
    const response = await api.get(url, );
    return response.data;
  } catch (error) {
    console.error('Error fetching menu items by category:', error);
    throw error;
  }
};

export const fetchCombosForRestaurant = async (restaurantId, selectedCategoryId = null) => {
  let url = `${API_BASE_URL}api/combos/?restaurant_id=${restaurantId}`;

  if (selectedCategoryId !== null) {
    url += `&category_id=${selectedCategoryId}`;
  }

  try {
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching combos:', error);
    throw error;
  }
};

// Function to fetch combos available for association
export const fetchCombosAvailableForAssociation = async (selectedMenu, selectedCategory) => {
  let url = `${API_BASE_URL}api/combos/available_for_association/?selectedMenu=${selectedMenu}`;

  if (selectedCategory) {
    url += `&selectedCategory=${selectedCategory}`;
  }

  try {
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching combos available for association:', error);
    throw error;
  }
};

export const createCombo = async (data) => {

  // Do not manually set Content-Type for FormData
  return api.post(`${API_BASE_URL}api/combos/`, data);
};

export const updateCombo = async (id, data) => {

  // Do not manually set Content-Type for FormData
  return api.patch(`${API_BASE_URL}api/combos/${id}/`, data);
};

export const deleteCombo = async (id) => {
  return api.delete(`${API_BASE_URL}api/combos/${id}/`);
};

// Function to delete or dissociate a combo based on its associations
export const deleteOrDissociateCombo = async (comboId, currentCategoryId) => {
  const url = `${API_BASE_URL}api/combos/${comboId}/delete-or-dissociate/`;

  try {
    const response = await api.delete(url, {
      data: {
        current_category_id: currentCategoryId,
      }, 
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting or dissociating combo:', error);
    throw error;
  }
};

// Function to fetch Staff roles
export const fetchRoles = async () => {
  try {
    const response = await api.get(`${API_BASE_URL}api/roles/`, );
    return response.data;
  } catch (error) {
    console.error('Error fetching roles:', error);
    throw error;
  }
};

// Function to add a staff member
export const addStaffMember = async (staffData) => {
  const url = `${API_BASE_URL}api/add_staff/`;
  try {
    const response = await api.post(url, staffData);
    return response.data;
  } catch (error) {
    console.error('Error adding staff member:', error);
    throw error;
  }
};

// Function to fetch all staff members
export const fetchStaffMembers = async () => {
  const url = `${API_BASE_URL}api/staff/`;
  try {
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching staff members:', error);
    throw error;
  }
};

// Function to udpate staff members
export const updateStaffMember = async (id, data) => {
  const url = `${API_BASE_URL}api/staff/${id}/`;
  try {
    const response = await api.patch(url, data);
    return response.data;
  } catch (error) {
    console.error('Error updating staff member:', error);
    throw error;
  }
};

// Function to delete a staff member
export const deleteStaffMember = async (id) => {
  const url = `${API_BASE_URL}api/staff/${id}/`;
  try {
    const response = await api.delete(url);
    return response.status === 204;  // Return true if deletion was successful
  } catch (error) {
    console.error('Error deleting staff member:', error);
    throw error;
  }
};

// Function to fetch all orders for the restaurant
export const fetchOrdersForRestaurant = async (restaurantId) => {
  try {
    const response = await api.get(`${API_BASE_URL}api/orders/`, {
      params: { restaurant_id: restaurantId }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching all orders:', error);
    throw error;
  }
};

// Function to fetch analytics data
export const fetchAnalyticsData = async (startDate, endDate) => {
  const url = `${API_BASE_URL}api/analytics/`;
  const params = {};

  // Format the dates as ISO strings (or any other format your backend expects)
  if (startDate) params.start_date = startDate.toISOString().split('T')[0];  // Format as YYYY-MM-DD
  if (endDate) params.end_date = endDate.toISOString().split('T')[0];        // Format as YYYY-MM-DD

  try {
    const response = await api.get(url, {
      params: params,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching analytics data:', error);
    throw error;
  }
};

// Function to get AI-generated insights
export const getAIInsights = async (analyticsData) => {
  const url = `${API_BASE_URL}api/analytics/get-insights/`;

  try {
    const response = await api.post(url, { analytics_data: analyticsData });
    return response.data;
  } catch (error) {
    console.error('Error getting AI insights:', error);
    throw error;
  }
};

