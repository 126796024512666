import React from 'react';
import { Routes, Route } from 'react-router-dom';
import POSLogin from './POSLogin';
import RoleSelection from './RoleSelection';
import POSDashboard from './POSDashboard'
import SessionExpired from './SessionExpired';
// Import other operational components

function POS() {
  return (
    <div>
      {/* POS Navigation and Quick Access Buttons */}
      <Routes>
        <Route path="clock-in" element={<POSLogin />} />
        <Route path="role-selection" element={<RoleSelection />} />
        <Route path="dashboard/*" element={<POSDashboard />} />
        {/* Define other routes for operational components */}
        <Route path="/session-expired" element={<SessionExpired />} />
      </Routes>
    </div>
  );
}

export default POS;
