// ReservationWidget v2.00
import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Box, Typography, Stepper, Step, StepLabel, Card, CardActionArea, CardContent, Snackbar, Stack,
  Alert, Paper, TextField, Grid, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
} from '@mui/material';
import { CalendarToday, AccessTime, People, CheckCircle } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import dayjs from 'dayjs';
import { createReservation, updateReservation, cancelReservation, fetchAvailableDates, fetchAvailableTimes } from '../../services/usersApiService';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

const steps = ['Guests', 'Date', 'Time', 'Confirmation'];

// Custom connector styles
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 20,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 2,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
}));

// Custom step icon
const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: ownerState.active ? '#ff6a3e' : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 38,
  height: 38,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',

  ...(ownerState.completed && {
    backgroundColor: '#3e3eff',
    cursor: 'pointer',
  }),
}));

const stepIcons = {
  1: <People sx={{ fontSize: '18px' }} />,
  2: <CalendarToday sx={{ fontSize: '18px' }} />,
  3: <AccessTime sx={{ fontSize: '20px' }} />,
  4: <CheckCircle sx={{ fontSize: '18px' }} />,
};

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {stepIcons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const ReservationWidget = ({ reservationData = null, restaurantId }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [formValues, setFormValues] = useState({
    reservation_date: reservationData ? dayjs(reservationData.reservation_date).toDate() : null, // Ensure we pass a Date object
    reservation_time: reservationData ? reservationData.reservation_time : '',
    number_of_guests: reservationData ? reservationData.number_of_guests : '',
    customer_name: reservationData ? reservationData.customer_name : '',
    customer_email: reservationData ? reservationData.customer_email : '',
    special_requests: reservationData ? reservationData.special_requests : '',
  });
  const [availableDates, setAvailableDates] = useState([]);
  const [availableTimes, setAvailableTimes] = useState([]);
  const [alert, setAlert] = useState({ open: false, message: '', severity: 'success' });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [reservationId, setReservationId] = useState(reservationData ? reservationData.id : null);
  const resolvedRestaurantId = reservationData ? reservationData.restaurant : restaurantId.id || restaurantId; // reservationData.restaurant expected to be an Integer
  const [phoneNumber, setPhoneNumber] = useState('');
  const [showSummary, setShowSummary] = useState(false);
  const theme = useTheme();
  const title = reservationData ? "Manage your reservation" : `Book a table at ${restaurantId.name}`;
  const subtitle = reservationData ? `We'll send the details to ${reservationData.restaurant_name}` : "It's completely free";

  useEffect(() => {
    // Pre-fill data if editing existing reservation
    if (reservationData) {
      setFormValues({
        reservation_date: dayjs(reservationData.reservation_date).toDate(),
        reservation_time: reservationData.reservation_time,
        number_of_guests: reservationData.number_of_guests,
        customer_name: reservationData.customer_name || '',
        customer_email: reservationData.customer_email || '',
        special_requests: reservationData.special_requests || '',
      });
      setReservationId(reservationData.id); // Save the reservation ID
    }
  }, [reservationData]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleSelection = async (name, value) => {
    setFormValues((prev) => ({ ...prev, [name]: value }));

    if (name === 'number_of_guests') {
      // Fetch dates available for the specified guest count
      try {
        const dates = await fetchAvailableDates(resolvedRestaurantId, value);
        setAvailableDates(dates);
      } catch (error) {
        console.error('Error fetching dates:', error);
      }
      handleNext();
    } else if (name === 'reservation_date') {
      // Fetch available times for the selected date and guest count
      try {
        const times = await fetchAvailableTimes(resolvedRestaurantId, dayjs(value).format('YYYY/MM/DD'), formValues.number_of_guests);
        setAvailableTimes(times);
      } catch (error) {
        console.error('Error fetching times:', error);
      }
      handleNext();
    } else {
      handleNext();
    }
  };

  const handleStepClick = (step) => {
    if (step <= activeStep) {
      setActiveStep(step);
    }
  };

  // Function for text inputs (does not advance the stepper)
  const handleInputChange = (name, value) => {
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    if (!formValues.customer_name || !formValues.customer_email) {
      setAlert({ open: true, message: 'Please fill all required fields.', severity: 'error' });
      return;
    }

    try {
      const reservationPayload = {
        ...formValues,
        restaurant: resolvedRestaurantId, // Always use restaurant ID
        reservation_date: dayjs(formValues.reservation_date).format('YYYY-MM-DD'),
      };

      if (reservationId) {
        // If reservation exists, update it
        await updateReservation({ reservationId, updateData: reservationPayload });
        setAlert({ open: true, message: 'Reservation updated successfully!', severity: 'success' });
      } else {
        // Create a new reservation
        const newReservation = await createReservation(reservationPayload);
        setReservationId(newReservation.id);
        setAlert({ open: true, message: 'Reservation created successfully!', severity: 'success' });
        setDialogOpen(true);
      }
    } catch (error) {
      setAlert({ open: true, message: 'Failed to process reservation.', severity: 'error' });
    }
  };

  const handleConfirmReservation = async () => {
    if (!phoneNumber) {
      setAlert({ open: true, message: 'Please provide a valid phone number.', severity: 'error' });
      return;
    }

    try {
      // Update the reservation status to 'confirmed'
      await updateReservation({
        reservationId,
        updateData: { customer_phone: phoneNumber, status: 'confirmed' },
      });
      setAlert({ open: true, message: 'Reservation confirmed!', severity: 'success' });
      setDialogOpen(false); // Close the dialog after confirmation
      setShowSummary(true); // Show summary only after confirmation
    } catch (error) {
      setAlert({ open: true, message: 'Confirmation failed. Please try again.', severity: 'error' });
    }
  };

  // Handle reservation cancellation directly in the widget
  const handleCancelReservation = async () => {
    try {
      await cancelReservation(reservationId);
      setAlert({ open: true, message: 'Reservation cancelled successfully.', severity: 'success' });
      setReservationId(null); 
      setShowSummary(false);
    } catch (error) {
      setAlert({ open: true, message: 'Failed to cancel reservation.', severity: 'error' });
    }
  };

  const getStepLabel = (step) => {
    switch (step) {
      case 0:
        return formValues.number_of_guests ? `${formValues.number_of_guests} Guests` : 'Guests';
      case 1:
        return formValues.reservation_date ? dayjs(formValues.reservation_date).format('DD MMM') : 'Date';
      case 2:
        return formValues.reservation_time || 'Time';
      default:
        return steps[step];
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        const guestNumbers = Array.from({ length: 12 }, (_, i) => i + 1);
        return (
          <Box m={2}>
            <Grid container spacing={2}>
              {guestNumbers.map((num) => (
                <Grid item xs={6} sm={4} md={3} key={num}>
                  <Card
                    variant={formValues.number_of_guests === num ? 'outlined' : 'elevation'}
                    onClick={() => handleSelection('number_of_guests', num)}
                  >
                    <CardActionArea>
                      <CardContent>
                        <Typography align="center">{num}</Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        );
      case 1:
        return (
          <Box component={Paper} elevation={1} width="100%" maxWidth="400px"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              margin: '10px auto',
              padding: '10px',
              maxHeight: '320px',
              [theme.breakpoints.down('sm')]: {
                maxWidth: '90%',
              },
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateCalendar
                value={formValues.reservation_date}
                onChange={(newDate) => handleSelection('reservation_date', newDate)}
                disablePast
                views={['day']}
                showDaysOutsideCurrentMonth
                shouldDisableDate={(date) => !availableDates.includes(dayjs(date).format('YYYY-MM-DD'))}
              />
            </LocalizationProvider>
          </Box>
        );
      case 2:
        return (
          <Box m={2}>
            <Grid container spacing={2}>
              {availableTimes.map((time) => (
                <Grid item xs={6} sm={4} md={3} key={time}>
                  <Card
                    variant={formValues.reservation_time === time ? 'outlined' : 'elevation'}
                    onClick={() => handleSelection('reservation_time', time)}
                  >
                    <CardActionArea>
                      <CardContent>
                        <Typography align="center">{time}</Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        );
      case 3:
        return (
          <Box m={2}>
            <TextField
              label="Name"
              value={formValues.customer_name}
              onChange={(e) => handleInputChange('customer_name', e.target.value)}
              fullWidth
              margin="normal"
              required
            />
            <TextField
              label="Email"
              value={formValues.customer_email}
              onChange={(e) => handleInputChange('customer_email', e.target.value)}
              fullWidth
              margin="normal"
              required
            />
            <TextField
              label="Special Requests"
              value={formValues.special_requests}
              onChange={(e) => handleInputChange('special_requests', e.target.value)}
              fullWidth
              margin="normal"
              multiline
              rows={3}
            />
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 2 }}
              onClick={handleSubmit} // Reserve a table button
            >
              {reservationId ? 'Update Reservation' : 'Reserve a Table'}
            </Button>
          </Box>
        );
      default:
        return null;
    }
  };

  // Show a Reservation Summary once done
  if (showSummary) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" p={3} width="100%" maxWidth="500px">
        <Typography variant="h5" color="primary" gutterBottom>
          Reservation at {reservationData ? reservationData.restaurant.name : restaurantId.name}
        </Typography>
        <Typography variant="body1">Date: {dayjs(formValues.reservation_date).format('DD MMM YYYY')}</Typography>
        <Typography variant="body1">Time: {formValues.reservation_time}</Typography>
        <Typography variant="body1">Guests: {formValues.number_of_guests}</Typography>
        <Typography variant="body1">Name: {formValues.customer_name}</Typography>
        <Typography variant="body1">Email: {formValues.customer_email}</Typography>
        {formValues.special_requests && (
          <Typography variant="body1">Special Requests: {formValues.special_requests}</Typography>
        )}
        <Button variant="outlined" color="primary" sx={{ mt: 2 }} onClick={() => setShowSummary(false)}>
          Edit Reservation
        </Button>
      </Box>
    );
  }

  return (
    <Box pb={7} position="relative" display="flex" flexDirection="column" alignItems="center" minHeight="575px" width="100%" maxWidth="500px" borderRadius="10px" boxShadow="0px 4px 6px -1px rgba(0, 0, 0, 0.1)">
      <Box sx={{ width: '100%', backgroundColor: 'var(--color-darkgrey)', padding: '15px 25px', marginBottom: '20px', borderRadius: '10px 10px 0px 0px' }}>
        <Typography variant="h6" gutterBottom color="white" m={0} p={0}>
          {title}
        </Typography>
        <Typography variant="body" color="white">
          {subtitle}
        </Typography>
      </Box>
      <Stack
        sx={{
          width: '100%',
          overflowX: 'auto',
          '& .MuiStepLabel-label': {
            whiteSpace: 'nowrap',
          },
        }}
        spacing={4}
      >
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<ColorlibConnector />}
          fullWidth
        >
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel
                StepIconComponent={ColorlibStepIcon}
                onClick={() => handleStepClick(index)}
              >
                {getStepLabel(index)}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Stack>
      <Box mt={2}>{renderStepContent(activeStep)}</Box>

      {/* Cancel Reservation Button for Editing View */}
      {reservationId && (
        <Box width={{ xs: '90%', md: '470px' }} position="absolute" bottom="10px" left="50%" sx={{ transform: "translateX(-50%)" }}>
        <Button variant="outlined" color="error" fullWidth onClick={handleCancelReservation}>
          Cancel Reservation
        </Button>
        </Box>
      )}

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Confirm Your Reservation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter your phone number to confirm your reservation.
          </DialogContentText>
          <TextField
            label="Phone Number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            fullWidth
            margin="normal"
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleConfirmReservation} variant="contained" color="primary">
            Confirm Reservation
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={() => setAlert((prev) => ({ ...prev, open: false }))}
      >
        <Alert
          onClose={() => setAlert((prev) => ({ ...prev, open: false }))}
          severity={alert.severity}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ReservationWidget;