// ReservationManagement v1.50
import React, { useState, useEffect } from 'react';
import { Box, Typography, Snackbar, Alert, CircularProgress } from '@mui/material';
import { useParams, useSearchParams } from 'react-router-dom';
import { fetchReservation } from '../../services/usersApiService';
import ReservationWidget from './ReservationWidget';
import logo from '../../assets/logo_tabomenu.png';

const ReservationManagement = () => {
  const { reservationId } = useParams();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const [reservationData, setReservationData] = useState(null);
  const [alert, setAlert] = useState({ open: false, message: '', severity: 'success' });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadReservation = async () => {
      try {
        const data = await fetchReservation(reservationId, token);
        setReservationData(data);
      } catch (error) {
        setAlert({ open: true, message: 'Failed to fetch reservation details', severity: 'error' });
      } finally {
        setLoading(false);
      }
    };
    loadReservation();
  }, [reservationId, token]);

  return (
    <Box 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center',
        px: { xs: 2, sm: 4 },
        mt: 3
      }}
    >
      {/* Tabomenu Logo at the Top-Center */}
      <Box mb={3}>
        <img src={logo} alt="Tabomenu Logo" style={{ width: '150px' }} />
      </Box>

      {/* Spinner while loading */}
      {loading ? (
        <CircularProgress />
      ) : reservationData ? (
        <Box 
          sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            width: '100%', 
            maxWidth: '500px', // Limits the width of the widget container
            mx: 'auto' // Centers on larger screens
          }}
        >
          <ReservationWidget reservationData={reservationData} />
        </Box>
      ) : (
        <Typography variant="body1">Loading reservation details...</Typography>
      )}

      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={() => setAlert((prev) => ({ ...prev, open: false }))}
      >
        <Alert
          onClose={() => setAlert((prev) => ({ ...prev, open: false }))}
          severity={alert.severity}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ReservationManagement;
