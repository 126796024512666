import React from 'react';
import { Routes, Route } from 'react-router-dom';
import styled from 'styled-components';
import POSHeader from './POSHeader';
import POSHome from './POSHome';
import POSOrders from './POSOrders';
import POSMenu from './POSMenu';
import POSTransactions from './POSTransactions';
import POSReservations from './POSReservations';
import POSNavbar from './POSNavbar';

const POSDashboardContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #121212; /* Dark mode background */
`;

const ContentContainer = styled.div`
    flex: 1; /* Take up the remaining space */
`;

const POSDashboard = () => {
    return (
        <POSDashboardContainer>
            <POSHeader />
            <ContentContainer>
                <Routes>
                    <Route path="home" element={<POSHome />} />
                    <Route path="menu" element={<POSMenu />} />
                    <Route path="orders" element={<POSOrders />} />
                    <Route path="transactions" element={<POSTransactions />} />
                    <Route path="reservations" element={<POSReservations />} />
                </Routes>
            </ContentContainer>
            <POSNavbar />
        </POSDashboardContainer>
    );
};

export default POSDashboard;
