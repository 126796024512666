import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton, Typography, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
import FullBillPayment from './payments/FullBillPayment';
import SplitBillPayment from './payments/SplitBillPayment';
import SelectItemsPayment from './payments/SelectItemsPayment';
import { usePOS } from '../../contexts/POSContext';

const DialogContainer = styled(DialogContent)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 90vh;
  overflow: hidden;
`;

const LeftContainer = styled.div`
  width: 40%;
  padding: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #ddd;
`;

const ItemsList = styled.div`
  flex-grow: 1;
  padding: 10px;
`;

const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
  cursor: ${({ selectable }) => (selectable ? 'pointer' : 'default')};
  background-color: ${({ selected, selectable }) => (selected && selectable ? '#FF6A3E' : 'inherit')};
  color: ${({ selected }) => (selected ? '#fff' : 'inherit')};
  text-decoration: ${({ isPaid }) => (isPaid ? 'line-through' : 'none')};
  pointer-events: ${({ isPaid }) => (isPaid ? 'none' : 'auto')};
`;

const TotalContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
  color: #ff6a3ee3;
`;

const PaymentType = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
  gap: 10px;
`;

const PaymentButton = styled(Button)`
  flex: 1;
`;

const RightContainer = styled.div`
  width: 60%;
  display: flex;
  justify-content: center;
  padding: 10px;
`;

const POSPayment = ({ isOpen, onClose, orderData }) => {
  const { paymentDetails, loadPaymentDetails } = usePOS();
  const [paymentOption, setPaymentOption] = useState('full_bill');
  const [selectedItems, setSelectedItems] = useState([]);
  const [isSelectItemsDisabled, setIsSelectItemsDisabled] = useState(false);

  useEffect(() => {
    if (orderData && orderData.orderId) {
      loadPaymentDetails(orderData.orderId);
    }
  }, [orderData, loadPaymentDetails]);

  useEffect(() => {
    if (paymentDetails) {
      const hasSplitPayment = paymentDetails.payments.some(payment => payment.contribution_type === 'split');
      setIsSelectItemsDisabled(hasSplitPayment);
    }
  }, [paymentDetails]);

  const handleItemClick = (itemId) => {
    if (paymentOption !== 'select_items') return;
    setSelectedItems((prevSelected) => {
      if (prevSelected.includes(itemId)) {
        return prevSelected.filter(id => id !== itemId);
      } else {
        return [...prevSelected, itemId];
      }
    });
  };

  const handlePaymentSuccess = () => {
    setSelectedItems([]);
    if (orderData && orderData.orderId) {
      loadPaymentDetails(orderData.orderId);
    }
  };

  const renderOrderItems = () => (
    <>
      {paymentDetails && paymentDetails.items && paymentDetails.items.map(item => {
        const isPaid = item.status === 'paid';
        return (
          <ItemContainer
            key={item.id}
            selectable={paymentOption === 'select_items'}
            selected={selectedItems.includes(item.id)}
            onClick={() => !isPaid && handleItemClick(item.id)}
            isPaid={isPaid}
          >
            <Typography>{item.menu_item || item.combo} x{item.quantity}</Typography>
            <Typography>€{(item.price * item.quantity).toFixed(2)}</Typography>
          </ItemContainer>
        );
      })}
    </>
  );

  const renderPaymentComponent = () => {
    if (!orderData || !paymentDetails) return null;
    switch (paymentOption) {
      case 'full_bill':
        return (
          <FullBillPayment
            amountDue={parseFloat(paymentDetails.amount_due).toFixed(2)}
            orderId={orderData.orderId}
            onPaymentSuccess={handlePaymentSuccess}
          />
        );
      case 'split_bill':
        return (
          <SplitBillPayment
            amountDue={parseFloat(paymentDetails.amount_due).toFixed(2)}
            orderId={orderData.orderId}
            onPaymentSuccess={handlePaymentSuccess}
            paymentDetails={paymentDetails}
          />
        );
      case 'select_items':
        return (
          <SelectItemsPayment
            selectedItems={selectedItems}
            orderData={orderData}
            paymentDetails={paymentDetails}
            onPaymentSuccess={handlePaymentSuccess}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>
        Payment
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContainer>
        <LeftContainer>
          <Typography variant="h6">Table #{orderData?.table_number}</Typography>
          <ItemsList>{renderOrderItems()}</ItemsList>
          <TotalContainer>Total: €{paymentDetails ? parseFloat(paymentDetails.total_amount).toFixed(2) : '...'}</TotalContainer>
          <PaymentType>
            <PaymentButton
              variant={paymentOption === 'full_bill' ? 'contained' : 'outlined'}
              color="primary"
              onClick={() => setPaymentOption('full_bill')}
            >
              Full Bill
            </PaymentButton>
            <PaymentButton
              variant={paymentOption === 'split_bill' ? 'contained' : 'outlined'}
              color="primary"
              onClick={() => setPaymentOption('split_bill')}
            >
              Split Bill
            </PaymentButton>
            <PaymentButton
              variant={paymentOption === 'select_items' ? 'contained' : 'outlined'}
              color="primary"
              onClick={() => !isSelectItemsDisabled && setPaymentOption('select_items')}
              disabled={isSelectItemsDisabled}
            >
              Select Items
            </PaymentButton>
          </PaymentType>
        </LeftContainer>
        <RightContainer>{renderPaymentComponent()}</RightContainer>
      </DialogContainer>
    </Dialog>
  );
};

export default POSPayment;
