import React, { useState, useEffect } from 'react';
import { useRestaurant } from '../../../contexts/RestaurantContext';
import { updateArea, createArea, createMultipleTables } from '../../../services/apiService';
import { TextField, Button, Typography, Box } from '@mui/material';

const AreaForm = ({ context, onClose }) => {
  const [areaName, setAreaName] = useState('');
  const [numberOfTables, setNumberOfTables] = useState(12);
  const { showSidebar, selectedRestaurant, editingArea, handleNewAreaCreated } = useRestaurant();

  const resetForm = () => {
    setAreaName('');
    setNumberOfTables(10);
  };

  useEffect(() => {
    if (context === 'editArea' && editingArea) {
      setAreaName(editingArea.name);
      setNumberOfTables(editingArea.number_of_tables);
    } else {
      resetForm();
    }
  }, [context, editingArea, showSidebar]);

  useEffect(() => {
    if (!showSidebar) {
      resetForm();
    }
  }, [showSidebar]);

  const handleCreateOrUpdateArea = async (event) => {
    event.preventDefault();
    const areaData = {
      name: areaName,
      number_of_tables: numberOfTables,
      restaurant: selectedRestaurant,
    };

    let areaResponse;

    try {
      if (context === 'editArea' && editingArea) {
        areaResponse = await updateArea(editingArea.id, areaData);
      } else {
        areaResponse = await createArea(areaData, selectedRestaurant);
        const tables = Array.from({ length: numberOfTables }, (_, index) => {
          const maxColumns = 8;
          const row = Math.floor(index / maxColumns);
          const col = index % maxColumns;

          return {
            position_x: col,
            position_y: row,
            width: 1,
            height: 1,
            number_of_seats: 4,
          };
        });
        await createMultipleTables(tables, areaResponse.id);
      }

      handleNewAreaCreated(areaResponse.id);
    } catch (error) {
      console.error('Error handling area:', error);
    }
    resetForm();
    onClose();
  };

  return (
    <Box component="form" onSubmit={handleCreateOrUpdateArea}>
      <Typography variant="h6">{context === 'editArea' ? 'Update Area' : 'Add a New Area'}</Typography>
      <TextField
        fullWidth
        variant="outlined"
        label="Area Name"
        placeholder="e.g. Indoor, Outdoor, etc"
        value={areaName}
        onChange={(e) => setAreaName(e.target.value)}
        margin="normal"
      />
      <TextField
        fullWidth
        variant="outlined"
        label="Number of Tables"
        type="number"
        value={numberOfTables}
        onChange={(e) => setNumberOfTables(e.target.value)}
        margin="normal"
      />
      <Button variant="contained" color="primary" type="submit">
        {context === 'editArea' ? 'Update Area' : 'Add Area'}
      </Button>
    </Box>
  );
};

export default AreaForm;
