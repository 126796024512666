import React, { useState, useEffect, useRef } from 'react';
import { Typography, Button, TextField, LinearProgress } from '@mui/material';
import styled from 'styled-components';
import PaymentWidget from './PaymentWidget';
import { usePOS } from '../../../contexts/POSContext';

const SplitContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 10px;
`;

const CustomInputContainer = styled.div`
  width: 80px;
`;

const StyledButton = styled(Button)`
  width: 80px;
  height: 37.5px;
`;

const CustomTextField = styled(TextField)`
  width: 80px;
`;

const ProgressBarContainer = styled.div`
  width: 100%;
`;

const roundToNearest5Cents = (amount) => {
  return (Math.round(amount * 20) / 20).toFixed(2);
};

const SplitBillPayment = ({ orderId, onPaymentSuccess }) => {
  const { paymentMethod, paymentDetails, loadPaymentDetails } = usePOS();
  const [numOfSplits, setNumOfSplits] = useState(2);
  const [customSplits, setCustomSplits] = useState('');
  const [showCustomInput, setShowCustomInput] = useState(false);
  const [currentSplit, setCurrentSplit] = useState(1);
  const customInputRef = useRef(null);

  useEffect(() => {
    loadPaymentDetails(orderId);
  }, [orderId, loadPaymentDetails]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (customInputRef.current && !customInputRef.current.contains(event.target)) {
        setShowCustomInput(false);
      }
    };

    const handleEscKeyPress = (event) => {
      if (event.key === 'Escape') {
        setShowCustomInput(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscKeyPress);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscKeyPress);
    };
  }, []);

  const handleSplitSelection = (splits) => {
    setNumOfSplits(splits);
    setShowCustomInput(false);
    setCurrentSplit(1); // Reset current split to 1 whenever the number of splits is changed
  };

  const handleCustomSplitsChange = (e) => {
    const value = e.target.value;
    setCustomSplits(value);
    setNumOfSplits(value ? parseInt(value, 10) : 2);
    setCurrentSplit(1); // Reset current split to 1 whenever custom splits are changed
  };

  const handleCustomClick = () => {
    setShowCustomInput(true);
    setCustomSplits('');
  };

  const handlePaymentSuccess = async (response) => {
    await loadPaymentDetails(orderId); // Re-fetch payment details after each successful payment
    if (currentSplit < numOfSplits) {
      setCurrentSplit(currentSplit + 1);
    } else {
      onPaymentSuccess(response);
    }
  };

  const calculateSplitTotal = () => {
    if (!paymentDetails) return '0.00'; // Ensure paymentDetails is defined

    const remainingAmount = parseFloat(paymentDetails.amount_due);
    const regularSplit = remainingAmount / (numOfSplits - currentSplit + 1);
    const roundedRegularSplit = paymentMethod === 'cash' ? roundToNearest5Cents(regularSplit) : regularSplit.toFixed(2);
    const roundedRemainingAmount = paymentMethod === 'cash' ? roundToNearest5Cents(remainingAmount) : remainingAmount.toFixed(2);

    return currentSplit === numOfSplits ? roundedRemainingAmount : roundedRegularSplit;
  };

  return (
    <SplitContainer>
      <Typography variant="h5" gutterBottom>Split Bill by</Typography>
      <ButtonContainer>
        <StyledButton
          variant={numOfSplits === 2 ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => handleSplitSelection(2)}
        >
          x2
        </StyledButton>
        <StyledButton
          variant={numOfSplits === 3 ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => handleSplitSelection(3)}
        >
          x3
        </StyledButton>
        <StyledButton
          variant={numOfSplits === 4 ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => handleSplitSelection(4)}
        >
          x4
        </StyledButton>
        {showCustomInput ? (
          <CustomInputContainer ref={customInputRef}>
            <CustomTextField
              label="Splits"
              type="number"
              size="small"
              value={customSplits}
              onChange={handleCustomSplitsChange}
              InputProps={{ inputProps: { min: 2, max: 16 }, onFocus: (e) => e.target.select() }}
              autoFocus
            />
          </CustomInputContainer>
        ) : (
          <StyledButton
            variant="outlined"
            color="primary"
            onClick={handleCustomClick}
          >
            Custom
          </StyledButton>
        )}
      </ButtonContainer>
      <Typography variant="h6" gutterBottom>Bill {currentSplit} of {numOfSplits}</Typography>
      <ProgressBarContainer>
        <LinearProgress variant="determinate" value={(currentSplit / numOfSplits) * 100} />
      </ProgressBarContainer>
      <PaymentWidget
        amountDue={calculateSplitTotal()}
        orderId={orderId}
        onPaymentSuccess={handlePaymentSuccess}
        paymentType="split_in_equal_parts"
        numOfSplits={numOfSplits}
      />
    </SplitContainer>
  );
};

export default SplitBillPayment;