import { createTheme, responsiveFontSizes } from '@mui/material/styles';

// Define your custom theme
let theme = createTheme({
  typography: {
    fontFamily: 'Source Sans Pro, sans-serif', // Default font for the entire app
    fontWeight: 400,
    fontSize: 17,
    lineHeight: 1.6,
    color: '#000000e3',
    h1: {
      fontFamily: 'Montserrat, sans-serif', // Specific font for headings
      fontWeight: 600,
      fontSize: '3.53rem',
      lineHeight: 1.4,
      color: '#000000e3',
    },
    h2: {
      fontFamily: 'Montserrat, sans-serif', // Specific font for headings
      fontWeight: 600,
      fontSize: '2.35rem',
      lineHeight: 1.25,
      color: '#000000e3',
    },
    h3: {
      fontFamily: 'Montserrat, sans-serif', // Specific font for headings
      fontWeight: 600,
      fontSize: '1.88rem',
      lineHeight: 1.2,
      color: '#000000e3',
    },
    h4: {
      fontFamily: 'Montserrat, sans-serif', // Specific font for headings
      fontWeight: 600,
      fontSize: '1.41rem',
      lineHeight: 1.2,
      color: '#000000e3',
    },
    h5: {
      fontFamily: 'Montserrat, sans-serif', // Specific font for headings
      fontWeight: 600,
      fontSize: '1.18rem',
      lineHeight: 1.2,
      color: '#000000e3',
    },
    h6: {
      fontFamily: 'Montserrat, sans-serif', // Specific font for headings
      fontWeight: 600,
      fontSize: '1rem',
      lineHeight: 1.25,
      color: '#000000e3',
    },
    button: {
      fontWeight: 400,
      fontSize: 17,
      lineHeight: 1.6,
      textTransform: 'none',
    },
    body1: {
      fontWeight: 400,
      fontSize: 15,
      lineHeight: 1.6,
      color: '#000000e3',
    },
    body2: {
      fontWeight: 400,
      fontSize: 13,
      lineHeight: 1.6,
      color: '#000000e3',
    },
  },
  palette: {
    primary: {
      main: '#3e3effe3',
    },
    secondary: {
      main: '#ff6a3ee3',
    },
    black: {
      main: '#000000e3',
    },
    dark: {
      main: '#1f1f1f',
    },
    darker: {
      main: '#121212',
    },
    darkgrey: {
      main: '#00000095',
    },
    grey: {
      main: '#00000065',
    },
    lightgrey: {
      main: '#00000025',
    },
    light: {
      main: '#EEEEEEE3',
    },
  },
});

// Apply responsive font scaling
theme = responsiveFontSizes(theme);

export default theme;
