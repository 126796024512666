import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f8d7da;
  color: #721c24;
  text-align: center;
`;

const SessionExpired = () => {
  const navigate = useNavigate();

  const handleLoginRedirect = () => {
    navigate('/pos/clock-in');
  };

  return (
    <Container>
      <h1>Session Expired</h1>
      <p>Your session has expired. Please log in again to continue.</p>
      <Button variant="contained" color="primary" onClick={handleLoginRedirect}>
        Go to Login
      </Button>
    </Container>
  );
};

export default SessionExpired;