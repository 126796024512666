// OnlineMenu.jsx v. 2.10
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useRestaurant } from '../../contexts/RestaurantContext';
import { fetchLiveMenuDetails, placeOrder, fetchBillDetails } from '../../services/usersApiService';
import { aggregateAndOptimizeForUsers } from '../../utils/orderAggregation';
import ReviewOrderModal from './ReviewOrderModal';
import BillReviewModal from './BillReviewModal';
import PaymentDrawer from './PaymentDrawer';
import AllergenDialog from './AllergenDialog';
import styled from 'styled-components';
import logo from '../../assets/logo_tabo-inverted.png';
import logo_regular from '../../assets/logo_tabomenu.png';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import { CircularProgress, IconButton, Button, Typography, TextField, Alert } from '@mui/material';

const Logo = styled.img`
  height: 16px;
  position: absolute;
  top: 12px;
  left: 12px;
  z-index: 3;
`;

const CoverImage = styled.div`
  height: 240px;
  background-image: url(${(props) => props.$image});
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;

  // Overlay with a semi-transparent dark background
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6); // Adjust opacity: (0.6 = 60% darker)
    z-index: 1; // Ensure the overlay is below the text
  }

  // Ensure the text sits on top of the overlay and left aligned
  & > div {
    position: relative;
    z-index: 2;
    width: 100%; // Make sure the container is full width
    padding: 16px;
    text-align: left; // Align text to the left
  }
`;

const LeftColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 10px 10px 0;
`;

const MenuCategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
  padding-left: 10px;
  padding-right: 10px;
`;

const MenuItemContainer = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
`;

const PriceContainer = styled.div`
  margin-top: auto; // Pushes the price to the bottom
  display: flex;
  align-items: flex-end;

`;

const RightColumn = styled.div`
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const PictureContainer = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid darkgrey;
`;

const MenuItemImage = styled.img`
  width: auto;
  height: 100%;
  object-fit: cover;
`;

const SelectionContainer = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 1;
  margin-top: auto; // Pushing the container to the bottom of its flex container
`;

const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33%;
`;

const QuantityContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33%;
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const generateSessionKey = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const OnlineMenu = () => {
  const { restaurantId, tableId, menuId } = useParams();
  const [orderItems, setOrderItems] = useState([]);
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const [isBillModalOpen, setIsBillModalOpen] = useState(false);
  const [isReviewBillButtonVisible, setIsReviewBillButtonVisible] = useState(true);
  const [billDetails, setBillDetails] = useState({ items: [], totalAmount: 0 });
  const { previewData, setPreviewData, currentOrderId, updateCurrentOrderId, setLoading, loading } = useRestaurant();
  const [isPaymentDrawerOpen, setIsPaymentDrawerOpen] = useState(false);
  const [aggregatedOrder, setAggregatedOrder] = useState(null);
  const [allergenDialogOpen, setAllergenDialogOpen] = useState(false);
  const [currentAllergens, setCurrentAllergens] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const ws = useRef(null);

  useEffect(() => {
    const fetchMenuDetails = async () => {
      if (!menuId || menuId === 'default') {
        console.error("Menu ID is 'default' or missing, cannot fetch menu details.");
        setLoading(false);
        return;
      }
      setLoading(true);
      try {
        const data = await fetchLiveMenuDetails(menuId, restaurantId, tableId);
        setPreviewData(data);
      } catch (error) {
        console.error('Failed to fetch menu details:', error);
      } finally {
        setLoading(false);
      }
    };
    if (menuId) fetchMenuDetails();
  }, [menuId, restaurantId, tableId, setLoading, setPreviewData]);

  // Get restaurant details from the previewData
  const restaurant = previewData.restaurant || {};
  const categories = previewData.categories || [];

  const coverImageUrl = restaurant.cover_image || '/path/to/default-cover-image.jpg';

  // Initialize or retrieve session keys from local storage
  useEffect(() => {
    let sessionKey = localStorage.getItem('session_key');
    if (!sessionKey) {
      sessionKey = generateSessionKey();
      localStorage.setItem('session_key', sessionKey);
    }
  }, []);

  useEffect(() => {
    ws.current = new WebSocket('ws://127.0.0.1:8000/ws/orders/');
    ws.current.onopen = () => console.log('WebSocket Connected');
    ws.current.onclose = () => console.log('WebSocket Disconnected');
    ws.current.onerror = (error) => console.log('WebSocket Error: ', error);
    ws.current.onmessage = (e) => {
      const message = JSON.parse(e.data);
      console.log('From Server: ', message);
    };
    return () => {
      if (ws.current) ws.current.close();
    };
  }, []);

  const addItemToOrder = (item, courseType) => {
    setOrderItems((prevItems) => {
      const existingItem = prevItems.find((prevItem) => prevItem.id === item.id);
      if (existingItem) {
        return prevItems.map((prevItem) =>
          prevItem.id === item.id ? { ...prevItem, quantity: prevItem.quantity + 1, course_type: courseType } : prevItem
        );
      } else {
        return [...prevItems, { ...item, quantity: 1, course_type: courseType }];
      }
    });
  };

  const handleAddItem = (item, courseType) => {
    addItemToOrder(item, courseType);
  };

  const removeItemFromOrder = (itemId) => {
    setOrderItems((prevItems) => {
      const existingItem = prevItems.find((item) => item.id === itemId);
      if (existingItem && existingItem.quantity > 1) {
        return prevItems.map((item) => (item.id === itemId ? { ...item, quantity: item.quantity - 1 } : item));
      } else {
        return prevItems.filter((item) => item.id !== itemId);
      }
    });
  };

  const prepareOrderData = () => {
    const sessionKey = localStorage.getItem('session_key');
    const orderItemsWithTypes = orderItems.map((item) => ({
      id: item.id,
      quantity: item.quantity,
      type: item.type,
      course_type: item.course_type,
      session_key: sessionKey,  // Session key at the item level
    }));

    const orderData = {
      table: tableId,
      items: orderItemsWithTypes,
    };

    return orderData;
  };

  const sendOrder = async () => {
    const orderData = prepareOrderData();
    try {
      const placedOrder = await placeOrder(tableId, orderData);
      console.log('Order successfully placed:', placedOrder);
      updateCurrentOrderId(placedOrder.id);
      setOrderItems([]);
    } catch (error) {
      console.error('Failed to place order:', error);
    }
  };

  const openReviewModal = async () => {
    const aggregatedOrderData = aggregateAndOptimizeForUsers(orderItems);
    setAggregatedOrder(aggregatedOrderData);
    setIsReviewModalOpen(true);
  };

  // Fetch bill details when the Bill Modal is opened
  const openBillModal = async () => {
    try {
      setIsBillModalOpen(true);  // Open the modal

      if (currentOrderId) {
        const updatedBillDetails = await fetchBillDetails(currentOrderId);

        // Always set billDetails
        setBillDetails(updatedBillDetails);

        // Check if the amount_due is zero (indicating the bill is fully paid)
        if (parseFloat(updatedBillDetails.amount_due) === 0) {
          // Clear orderId and session_key from localStorage
          localStorage.removeItem('currentOrderId');
          localStorage.removeItem('session_key');
          // Hide the "Review Bill" button since the bill is paid
          setIsReviewBillButtonVisible(false);
        }
      } else {
        console.error('No current order ID found');
      }
    } catch (error) {
      console.error('Failed to fetch bill details:', error);
    }
  };

  const filterItemsAndCategories = () => {
    if (!searchQuery) return categories;

    return categories.reduce((filteredCategories, category) => {
      const matchesCategoryName = category.categoryName.toLowerCase().includes(searchQuery.toLowerCase());
      const filteredItems = category.items.filter((item) =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      const filteredCombos = category.combos.filter((combo) =>
        combo.name.toLowerCase().includes(searchQuery.toLowerCase())
      );

      if (matchesCategoryName || filteredItems.length || filteredCombos.length) {
        filteredCategories.push({
          ...category,
          items: matchesCategoryName ? category.items : filteredItems,
          combos: matchesCategoryName ? category.combos : filteredCombos,
        });
      }
      return filteredCategories;
    }, []);
  };

  const openAllergenDialog = (allergens) => {
    setCurrentAllergens(allergens);
    setAllergenDialogOpen(true);
  };

  const closeAllergenDialog = () => {
    setAllergenDialogOpen(false);
  };

  // Open Payment Drawer and close the BillReviewModal
  const openPaymentDrawer = () => {
    setIsPaymentDrawerOpen(true);  // Open PaymentDrawer
  };

  // Close the Payment Drawer
  const closePaymentDrawer = () => {
    setIsPaymentDrawerOpen(false);
  };

  if (loading) {
    return (
      <SpinnerContainer>
        <CircularProgress size={100} color="secondary" />
      </SpinnerContainer>
    );
  }

  const filteredCategories = filterItemsAndCategories();
  const hasMenuItems = categories.length > 0;
  const hasFilteredResults = filteredCategories.some((category) => category.items.length > 0 || category.combos.length > 0);

  if (!hasMenuItems) {
    return (
      <div style={{ paddingTop: '50px', paddingLeft: '25px', paddingRight: '25px' }}>
        <Logo src={logo_regular} alt="TaboMenu Logo" />
        <Alert severity="warning">No menus are available at the moment. Please check again later.</Alert>
      </div>
    );
  }

  return (
    <div>
      <CoverImage $image={coverImageUrl}>
        <Logo src={logo} alt="TaboMenu Logo" />
        <div>
          <Typography variant="h4" color="white">{restaurant.name}</Typography>
          <Typography variant="body1" color="white">{restaurant.description}</Typography>
        </div>
      </CoverImage>

      <TextField
        placeholder="Search Menu"
        variant="outlined"
        fullWidth
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        size="small"
        style={{ maxWidth: '600px', width: '80%', margin: '20px auto 10px auto', display: 'block' }}
        InputProps={{
          style: { borderRadius: '10px' }
        }}
      />

      {hasFilteredResults ? (
        <MenuCategoryContainer>
          {filteredCategories.map(({ categoryName, items, combos }) => (
            <div key={categoryName}>
              <Typography variant="h4" style={{ marginTop: '30px', marginBottom: '20px' }}>{categoryName}</Typography>
              {items.map((item) => {
                const orderItem = orderItems.find((orderItem) => orderItem.id === item.id);
                return (
                  <MenuItemContainer key={item.id}>
                    <LeftColumn>
                      <div style={{ marginBottom: '20px' }}>
                        <Typography style={{ fontWeight: 'bold' }}>{item.name}</Typography>
                        <Typography>{item.description}</Typography>
                        {item.allergens.length > 0 && (
                          <Typography
                            color="secondary" style={{ cursor: 'pointer', textDecoration: 'underline', marginTop: '10px' }}
                            onClick={() => openAllergenDialog(item.allergens)}>
                            Allergens
                          </Typography>
                        )}
                      </div>
                      <PriceContainer>
                        <Typography color="primary" fontWeight="bold">€{item.price}</Typography>
                      </PriceContainer>
                    </LeftColumn>
                    <RightColumn>
                      {item.picture && (
                        <PictureContainer>
                          <MenuItemImage src={item.picture} alt={item.name} />
                        </PictureContainer>
                      )}
                      <SelectionContainer>
                        {orderItem?.quantity > 0 && (
                          <IconsContainer>
                            <IconButton onClick={() => removeItemFromOrder(item.id)}>
                              <RemoveCircleOutline color="secondary" />
                            </IconButton>
                          </IconsContainer>
                        )}
                        {orderItem?.quantity > 0 && (
                          <QuantityContainer>{orderItem.quantity}</QuantityContainer>
                        )}
                        <IconsContainer>
                          <IconButton onClick={() => handleAddItem({ ...item, type: 'menu_item' }, item.course_type)}>
                            <AddCircleOutline color="secondary" />
                          </IconButton>
                        </IconsContainer>
                      </SelectionContainer>
                    </RightColumn>
                  </MenuItemContainer>
                );
              })}
              {combos.map((combo) => {
                const orderCombo = orderItems.find((orderItem) => orderItem.id === combo.id);
                return (
                  <MenuItemContainer key={combo.id}>
                    <LeftColumn>
                      <div style={{ marginBottom: '20px' }}>
                        <Typography style={{ fontWeight: 'bold' }}>{combo.name}</Typography>
                        <Typography>{combo.description}</Typography>
                        {combo.allergens.length > 0 && (
                          <Typography
                            color="secondary" style={{ cursor: 'pointer', textDecoration: 'underline', marginTop: '10px' }}
                            onClick={() => openAllergenDialog(combo.allergens)}>
                            Allergens
                          </Typography>
                        )}
                      </div>
                      <PriceContainer>
                        <Typography color="primary" fontWeight="bold">€{combo.price}</Typography>
                      </PriceContainer>
                    </LeftColumn>
                    <RightColumn>
                      {combo.picture && (
                        <PictureContainer>
                          <MenuItemImage src={combo.picture} alt={combo.name} />
                        </PictureContainer>
                      )}
                      <SelectionContainer>
                        {orderCombo?.quantity > 0 && (
                          <IconsContainer>
                            <IconButton onClick={() => removeItemFromOrder(combo.id)}>
                              <RemoveCircleOutline color="secondary" />
                            </IconButton>
                          </IconsContainer>
                        )}
                        {orderCombo?.quantity > 0 && (
                          <QuantityContainer>{orderCombo.quantity}</QuantityContainer>
                        )}
                        <IconsContainer>
                          <IconButton onClick={() => handleAddItem({ ...combo, type: 'combo' }, combo.course_type)}>
                            <AddCircleOutline color="secondary" />
                          </IconButton>
                        </IconsContainer>
                      </SelectionContainer>
                    </RightColumn>
                  </MenuItemContainer>
                );
              })}
            </div>
          ))}
        </MenuCategoryContainer>
      ) : (
        <Alert severity="info">No results found for "{searchQuery}".</Alert>
      )}

      <AllergenDialog open={allergenDialogOpen} onClose={closeAllergenDialog} allergens={currentAllergens} />

      {currentOrderId && isReviewBillButtonVisible && (
        <Button
          onClick={openBillModal}
          style={{ position: 'fixed', bottom: '20px', left: '50%', transform: 'translateX(-50%)', zIndex: 1 }}
          variant="contained" color="primary"
        >
          Review Bill
        </Button>
      )}

      {orderItems.length > 0 && (
        <Button
          onClick={openReviewModal}
          style={{ position: 'fixed', bottom: '20px', left: '50%', transform: 'translateX(-50%)', zIndex: 1 }}
          variant="contained"
          color="primary"
        >
          Review Order
        </Button>
      )}

      <ReviewOrderModal
        isOpen={isReviewModalOpen}
        onClose={() => setIsReviewModalOpen(false)}
        orderItems={aggregatedOrder}
        onConfirm={() => {
          sendOrder();
          setIsReviewModalOpen(false);
        }}
      />

      <Button onClick={() => setIsBillModalOpen(true)}>Review Bill</Button>
      <BillReviewModal
        isOpen={isBillModalOpen}
        onClose={() => setIsBillModalOpen(false)}
        billDetails={billDetails}
        orderId={currentOrderId}
        onOpenPaymentDrawer={openPaymentDrawer}  // Pass function to open PaymentDrawer
      />

      <PaymentDrawer
        open={isPaymentDrawerOpen}
        onClose={closePaymentDrawer}
        billDetails={billDetails}
        orderId={currentOrderId}
      />
    </div>
  );
};

export default OnlineMenu;
