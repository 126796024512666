import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import { GridView, Fastfood, DvrRounded, ReceiptOutlined, CalendarToday } from '@mui/icons-material';
import styled from 'styled-components';
import useMediaQuery from '@mui/material/useMediaQuery';

const StyledBottomNavigation = styled(BottomNavigation)`
  && {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #1f1f1f;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    padding: 0px;
  }
`;

const StyledBottomNavigationAction = styled(({ $hideLabels, ...rest }) => <BottomNavigationAction {...rest} />)`
  && {
    flex: 1;
    min-width: 0;
    max-width: 100%;
    color: #CBD5E1; // Default color for icons and labels
    padding-top: 2px;
    display: flex;
    flex-direction: row; /* Align icon and label in a row */
    justify-content: center; /* Center the content */
    align-items: center; /* Center the content vertically */
    &.Mui-selected {
      color: #ff6a3ee3; // Color for selected icons and labels
    }
    .MuiBottomNavigationAction-label {
      font-size: 21px; // Customize label font size if needed
      margin-left: 8px; /* Add space between icon and label */
    }
    .MuiSvgIcon-root {
      font-size: 28px; /* Standardize icon size */
    }

    /* Hide labels on mobile */
    ${({ $hideLabels }) => $hideLabels && `
      .MuiBottomNavigationAction-label {
        display: none;
      }
    `}
  }

  &&:first-child {
    border-left: none;
  }

  &&:last-child {
    border-right: none;
  }
`;

const POSNavbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery('(max-width:600px)');
  const [value, setValue] = useState('home');

  useEffect(() => {
    const currentPath = location.pathname.split('/').pop();
    setValue(currentPath);
  }, [location.pathname]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(`/pos/dashboard/${newValue}`);
  };

  return (
    <StyledBottomNavigation value={value} onChange={handleChange} showLabels={!isMobile}>
      <StyledBottomNavigationAction label="Tables" value="home" icon={<GridView />} $hideLabels={isMobile} />
      <StyledBottomNavigationAction label="Menu" value="menu" icon={<Fastfood />} $hideLabels={isMobile} />
      <StyledBottomNavigationAction label="Orders" value="orders" icon={<DvrRounded />} $hideLabels={isMobile} />
      <StyledBottomNavigationAction label="Transactions" value="transactions" icon={<ReceiptOutlined />} $hideLabels={isMobile} />
      <StyledBottomNavigationAction label="Reservations" value="reservations" icon={<CalendarToday />} $hideLabels={isMobile} />
    </StyledBottomNavigation>
  );
};

export default POSNavbar;
