import React from 'react';
import styled from 'styled-components';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  text-align: center;
`;

const ModalTitle = styled.h4`
  margin-bottom: 10px;
`;

const ModalSubtitle = styled.p`
  margin-bottom: 20px;
`;

const Button = styled.button`
  padding: 10px 20px;
  margin: 0 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

const DeleteButton = styled(Button)`
  background-color: #d33;
  color: white;
`;

const CancelButton = styled(Button)`
  background-color: #aaa;
  color: white;
`;

const ConfirmationModal = ({ onClose, onConfirm }) => {
  return (
    <ModalOverlay>
      <ModalContainer>
        <ModalTitle>Are you sure you want to delete this item?</ModalTitle>
        <ModalSubtitle>You won't be able to revert this.</ModalSubtitle>
        <DeleteButton onClick={onConfirm}>Delete</DeleteButton>
        <CancelButton onClick={onClose}>Cancel</CancelButton>
      </ModalContainer>
    </ModalOverlay>
  );
};

export default ConfirmationModal;
